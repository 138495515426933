import React from 'react';
import SEO from '../../components/SEO/SEO';
import GtcShop from '../../components/Shop/Shops/Gtc/GtcShop';

const GtcShopPage = ({ location: { href }}) => (
  <>
    <SEO
      title='Global Travel Collection Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <GtcShop location={href} />
  </>
);

export default GtcShopPage;
