import { get } from 'lodash';

const calculateProductPrice = (product, numberOfKits, discount, delivery, requiresReturn) => {
  let internalRequiresReturn = requiresReturn;
  const productPrice = product.price;
  const isDiscountAvailable = !!discount && !get(product, 'discount_exempt', false);
  const price = isDiscountAvailable ? productPrice - (productPrice * (discount.value / 100)) : productPrice;
  if (!internalRequiresReturn)
    internalRequiresReturn = get(product, 'tags', []).find((tagName) => tagName === 'postal_return') ? 1 : 0;
  const returnPrice = (delivery === 'royal' ? (10 * numberOfKits * internalRequiresReturn) : 0);
  const returnPriceWithDiscount = returnPrice * (isDiscountAvailable ? 1 - (discount.value / 100) : 1);
  const totalPrice = (price * numberOfKits) + (isDiscountAvailable ? returnPriceWithDiscount : returnPrice);
  const priceWithoutDiscount = (productPrice * numberOfKits) + returnPrice;

  return ({
    price,
    isDiscountAvailable,
    priceWithoutDiscount,
    totalPrice,
    returnPrice,
    returnPriceWithDiscount,
  });
};

export default calculateProductPrice;
