import React from 'react';
import PropTypes from 'prop-types';
import './QuoteFrom.scss';
import AvatarImage from '../AvatarImage/AvatarImage';

const QuoteFrom = ({ authorName, authorCompany, authorRole, authorImageSrc, quote, backgroundColor}) => (
  <div className='container-fluid quote-from-container'
    style={{
      backgroundColor: backgroundColor || 'var(--doc-white)'
    }}>
    <div className='row'>
      <div className='col-12 col-md-8 order-2 order-md-1'>
        <div>
          <i className='fa fa-quote-left pink-quote'></i>
        </div>
        <blockquote className='blockquote'>
          {quote.map((paragraph, i) => (
            <p key={i} className="stories-quote">{paragraph}</p>
          ))}
          <footer className='blockquote-footer'>
            {authorName} -{' '}
            <cite title='Source Title'>
              {authorRole} at {authorCompany}
            </cite>
          </footer>
        </blockquote>
      </div>
      <div className='col-12 col-md-4 order-1 order-md-2'>
        <AvatarImage imageSrc={authorImageSrc} />
      </div>
    </div>
  </div>
);

export default QuoteFrom;

QuoteFrom.defaultProps = {
  authorName: 'Madhur',
  authorImageSrc: '',
  authorRole: '',
  authorCompany: 'DochHQ',
  quote: ['Here', 'is a', 'paragraph'],
};

QuoteFrom.propTypes = {
  authorName: PropTypes.string.isRequired,
  authorImageSrc: PropTypes.string.isRequired,
  authorRole: PropTypes.string.isRequired,
  authorCompany: PropTypes.string.isRequired,
  quote: PropTypes.array.isRequired,
};
