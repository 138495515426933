/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from 'react-dom';
import './src/assets/styles/bootstrap-grid.css';
import './src/assets/styles/theme.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-phone-number-input/style.css';
import './static/iub.js';
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }
};

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
