import React from 'react';
import GreenCheckbox from '../FormComponents/GreenCheckbox';
import calculatePriceWithDiscount from '../../helpers/calculatePriceWithDiscount';
import BillingDetails from '../FormComponents/BillingDetails/BillingDetails';
import ShippingDetails from '../FormComponents/ShippingDetails/ShippingDetails';
import CardDetails from '../FormComponents/CardDetails/CardDetails';
import PersonalDetails from '../FormComponents/PersonalDetails/PersonalDetails';
import TextInputElement from '../FormComponents/TextInputElement';
import Button from '../Button/Button';
import DiscountCode from '../FormComponents/DiscountCode';
import covidSvc from '../../services/covid-19.service';
import Modal from '../Modal/Modal';
import fireAwin from '../../helpers/fireAwin';
import './PurchaseForm.scss';

export default class PurchaseForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      quantity: 1,
      card: {
        number: '',
        expiration_date: '',
        cvv: '',
      },
      billing_details: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        dateOfBirth: '',
        street_address: '',
        extended_address: '',
        locality: '',
        region: '',
        postal_code: '',
        valid: false,
      },
      shipping_details: {
        first_name: '',
        last_name: '',
        company: '',
        street_address: '',
        extended_address: '',
        locality: '',
        region: '',
        postal_code: '',
        valid: false,
      },
      toc_accept: false,
      marketing_accept: false,
      discount_code: '',
      discount: {},
      shippingSameAsBilling: false,
      discountHealth: true,
      modalVisible: false,
      success: false,
      orderId: '',
      gotPrice: false,
      gotHealth: false,
      health: false,
    };
    this.submitForm = submitForm.bind(this);
    this.updateErrors = updateErrors.bind(this);
    this.getHealth = getHealth.bind(this);
    this.getPrice = getPrice.bind(this);
    this.submitPayment = submitPayment.bind(this);
    function updateErrors(valid, field) {
      if (valid) {
        const newErrors = this.state.errors.filter(fieldName => fieldName !== field);
        this.setState({ errors: newErrors });
      } else if (this.state.errors.filter(fieldName => fieldName !== field).length !== 0) {
        this.setState(prevState => ({ errors: [...prevState.errors, field] }));
      } else {
        this.setState(prevState => ({ errors: [...prevState.errors, field] }));
      }
    }
    async function getHealth() {
      const health = await covidSvc.getHealth();
      this.setState({ health, gotHealth: true });
    }
    async function getPrice() {
      const price = await covidSvc.getPrice();
      if (price.error !== 'undefined' && price.price) {
        this.setState({ price: price.price, gotPrice: true });
      }
    }
    function submitForm() {
      if (this.state.errors.length > 0) {
        // Open error modal
        const errorContent = this.state.errors;
        this.setState({ modalVisible: true, errorContent });
      } else {
        // Make http call
        if (this.state.health && this.state.toc_accept) {
          const shipping = this.state.shippingSameAsBilling
            ? this.state.billing_details
            : this.state.shipping_details;
          const dataObj = {
            quantity: this.state.quantity,
            card: { ...this.state.card },
            billing_details: { ...this.state.billing_details },
            shipping_details: { ...shipping },
            discount_code: this.state.discount_code,
          };
          this.submitPayment(dataObj);
        } else if (!this.state.toc_accept) {
          this.setState({
            errorContent: 'Please accept our terms and conditions',
            modalVisible: true,
          });
        } else {
          // Service is unavailable at this time, for more information contact us or try again later.
          this.setState({
            errorModalContent: 'This service is temporarily available, please try again later.',
            modalVisible: true,
          });
        }
      }
    }
    async function submitPayment(formData) {
      await covidSvc
        .submitPayment(formData)
        .then(resp => {
          if (resp.success && resp.orderId && resp.amount) {
            fireAwin(resp.amount, this.state.discount_code, resp.orderId);
            this.setState({ success: true, modalVisible: true, orderId: resp.orderId });
          } else {
            this.setState({
              errorContent: 'Unfortunately an error occurred. ' + resp.error,
              modalVisible: true,
            });
          }
        })
        .catch(err => {
          this.setState({
            errorContent: 'There was an error placing your order, please try again.',
            modalVisible: true,
          });
        });
    }
    if (!this.state.gotPrice) {
      this.getPrice();
    }
    if (!this.state.gotHealth) {
      this.getHealth();
    }
  }

  render() {
    return (
      <>
        <div className='purchase-form-all-container' id='order-form'>
          <div className='purchase-form-container'>
            <div className='row'>
              <h2>Covid-19 Home Test Kit Order Form</h2>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-6 input-item'>
                <TextInputElement
                  label='Quantity'
                  id='quantity-top'
                  type='number'
                  min={1}
                  max={4}
                  step={1}
                  pattern={new RegExp(/^[1-4]{1}$/)}
                  value={this.state.quantity}
                  onChange={quantity => this.setState({ quantity })}
                  updateStatus={(valid, field) => this.updateErrors(valid, field)}
                  required
                />
              </div>
              <div className='col-sm-12 col-md-6 input-item'>
                <Price
                  quantity={this.state.quantity}
                  price={this.state.price}
                  discount={this.state.discount}
                />
              </div>
              <div className='row' style={{ paddingLeft: '20px' }}>
                {this.state.quantity > 4 && (
                  <p style={{ color: 'var(--doc-error)' }}>
										We're only allowing 4 tests per order to reduce panic testing. We thank you for
										understanding.
                  </p>
                )}
              </div>
            </div>
            <form noValidate>
              <PersonalDetails
                update={newDetails =>
                  this.setState(prevState => {
                    let newState = prevState.billing_details;
                    newState = { ...prevState.billing_details, ...newDetails };
                    return { billing_details: newState };
                  })
                }
                updateStatus={this.updateErrors}
              />
              <BillingDetails
                update={newDetails => this.setState({ billing_details: { ...newDetails } })}
                updateStatus={this.updateErrors}
              />
              <div className='payment-card-details'>
                <div className='row'>
                  <h4>Payment Details</h4>
                </div>
                <CardDetails
                  update={newDetails => this.setState({ card: { ...newDetails } })}
                  updateStatus={this.updateErrors}
                />
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-6' style={{ display: 'inline-flex' }}>
                  <GreenCheckbox
                    onChange={e => this.setState({ shippingSameAsBilling: e.target.checked })}
                  />
                  <p>My shipping address is the same as my billing address.</p>
                </div>
              </div>
              {!this.state.shippingSameAsBilling && (
                <ShippingDetails
                  update={newDetails => this.setState({ shipping_details: { ...newDetails } })}
                  updateStatus={this.updateErrors}
                />
              )}

              <div className='row'>
                <div className='col-sm-12 col-md-6' style={{ display: 'inline-flex' }}>
                  <GreenCheckbox onChange={e => this.setState({ toc_accept: e.target.checked })} />
                  <p>
										Accept{' '}
                    <a
                      href='/terms-and-conditions'
                      target="_blank"
                      rel='noopener noreferrer'
                      style={{ color: 'var(--doc-pink)' }}
                    >
											Terms and Conditions
                    </a>
                  </p>
                </div>
                <div className='col-sm-12 col-md-6' style={{ display: 'inline-flex' }}>
                  <GreenCheckbox
                    onChange={e => this.setState({ marketing_accept: e.target.checked })}
                  />
                  <p>I want DocHQ to update with new products, services and offers.</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-6 input-item'>
                  <Price
                    quantity={this.state.quantity}
                    price={this.state.price}
                    discount={this.state.discount}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-6 input-item'>
                  <DiscountCode
                    value={this.state.discount_code}
                    onChange={val => this.setState({ discount_code: val })}
                    updateDiscount={discount => this.setState({ discount })}
                    style={{ marginTop: '1rem', maxWidth: '200px' }}
                  />
                </div>
              </div>
              {this.state.discount.valid && (
                <div className='row'>
                  <p style={{ color: 'var(--doc-green)', margin: '20px' }}>
										Discount applied successfully.
                  </p>
                </div>
              )}
              {this.state.discount.valid === false && this.state.discount_code.length >= 3 && (
                <div className='row'>
                  <p style={{ color: 'var(--doc-error)', margin: '20px' }}>
										Invalid discount code.
                  </p>
                </div>
              )}
              <div className='row'>
                <Button
                  text='Place Order'
                  color='pink'
                  onClick={this.submitForm}
                  aria-label='Submit button'
                />
              </div>
            </form>
          </div>
        </div>
        {this.state.modalVisible && (
          <ErrorModal
            errorContent={this.state.errorContent}
            isVisible={this.state.modalVisible && !this.state.success}
            onClose={() => this.setState({ modalVisible: false })}
          />
        )}
        {this.state.success && (
          <SuccessModal
            orderId={this.state.orderId}
            isVisible={this.state.success && this.state.modalVisible}
            onClose={() => this.setState({ modalVisible: false })}
          />
        )}
      </>
    );
  }
}

const Price = ({ quantity, price, discount }) => {
  return (
    <>
      <div className='total-price'>
				Total:{' '}
        <span className={`${discount && discount.valid ? 'valid-discount' : null}`}>
					£{calculatePriceWithDiscount(quantity, price || 195, discount)}
        </span>
      </div>
    </>
  );
};

const ErrorModal = ({ errorContent, isVisible, onClose }) => {
  return typeof errorContent === 'string' ? (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title='An Error Occurred'
      content={<p style={{ color: 'var(--doc-error)' }}>{errorContent}</p>}
    />
  ) : (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title='An Error Occurred'
      content={
        <>
          <p>Please correct the following fields:</p>
          <ul className='shwd-list'>
            {errorContent.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </>
      }
    />
  );
};
const SuccessModal = ({ orderId, isVisible, onClose }) => (
  <Modal
    isVisible={isVisible}
    onClose={onClose}
    title={'Success'}
    content={<p>Your order id is: {orderId}.</p>}
  />
);
