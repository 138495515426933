import React from 'react';
import MenuButton from '../MenuButton/MenuButton';
import { Link } from 'gatsby';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import './NavBar.scss';

const NavigationDropDown = ({ title, links, activeStyle, isMobileView }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return(
    <>
      {
        isMobileView ? (
          <>
            <ListItem
              onClick={handleClick}
              className="nav-item"
            >
              {open ? <KeyboardArrowUpIcon fontSize="large" className="arrow"/> : <KeyboardArrowDownIcon fontSize="large" className="arrow"/>}
              <ListItemText primary={title}/>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
              >
                {
                  links.map(({linkTo, displayText}) => (
                    <ListItem>
                      <Link
                        to={linkTo}
                        activeStyle={activeStyle}
                      ><div className="nav-item">{displayText}</div></Link>
                    </ListItem>
                  ))
                }
              </List>
            </Collapse>
          </>
        ) : (
          <MenuButton
            text={title}
            links={
              links.map(({linkTo, displayText}) => (
                <Link
                  to={linkTo}
                  activeStyle={activeStyle}
                >
                  <div className="nav-item">{displayText}</div>
                </Link>
              ))
            }
          />
        )
      }
    </>
  );
};

export default NavigationDropDown;
