import React, { useEffect } from 'react';
import getURLParams from '../../../helpers/getURLParams';

const HowToMakeATestPage = ({ location: { href } }) => {
  const param = getURLParams(href);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      switch (param['language']) {
      case 'en':
        window.location.pathname = 'DocHQ V1  Flowflex Bio CE0123 S-2 Ag Self-Testing Insert (English).pdf';
        break;
      case 'de':
        window.location.pathname = 'DocHQ V1  Flowflex Bio CE0123 S-2 Ag Self-Testing Insert (German).pdf';
        break;
      case 'es':
        window.location.pathname = 'DocHQ V1  Flowflex Bio CE0123 S-2 Ag Self-Testing Insert (Spanish).pdf';
      case 'it':
        window.location.pathname = 'DocHQ V1  Flowflex Bio CE0123 S-2 Ag Self-Testing Insert (Italian).pdf';
        break;
      default:
        window.location.pathname = 'DocHQ V1  Flowflex Bio CE0123 S-2 Ag Self-Testing Insert (English).pdf';
        break;
      }
    }
  }, []);

  return (
    <></>
  );
};

export default HowToMakeATestPage;
