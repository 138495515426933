import axios from 'axios';

const productSvc = {
  async getProducts(baseURL, queryParams) {
    return await new Promise((resolve, reject) => {
      axios({
        url: `${baseURL}/v1/product?${queryParams ? queryParams : ''}`,
        method: 'GET',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data) {
            resolve({ success: true, products: response.data.products });
          } else {
            reject({
              success: false,
              error: 'Unable to find dropboxes',
            });
          }
        })
        .catch(() => reject({ success: false, error: 'Server Error Occurred' }));
    });
  },
  async getApprovedKits(baseURL) {
    return await new Promise((resolve, reject) => {
      axios({
        url: `${baseURL}/v1/approved_kits`,
        method: 'GET',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data) {
            resolve({ success: true, kits: response.data });
          } else {
            reject({
              success: false,
              error: 'Unable to find approved kits',
            });
          }
        })
        .catch(() => reject({ success: false, error: 'Server Error Occurred' }));
    });
  }
};

export default productSvc;
