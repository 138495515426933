import { DeploymentType } from '../layouts/MainLayout/MainLayout';
import ENV_VARS from '../config';
import axios from 'axios';

const config =
	DeploymentType !== 'staging'
	  ? ENV_VARS.staging
	  : ENV_VARS.production;
const api = axios.create({
  baseURL: config.brochureAPI,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
});

const brochureSvc = {
  submitDetails(formDetails) {
    const params = { email: formDetails.email, name: formDetails.name, accept: true };
    return new Promise((resolve, reject) => {
      axios
        .get(config.brochureAPI, { params })
        .then(result => {
          resolve(result.status === 200 || result.data.status === 'success');
        })
        .catch(err => {
          resolve(false);
          console.error(err);
        });
    });
  },
};

export default brochureSvc;
