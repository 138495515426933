import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import QuoteFrom from '../components/QuoteFrom/QuoteFrom';
import LinkButton from '../components/LinkButton/LinkButton';
import Packages from '../components/Packages/Packages';

const PackagesPage = () => (
  <>
    <HeroBanner
      title='Build your plan'
      tagline='We have a range of packages to suit your business. Whether your employees are office based, on the shop floor or out in the field, we take care of their health and wellbeing.'
      imageAlt='Female worker in uniform writing'
      imageSrc='packages-cover.png'
      backgroundStyle={{ backgroundPosition: 'center -30px' }}
    />
    <SignUpText />
    <Packages />
    <QuoteFrom
      authorName='Dermot'
      authorRole='Managing Director'
      authorCompany='Ingenieur Ltd'
      authorImageSrc='dermot.png'
      quote={[
        'This is a low cost, effective solution to enable my staff to take control of their health and time. It also benefits the business through reducing absenteeism and increasing productivity.',
      ]}
    />
  </>
);

export default PackagesPage;

const SignUpText = () => (
  <div
    className='sign-up-container'
    style={{
      backgroundColor: 'var(--doc-light-grey)',
      color: 'var(--doc-dark-grey)',
      textAlign: 'center',
      padding: '20px',
    }}
  >
    <div className='row'>
      <h2 style={{ margin: 'auto' }}>It's easy to sign up</h2>
    </div>
    <div className='row' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <p style={{ width: '60%', minWidth: '250px', margin: 'auto' }}>
				We want to understand your needs, so speak with us to create a package which suits you and
				your employees. We can start onboarding immediately so that your employees can benefit from
				perks and discounts, mental wellbeing tools, GP consultations and specialist support
				quickly.
      </p>
    </div>
    <div
      className='row'
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <LinkButton
        text='Speak with us'
        color='green'
        linkSrc='https://landing.dochq.co.uk/video-call/'
      />
    </div>
  </div>
);
