import { useEffect } from 'react';
import calculateProductPrice from './calculateProductPrice';

const useOldBasket = (setBasket, products, discount) => {
  useEffect(() => {
    let oldBasket = localStorage.getItem('basket');
    if (oldBasket && products && products.length) {
      oldBasket = JSON.parse(oldBasket);
      oldBasket = oldBasket.map((oldItem) => {
        const {
          id: oldItemId,
          numberOfKits,
          delivery,
        } = oldItem;
        const product = products.find(({ id: productId }) => productId === oldItemId);
        const {
          totalPrice,
        } = calculateProductPrice(product, numberOfKits, discount, delivery);

        return ({
          ...oldItem,
          price: totalPrice,
        });
      });
      setBasket(oldBasket);
    }
  }, [products, discount]);

};

export default useOldBasket;
