import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useState } from 'react';
import './TextInput.scss';

const TextInputElement = ({
  value,
  onChange,
  pattern = {
    test: (value) => !!value,
  },
  updateStatus,
  label,
  id,
  autoComplete,
  inputProps,
  required,
  style,
  numRows,
  ...rest
}) => {
  const [completedInput, setCompletedInput] = useState(false);
  const [initialErrorFieldSubmitted, setInitialErrorFieldSubmitted] = useState(false);
  if (
    initialErrorFieldSubmitted === false &&
		updateStatus &&
		required === true &&
		value.length === 0
  ) {
    updateStatus(false, label.toLowerCase());
    setInitialErrorFieldSubmitted(true);
  }
  let allStyles = {};
  if (style) {
    allStyles = { ...style };
  } else {
    allStyles = { flex: 1 };
  }
  useEffect(() => {
    if (!!pattern && completedInput && pattern.test(value) && required && updateStatus) {
      updateStatus(true, label.toLowerCase());
    } else if (updateStatus && typeof required === 'undefined' && value.length !== 0) {
      updateStatus(pattern.test(value), label.toLowerCase());
    } else if (completedInput && required && updateStatus) {
      updateStatus(pattern.test(value), label.toLowerCase());
    }
  }, [completedInput]);
  if (required === true && typeof numRows !== 'undefined') {
    return (
      <TextField
        error={completedInput && !pattern.test(value)}
        id={id || `text-field-${(Math.random() * 100).toFixed(0)}`}
        label={label}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        onBlur={() => setCompletedInput(true)}
        onFocus={() => setCompletedInput(false)}
        value={value}
        variant='filled'
        inputProps={inputProps}
        required
        inputMode='text'
        type='text'
        multiline
        rows={numRows}
        {...rest}
        style={allStyles}
      />
    );
  }
  if (required === true && typeof numRows === 'undefined') {
    return (
      <TextField
        error={completedInput && !pattern.test(value)}
        id={id || `text-field-${(Math.random() * 100).toFixed(0)}`}
        label={label}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        onBlur={() => setCompletedInput(true)}
        onFocus={() => setCompletedInput(false)}
        value={value}
        variant='filled'
        inputProps={inputProps}
        required
        {...rest}
        style={allStyles}
      />
    );
  }
  if (required !== true && typeof numRows !== 'undefined') {
    return (
      <TextField
        error={completedInput && !pattern.test(value)}
        id={id || `text-field-${(Math.random() * 100).toFixed(0)}`}
        label={label}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        onBlur={() => setCompletedInput(true)}
        onFocus={() => setCompletedInput(false)}
        value={value}
        variant='filled'
        inputProps={inputProps}
        inputMode='text'
        type='text'
        multiline
        rows={numRows}
        {...rest}
        style={allStyles}
      />
    );
  }
  if (required !== true && typeof numRows === 'undefined') {
    return (
      <TextField
        error={completedInput && !pattern.test(value)}
        id={id || `text-field-${(Math.random() * 100).toFixed(0)}`}
        label={label}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        onBlur={() => setCompletedInput(true)}
        onFocus={() => setCompletedInput(false)}
        value={value}
        variant='filled'
        inputProps={inputProps}
        {...rest}
        style={allStyles}
      />
    );
  }
};
export default TextInputElement;
