import React from 'react';
import LinkButton from '../LinkButton/LinkButton';
import './Packages.scss';

const Packages = () => (
  <>
    <div className='packages-all-container'>
      <div className='row header-row'>
        <h2 className='center'>DocHQ Packages</h2>
      </div>
      <div className='row packages-container'>
        <div className='col-sm-12 col-md-6 col-lg-4'>
          <div className='package'>
            <div className='bordered-box'>
              <div className='package-title'>
                <h4>Employee Engagement</h4>
              </div>
            </div>
            <div className='package-items-list'>
              <ul>
                <li>
                  <Icon />
									Perks and discounts
                </li>
                <li>
                  <Icon />
									Mental wellbeing tools
                </li>
                <li>
                  <Icon />
									Online learning
                </li>
                <li>
                  <Icon />
									Recognition and rewards
                </li>
                <li>
                  <Icon />
									Employee surveys
                </li>
                <li>
                  <Icon />
									Insights dashboard
                </li>
              </ul>
            </div>
            <div className='row button-row'>
              <LinkButton
                linkSrc='https://landing.dochq.co.uk/employee-engagement-registration/'
                color='dark-grey'
                text='Register Online'
              />
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-4 '>
          <div className='package'>
            <div className='bordered-box'>
              <div className='package-title'>
                <h4>Employee Health</h4>
              </div>
            </div>
            <div className='package-items-list'>
              <ul>
                <li>
                  <Icon />
									Everything in Employee Engagement pack
                </li>
                <li>
                  <Icon />
									Mobile app
                </li>
                <li>
                  <Icon />
									Phone &amp; video consultations
                </li>
                <li>
                  <Icon />
									Prescriptions &amp; medication delivery
                </li>
                <li>
                  <Icon />
									Sick notes &amp; HR administrative support
                </li>
                <li>
                  <Icon />
									Follow-up care via email
                </li>
                <li>
                  <Icon />
									Annual health check
                </li>
                <li>
                  <Icon />
									Face-to-face consultation
                </li>
              </ul>
            </div>
            <div className='row button-row'>
              <LinkButton
                color='green'
                text='Speak With Us'
                linkSrc='https://landing.dochq.co.uk/video-call/'
              />
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-4'>
          <div className='package'>
            <div className='bordered-box'>
              <div className='package-title'>
                <h4>Employee Wellness</h4>
              </div>
            </div>
            <div className='package-items-list'>
              <ul>
                <li>
                  <Icon />
									Everything in Employee Health pack{' '}
                </li>
                <li>
                  <Icon />
									Mental health assessment
                </li>
                <li>
                  <Icon />
									Mental health counselling
                </li>
                <li>
                  <Icon />
									Specified occupational health support
                </li>
                <li>
                  <Icon />
									Wellness seminars
                </li>
                <li>
                  <Icon />
									Wellness tools
                </li>
              </ul>
            </div>
            <div className='row button-row'>
              <LinkButton
                color='green'
                text='Speak With Us'
                linkSrc='https://landing.dochq.co.uk/video-call/'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Packages;

const Icon = () => <i className='fas fa-check'></i>;
