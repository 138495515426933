import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import './LinkButton.scss';
/*
	Acceptable colors - pink or green
*/
const LinkButton = ({ text, color, linkSrc, newTab, style, rel, centered }) => {
  if (linkSrc.includes('https://') || linkSrc.includes('http://') || linkSrc.includes('mailto') || newTab) {
    return (
      <a
        href={linkSrc}
        rel={`noopener noreferrer ${rel ? 'nofollow' : ''}`}
        target={newTab ? '_blank' : ''}
        className={centered ? 'centered-link-button-a' : ''}
      >
        <button type='button' style={style} role='button' className={`btn btn-swu-${color}`}>
          {text}
        </button>
      </a>
    );
  } else {
    return (
      <Link to={linkSrc}>
        <button type='button' style={style}  role='button' className={`btn btn-swu-${color}`}>
          {text}
        </button>
      </Link>
    );
  }
};
export default LinkButton;

LinkButton.defaultProps = {
  text: 'DocHQ',
  linkSrc: 'https://dochq.co.uk',
  color: 'pink',
  newTab: false,
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  linkSrc: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  newTab: PropTypes.bool.isRequired,
};
