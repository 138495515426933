import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const GreenCheckbox = withStyles({
  root: {
    color: 'var(--doc-pink)',
    '&$checked': {
      color: 'var(--doc-green)',
    },
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  checked: {},
})(props => <Checkbox color='default' {...props} inputProps={{ 'aria-label': props.label }} />);
export default GreenCheckbox;
