import React from 'react';
import { TextField } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import discountSvc from '../../services/discounts.service.js';
const DiscountCode = ({ value, onChange, style, updateDiscount, ...rest }) => {
  const [completedInput, setCompletedInput] = useState(false);
  const [valid, setValid] = useState(false);
  let allStyles = {};
  if (style) {
    allStyles = { ...style };
  } else {
    allStyles = { flex: 1 };
  }
  useEffect(() => {
    discountSvc
      .getDiscountDetails(value)
      .then(data => {
        if (data.valid && data.type && data.value) {
          updateDiscount(data);
        } else if (!data.valid && data.reason) {
          updateDiscount(data);
        }
        setValid(data.valid);
      })
      .catch(err => {
        updateDiscount({
          valid: false,
          error: err.error,
          errorMsg: err.errorMsg,
        });
        setValid(false);
      });
  }, [completedInput]);
  return (
    <TextField
      error={completedInput && !valid}
      id={'discount-code-input'}
      label={'Discount Code'}
      onChange={e => onChange(e.target.value)}
      onBlur={() => setCompletedInput(true)}
      onFocus={() => setCompletedInput(false)}
      value={value}
      variant='filled'
      {...rest}
      style={allStyles}
    />
  );
};
export default DiscountCode;
