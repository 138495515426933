import React, { useState, useEffect } from 'react';
import TextInputElement from '../TextInputElement';
import './BillingDetails.scss';
const BillingDetails = ({ update, updateStatus }) => {
  const [street_address, setStreet_address] = useState('');
  const [extended_address, setExtended_address] = useState('');
  const [locality, setLocality] = useState('');
  const [region, setRegion] = useState('');
  const [postal_code, setPostal_code] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');

  useEffect(() => {
    update({
      first_name,
      last_name,
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
    });
  }, [first_name, last_name, street_address, locality, region, postal_code]);
  return (
    <>
      <div className='billing-address-details' aria-label='Billing address form section'>
        <div className='row'>
          <h4>Billing Address</h4>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={first_name}
              id='billing-first_name'
              label='First Name'
              onChange={setFirst_name}
              autoComplete='given-name'
              pattern={new RegExp(/^[a-zA-Z ]{2,}$/)}
              inputProps={{ minLength: '2' }}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={last_name}
              id='billing-last_name'
              label='Last Name'
              onChange={setLast_name}
              autoComplete='family-name'
              pattern={new RegExp(/^[a-zA-Z ]{2,}$/)}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={street_address}
              id='billing-street_address'
              label='Address Line 1'
              onChange={setStreet_address}
              autoComplete='billing address-line1'
              pattern={new RegExp(/^[a-zA-Z0-9 ]+$/)}
              inputProps={{ minLength: '1' }}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={extended_address}
              id='billing-extended_address'
              label='Address Line 2'
              onChange={setExtended_address}
              autoComplete='billing address-line2'
              pattern={new RegExp(/^[a-zA-Z0-9 ]+$/)}
              inputProps={{ minLength: '3' }}
              updateStatus={updateStatus}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={locality}
              id='billing-locality'
              label='Town'
              onChange={setLocality}
              autoComplete='billing locality'
              pattern={new RegExp(/^[A-Za-z ]+$/)}
              inputProps={{ minLength: '3' }}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={region}
              id='billing-region'
              label='County'
              onChange={setRegion}
              autoComplete='billing region'
              pattern={new RegExp(/[a-zA-Z ]+$/)}
              inputProps={{ minLength: '3' }}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <TextInputElement
              value={postal_code}
              id='billing-postcode'
              label='Postcode'
              onChange={setPostal_code}
              autoComplete='billing postal_code'
              pattern={
                new RegExp(
                  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
                )
              }
              inputProps={{ maxLength: '8' }}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDetails;
