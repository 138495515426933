import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Field, useFormikContext } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import bookingSvc from '../../services/booking.service';
import './Shops/Shop.scss';

const datePickerTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      colorPrimary: { color: 'var(--doc-pink)' },
    },
    MuiDialog: {
      root: {
        zIndex: '1000000 !important',
      },
    },
    MuiPickersMonth: { monthSelected: { color: 'var(--doc-pink)' } },
    MuiPickersDay: {
      daySelected: {
        '&:hover': { backgroundColor: 'inherit' },
        backgroundColor: 'inherit',
        color: 'var(--doc-pink)',
        MuiIconButton: {
          label: { color: 'var(--doc-pink)' },
        },
      },
      current: {
        color: 'var(--doc-green)',
      },
      dayDisabled: {
        color: 'var(--doc-dark-grey)',
        opacity: '0.5',
        backgroundColor: 'var(--doc-white)!important',
      },
      hidden: {
        opacity: '0 !important',
      },
      day: {
        width: '24px',
        height: '24px',
        marginTop: '5px',
        marginBottom: '5px',
        color: 'var(--doc-black)',
        background: 'var(--doc-white)!important',
      },
    },
    MuiIconButton: {
      label: {
        backgroundColor: 'inherit',
        color: 'inherit',
        transition: '0.3s',
        '&:hover': { backgroundColor: 'var(--doc-pink)', color: 'var(--doc-white)' },
        borderRadius: '50%',
        height: '24px',
        width: '24px',
      },
    },
    MuiButton: {
      label: {
        color: 'var(--doc-green)',
      },
    },
    MuiPickersToolbar: {
      toolbar: { backgroundColor: 'var(--doc-green)' },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        width: '90%',
        border: '2px solid var(--doc-green)',
        borderRadius: '10px',
        minWidth: '200px',
        maxWidth: '300px',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: { fontSize: '22px' },
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: '300px',
        minWidth: '200px',
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-evenly',
      },
    },
    MuiPickersCalendarHeader: {
      daysHeader: { justifyContent: 'space-evenly' },
      dayLabel: {
        width: 'auto',
      },
    },
  },
});

const CheckAvailabilityForm = ({
  bookingUrl,
}) => {
  const [slots, setSlots] = useState([]);
  const { values: { type, date, time }} = useFormikContext();

  const prepareSlots = (arr) => {
    const result = {};
    arr.map((item) => {
      const currentDate = moment(item.start_time).format('DD/MM/YYYY');
      result[currentDate] = (result[currentDate] || 0) + 1;
      return item;
    });
    setSlots(Object.entries(result));
  };

  const getSlots = async () => {
    const timeHours = new Date(time).getHours();
    const timeMinutes = new Date(time).getMinutes();
    if (type === 'Antigen') {
      await bookingSvc
        .getSlotsByTime({
          baseURL: bookingUrl,
          date_time: moment(date).hours(timeHours).minutes(timeMinutes).subtract(48, 'hours').format().replace('+', '%2B'),
          date_time_to: moment(date).hours(timeHours).minutes(timeMinutes).format().replace('+', '%2B'),
        })
        .then(result => {
          if (result.success && result.appointments) {

            prepareSlots([...result.appointments]);
          } else {
            setSlots([]);
          }
        })
        .catch(err => setSlots([]));
    } else {
      await bookingSvc
        .getSlotsByTime({
          baseURL: bookingUrl,
          date_time: moment(date).hours(timeHours).minutes(timeMinutes).format().replace('+', '%2B'),
          date_time_to: moment(date).hours(timeHours).minutes(timeMinutes).add(2, 'day').format().replace('+', '%2B'),
        })
        .then(result => {
          if (result.success && result.appointments) {

            prepareSlots([...result.appointments]);
          } else {
            setSlots([]);
          }
        })
        .catch(err => setSlots([]));
    }
  };

  useEffect(() => {
    if (!!type && !!date && !!time) getSlots();
  }, [type, date, time]);

  return (
    <Box p={1}>
      <h5 style={{ margin: 0, fontSize: 18 }}>
        What test are you looking to book?*
      </h5>
      <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
        <div style={{ maxWidth: '40%', minWidth: '320px' }}>
          <Field name="type">
            {({ field, form, meta }) => (
              <FormControl
                error={!!meta.error && meta.touched}
                touched={meta.touched}
                component='fieldset'
                style={{ width: '100%' }}
              >
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={field.value}
                  label="Select the test you are booking for"
                  {...field}
                  onChange={(({ target: { value } }) => {
                    form.setFieldValue(field.name, value);
                  })}
                >
                  <FormControlLabel value="Antigen" control={<Radio />} label="Fit to fly [Antigen]" />
                  <FormControlLabel value="Day3" control={<Radio />} label="Day 3 Antigen Test [US]" />
                </RadioGroup>
                <FormHelperText>{(meta.error && meta.touched) && meta.error}</FormHelperText>
              </FormControl>
            )}
          </Field>
        </div>
      </div>
      <div>
        <ThemeProvider theme={datePickerTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item xs={12} md={5}>
                <div style={{ marginRight: 20 }}>
                  <h5 style={{ margin: 0, paddingTop: 20, fontSize: 18, paddingBottom: 10 }}>
                    Flight Date
                  </h5>
                  <Field
                    name="date"
                    validate={(value) => {
                      let error;
                      const tmpDate = moment(value);
                      if (!value) {
                        error = 'Input date of birth';
                      } else if (!tmpDate.isValid()) {
                        error = 'Invalid Date';
                      }
                      return error;
                    }}
                  >
                    {({ field, form, meta }) => (
                      <KeyboardDatePicker
                        {...field}
                        required
                        name="dateOfBirth"
                        label="Please select a date"
                        placeholder="dd/mm/yyyy"
                        emptyLabel="dd/mm/yyyy"
                        error={!!meta.error}
                        touched={meta.touched}
                        inputVariant='filled'
                        disablePast={type !== 'Day2'}
                        helperText={(meta.error && meta.touched) && meta.error}
                        variant="filled"
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          form.setFieldValue(field.name, value);
                        }}
                      />
                    )}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <h4 style={{ margin: 0, paddingTop: 20, fontSize: 18, paddingBottom: 10 }}>
                  Flight Time
                </h4>
                <Field name="time">
                  {({ field, form }) => (
                    <KeyboardTimePicker
                      autoOk
                      {...field}
                      name="time"
                      label="Select Departure Time"
                      placeholder="00:00 AM/PM"
                      required
                      inputVariant='filled'
                      onChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
      {(!!type && !!date && !!slots.length) && (
        <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
          <div>
            <h5 style={{ margin: 0, paddingTop: 20, fontSize: 18, paddingBottom: 5 }}>
              Available appointments
            </h5>
            <h5 style={{ margin: 0, paddingTop: 5, fontSize: 18, paddingBottom: 5 }}>
              Great!
            </h5>
            {slots.map((item, indx) => (
              <p key={indx} className="no-margin" style={{ paddingTop: 5 }}>
                On {item[0]},&nbsp;
                <span className="green-available-text">
                  {item[1] * 4} appointments available
                </span>
              </p>
            ))}
          </div>
        </div>
      )}
      {(!!type && !!date && !slots.length) && (
        <>
          <p>
            Unfortunately there are no available appointments for chosen date.<br />
            Contact <span className="red-text">support@dochq.co.uk</span> to request availability for chosen date.
          </p>
          {/* <h5 style={{ margin: 0, padding: '10px 0 10px 0' }}>
            Enter email
          </h5>
          <div className='row' style={{ flexWrap: 'wrap', width: '60%' }}>
            <div style={{ maxWidth: '100%', minWidth: '340px' }}>
              <Field name="email">
                {({ field, meta }) => (
                  <TextInputElement
                    {...field}
                    required
                    id='email'
                    style={{ width: '100%' }}
                    error={!!meta.error && meta.touched}
                    touched={meta.touched}
                    helperText={!!meta.error && meta.touched}
                    label='Email'
                  />
                )}
              </Field>
            </div>
            <div className='row flex-end'>
              <Button
                text='Submit'
                color='green'
                type="submit"
              />
            </div>
          </div> */}
        </>
      )}
    </Box>
  );
};

export default CheckAvailabilityForm;
