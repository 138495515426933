import axios from 'axios';

const dropboxSvc = {
  async searchDropbox(baseURL, postcode) {
    return await new Promise((resolve, reject) => {
      axios({
        url: `${baseURL}/v1/dropbox/location-search?postcode=${postcode}`,
        method: 'GET',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data && response.data.dropboxes) {
            resolve({
              success: true,
              dropboxes: response.data.dropboxes,
            });
          } else {
            reject({
              success: false,
              error: 'Unable to find dropboxes',
            });
          }
        })
        .catch(() => reject({ success: false, error: 'Server Error Occurred' }));
    });
  },
  async getDropboxes(baseURL) {
    return await new Promise((resolve, reject) => {
      axios({
        url: `${baseURL}/v1/dropbox`,
        method: 'GET',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data && response.data.dropboxes) {
            resolve({
              success: true,
              dropboxes: response.data.dropboxes,
            });
          } else {
            reject({
              success: false,
              error: 'Unable to find dropboxes',
            });
          }
        })
        .catch(() => reject({ success: false, error: 'Server Error Occurred' }));
    });
  }
};

export default dropboxSvc;
