import React from 'react';
import { Grid } from '@material-ui/core';
import './HowTestWorks.scss';

const Step = ({ number, icon, children, customIcons }) => {

  return (
    <Grid container justify="space-between" className="step-box" spacing={2}>
      <Grid item xs={4}>
        <img src={icon} className={customIcons ? 'step-icon-custom' : 'step-icon'} />
      </Grid>
      <Grid item xs={8} className="label">
        <div className="number">{number}.</div>
        {children}
      </Grid>
    </Grid>
  );
};

export default Step;
