import React from 'react';
import SEO from '../components/SEO/SEO';
import FitToFlyCountryChecker from '../components/FitToFlyCountryChecker/FitToFlyCountryChecker';

const TravelRestrictionPage = () => (
  <>
    <SEO
      title='Travel Restriction Checker'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    {/* <FitToFlyCountryChecker
				title='What type of Covid-19 tests do you need to travel?'
			/> */}
  </>
);

export default TravelRestrictionPage;
