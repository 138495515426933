import React, { useState, useEffect } from 'react';
import TextInputElement from '../TextInputElement';
import './ShippingDetails.scss';

const ShippingDetails = ({ update }) => {
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [street_address, setStreet_address] = useState('');
  const [extended_address, setExtended_address] = useState('');
  const [locality, setLocality] = useState('');
  const [region, setRegion] = useState('');
  const [postal_code, setPostal_code] = useState('');
  useEffect(() => {
    update({
      first_name,
      last_name,
      street_address,
      extended_address,
      locality,
      region,
      postal_code,
    });
  }, [first_name, last_name, street_address, locality, region, postal_code]);
  return (
    <>
      <div className='shipping-address-details' aria-label='Shipping address form section'>
        <div className='row'>
          <h4>Shipping Address</h4>
        </div>
        <>
          <div className='row'>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={first_name}
                id='shipping-first_name'
                label='First Name'
                onChange={setFirst_name}
                autoComplete='given-name'
                pattern={new RegExp(/^[a-zA-Z ]+$/)}
                inputProps={{ minLength: '2' }}
                required={true}
              />
            </div>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={last_name}
                id='shipping-last_name'
                label='Last Name'
                onChange={setLast_name}
                autoComplete='family-name'
                pattern={new RegExp(/^[a-zA-Z ]+$/)}
                required={true}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={street_address}
                id='shipping-street_address'
                label='Address Line 1'
                onChange={setStreet_address}
                autoComplete='shipping address-line1'
                pattern={new RegExp(/^[a-zA-Z0-9 ]+$/)}
                inputProps={{ minLength: '1' }}
                required={true}
              />
            </div>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={extended_address}
                id='shipping-extended_address'
                label='Address Line 2'
                onChange={setExtended_address}
                autoComplete='shipping address-line2'
                pattern={new RegExp(/^[a-zA-Z0-9 ]+$/)}
                inputProps={{ minLength: '3' }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={locality}
                id='shipping-locality'
                label='Town'
                onChange={setLocality}
                autoComplete='shipping locality'
                pattern={new RegExp(/^[A-Za-z ]+$/)}
                inputProps={{ minLength: '3' }}
                required={true}
              />
            </div>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={region}
                id='shipping-region'
                label='County'
                onChange={setRegion}
                autoComplete='shipping region'
                pattern={new RegExp(/[a-zA-Z ]+$/)}
                inputProps={{ minLength: '3' }}
                required={true}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 input-item'>
              <TextInputElement
                value={postal_code}
                id='shipping-postcode'
                label='Postcode'
                onChange={setPostal_code}
                autoComplete='shipping postal_code'
                pattern={
                  new RegExp(
                    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
                  )
                }
                inputProps={{ maxLength: '8' }}
                required={true}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ShippingDetails;
