import React from 'react';
import './DrugSchedules.scss';

const schedules = [
  {
    number: 1,
    subtitle: 'Contains class A, B & C Drugs',
    content: (
      <p>
				Drugs with no currently accepted medical use and a high potential for abuse.
        <br></br>
        <br></br>
				Examples: herion, lysergic acid diethylamide (LSD), marijuana (cannabis), 3,
				4methylenedioxy-methamphetamine (ecstasy), methaqualone, and peyote.
      </p>
    ),
  },
  {
    number: 2,
    subtitle: 'Contains class A, B & C Drugs',
    content: (
      <p>
				Drugs with a high potential for abuse, with use potentially leading to severe psychological
				or physical dependence.
        <br></br>
        <br></br>
				Examples: Combination products with less than 15mg of hydrocodone per dosage (Vicodin),
				cocaine, methamphetamine, methadone, hydromorphone (Dilaudid), oxycodone.
      </p>
    ),
  },
  {
    number: 3,
    subtitle: 'Contains class B & C Drugs',
    content: (
      <p>
				Drugs with a moderate to low potential for potential for physical and psychological
				dependence.
        <br></br>
        <br></br>
				Examples: Products containing less than 90mg of codeine per dosage unit (Tylenol with
				codeine), ketamine, anabolic steroids, testosterone.
      </p>
    ),
  },
  {
    number: 4,
    subtitle: 'Contains class B & C Drugs',
    content: (
      <p>
				Drugs with a low potential for abuse and low risk of dependence.
        <br></br>
        <br></br>
				Examples: Xanax, Soma, Darvon, Darvocet, Valium, Ativan, Talwin, Ambien, Tramadol.
      </p>
    ),
  },
  {
    number: 5,
    subtitle: 'Contains class B & C Drugs',
    content: (
      <p>
				Drugs with a very lower potential for abuse and consist of preparations containing limited
				quantities of certain narcotics.
        <br></br>
        <br></br>
				Examples: cough preparations with less than 200mg of codeine or per 100ml (Robitussin AC),
				Lomotil, Motofen, Lyrica, Parepectolin
      </p>
    ),
  },
];
const DrugSchedules = () => (
  <>
    <div className='drug-schedules-container'>
      <div className='row'>
        <div className='drug-schedules-title'>
          <h3>
						If your medicine contains a controlled drug, it's listed in the Controlled Substances
						Act of 1970.
          </h3>
        </div>
      </div>
      <div className='row drug-schedules-row'>
        {schedules.map(item => (
          <Schedule
            key={item.number}
            number={item.number}
            subtitle={item.subtitle}
            content={item.content}
          />
        ))}
      </div>
      <p
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '0px',
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
				Most countries will not let you travel with schedule 1 drugs, whereas it may be possible to
				carry schedule 2-5 drugs with the right supporting documentation.
      </p>
    </div>
  </>
);

export default DrugSchedules;

const Schedule = ({ number, subtitle, content }) => (
  <div className='drug-schedule col-sm-12 col-md-2'>
    <div className='title'>
      <h5>Schedule</h5>
      <span className='schedule-number'>{number}</span>
    </div>
    <div className='subtitle'>
      <p>
        <strong>{subtitle}</strong>
      </p>
    </div>
    <div className='sep'></div>
    <div className='content'>{content}</div>
  </div>
);
