import React from 'react';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import BackgroundWCenterContent from '../components/BackgroundWCenterContent/BackgroundWCenterContent';
import LinkButton from '../components/LinkButton/LinkButton';
import ComingSoonAd from '../components/ComingSoonAd/ComingSoonAd';
import tetanus from '../assets/images/tetanus.png';
import diptheria from '../assets/images/diptheria.png';
import polio from '../assets/images/polio.png';
import typhoid from '../assets/images/typhoid.png';
import hepatitisA from '../assets/images/hepatitis-a.png';
import hepatitisB from '../assets/images/hepatitis-b.png';
import cholera from '../assets/images/cholera.png';
import japaneseEncephalitis from '../assets/images/japanese-encephalitis.png';
import meningitisA from '../assets/images/meningitis-a.png';
import rabies from '../assets/images/rabies.png';
import tbe from '../assets/images/tbe.png';
import tb from '../assets/images/tb.png';
import yellowFever from '../assets/images/yellow-fever.png';
import mmr from '../assets/images/mmr.png';

const TravelVaccinationPage = () => (
  <>
    <SEO
      title='Available Travel Vaccinations'
      description='We take care of all your travel health needs, offering a complete range of travel vaccinations, antimalarials and experts advice.'
    />
    <HeroBanner
      title='Travel Vaccinations'
      tagline='We take care of all your travel health needs, offering a complete range of travel vaccinations, antimalarials and experts advice.'
      imageAlt='Medicine bottle being held in a clinical environment'
      imageSrc='travel-vaccination.png'
    />
    {/* <BackgroundWCenterContent
      content={<BGContent />}
      imageAlt='stethoscope background'
      imageSrc='cqc-clinics.png'
    /> */}
    <ComingSoonAd
      contentTitle='Tetanus'
      contentBody={<Tetanus />}
      imageAlt='Tetanus'
      imageSrc={tetanus}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Diptheria'
      contentBody={<Diptheria />}
      imageAlt='Diptheria'
      imageSrc={diptheria}
    />
    <ComingSoonAd
      contentTitle='Polio'
      contentBody={<Polio />}
      imageAlt='Polio'
      imageSrc={polio}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Typhoid'
      contentBody={<Typhoid />}
      imageAlt='Typhoid'
      imageSrc={typhoid}
    />
    <ComingSoonAd
      contentTitle='Hepatitis-A'
      contentBody={<HepatitisA />}
      imageAlt='Hepatitis-A'
      imageSrc={hepatitisA}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Hepatitis-B'
      contentBody={<HepatitisB />}
      imageAlt='Hepatitis-B'
      imageSrc={hepatitisB}
    />
    <ComingSoonAd
      contentTitle='Cholera'
      contentBody={<Cholera />}
      imageAlt='Cholera'
      imageSrc={cholera}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Japanese Encephalitis'
      contentBody={<JapaneseEncephalitis />}
      imageAlt='Japanese Encephalitis'
      imageSrc={japaneseEncephalitis}
    />
    <ComingSoonAd
      contentTitle='Meningitis'
      contentBody={<Meningitis />}
      imageAlt='Meningitis'
      imageSrc={meningitisA}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Rabies'
      contentBody={<Rabies />}
      imageAlt='Rabies'
      imageSrc={rabies}
    />
    <ComingSoonAd
      contentTitle='Tick Borne Encephalitis'
      contentBody={<TBE />}
      imageAlt='Tick Borne Encephalitis'
      imageSrc={tbe}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Tuberculosis (TB)'
      contentBody={<TB />}
      imageAlt='Tuberculosis (TB)'
      imageSrc={tb}
    />
    <ComingSoonAd
      contentTitle='Yellow Fever'
      contentBody={<YellowFever />}
      imageAlt='Yellow Fever'
      imageSrc={yellowFever}
      side={'left'}
    />
    <ComingSoonAd
      contentTitle='Measles, Mumps and Rubella'
      contentBody={<MeaslesPlus />}
      imageAlt='Measles, Mumps and Rubella'
      imageSrc={mmr}
    />
  </>
);

export default TravelVaccinationPage;

const BGContent = () => (
  <>
    <h2>DocHQ works with CQC Registered Clinics</h2>
    <p>For further travel vaccine advice, book a clinic appointment near you today.</p>
    <LinkButton text='Find Nearest Clinic' linkSrc='/travel-vaccine' color='green' />
  </>
);

const Tetanus = () => (
  <>
    <p>
			Tetanus is a serious condition caused by a bacterial toxin that affects your nervous system,
			leading to painful muscle contractions, particularly of your jaw and neck muscles.
    </p>
    <p>Tetanus can interfere with your ability to breathe and can threaten your life. </p>
    <p>
			The bacteria can survive for a long time outside the body, so is found everywhere in the
			environment, commonly in the soil and in the manure of animals such as horses or cows.
    </p>
    <p>The tetanus vaccine is considered to be 100% effective in preventing tetanus.</p>
  </>
);
const Diptheria = () => (
  <>
    <p>
			Diphtheria is a highly contagious and potentially fatal infection that can affect the nose and
			throat and sometimes the skin. It's rare in the UK, but there's a small risk of catching it
			while travelling in some parts of the world.
    </p>
    <p>
			It's spread by coughs and sneezes or through close contact with someone who's infected. You
			can also get it by sharing items, such as cups, cutlery, clothing or bedding, with an infected
			person.
    </p>
    <ul className='shwd-list'>
      <li>Asia</li>
      <li>The South Pacific</li>
      <li>The Middle East</li>
      <li>Eastern Europe</li>
      <li>The Caribbean</li>
    </ul>
  </>
);
const Polio = () => (
  <>
    <p>
			Polio is an infectious disease caused by the poliovirus. For a small percentage of people, the
			polio virus causes temporary or permanent paralysis, which can be life threatening.
    </p>
    <p>
			You can become infected with the polio virus if you come into contact with the feaces of
			someone with the infection, or with the droplets launched into the air when they cough or
			sneeze.
    </p>
    <p>
			You can also get the infection from food or water that's been contaminated with infected
			feaces or droplets.
    </p>

    <p>
			Polio is still found in many places. It's still a significant problem in Afghanistan, Nigeria
			and Pakistan, and there's a potential risk of infection in other parts of Africa and some
			Middle Eastern countries.
    </p>
    <p>
			There's no cure for polio, so it's important to make sure that you are fully vaccinated
			against it.
    </p>
  </>
);
const Typhoid = () => (
  <>
    <p>
			Typhoid fever is a bacterial infection that can spread throughout the body. Without prompt
			treatment, it can cause serious complications and can be fatal.
    </p>
    <p>
			Typhoid fever is highly contagious. An infected person can pass the bacteria out of their body
			in their feaces or urine. If someone else eats food or drinks water that's been contaminated
			they can become infected with the bacteria and develop typhoid fever.
    </p>
    <p>
			Typhoid fever is most common in parts of the world that have poor sanitation and limited
			access to clean water. You're also at risk of developing the infection if you visit Asia,
			Africa or South America.
    </p>
  </>
);
const HepatitisA = () => (
  <>
    <p>
			Hepatitis A is a liver infection caused by a virus that's spread in the feaces of an infected
			person. It's uncommon in the UK, but certain groups are at increased risk.
    </p>
    <p>
			This includes travellers to parts of the world with poor levels of sanitation, men who have
			sex with men and people who inject drugs.
    </p>
    <p>
			It's not usually serious and most people make a full recovery within a couple of months. In
			rare cases, it can be life threatening if it causes liver failure.
    </p>
    <p>
			Hepatitis A is most widespread in parts of the world where standards of sanitation and food
			hygiene are generally poor, such as parts of Africa, the Indian subcontinent, Far East, Middle
			East, Central and South America.
    </p>
    <p>You can get the infection from:</p>
    <ul className='shwd-list'>
      <li>
				Eating food prepared by someone with the infection who has not washed their hands properly
				or washed them in water contaminated with sewage
      </li>
      <li>Close contact with someone who has Hepatitis A</li>
      <li>
				Less commonly, having sex with someone with Hepatitis A or injecting drugs using
				contaminated equipment
      </li>
      <li>Eating raw or undercooked shellfish from contaminated water</li>
      <li>Drinking contaminated water, including ice cubes</li>
    </ul>
  </>
);
const HepatitisB = () => (
  <>
    <p>
			Hepatitis B is a virus, which is a major cause of serious liver disease, including scarring of
			the liver (cirrhosis) and liver cancer.
    </p>
    <p>
			You can get infected with hepatitis B if you have contact with an infected person's blood or
			other body fluids.
    </p>
    <p>
			The hepatitis B vaccine is very safe. Other than some redness and soreness at the site of the
			injection, side effects are rare. It's an inactivated vaccine, so it cannot cause the
			infection itself.
    </p>
  </>
);
const Cholera = () => (
  <>
    <p>Cholera is an infection that can cause severe diarrhoea.</p>
    <p>You can catch cholera from:</p>
    <ul className='shwd-list'>
      <li>Drinking unclean water</li>
      <li>Eating food that's been handled by an infected person</li>
      <li>Eating food (particularly shellfish) that's been in unclean water</li>
    </ul>
    <p>
			It's not found in the UK, but there's a risk of getting it while travelling in some parts of
			the world. It's mainly found in places without a clean water supply or modern sewage system,
			such as parts of Africa and Asia.
    </p>
  </>
);
const JapaneseEncephalitis = () => (
  <>
    <p>Japanese Encephalitis is a viral brain infection that's spread through mosquito bites. </p>
    <p>It's most common in rural areas in southeast Asia, the Pacific islands and the Far East.</p>
    <p>
			The virus is found in pigs and birds and is passed to mosquitoes when they bite infected
			animals. It cannot be spread from person to person.
    </p>
    <p>
			A small number of people who become infected with Japanese encephalitis develop more severe
			symptoms as the infection spreads to the brain and may die as a result of the infection.
    </p>
    <p>
			It's rare for travellers visiting risk areas to be affected by Japanese encephalitis. But the
			risk is greater if you're planning to visit rural areas, go hiking or camping.
    </p>
  </>
);
const Meningitis = () => (
  <>
    <p>
			Meningitis is observed worldwide. It's an infection of the protective membranes that surround
			the brain and spinal cord. It can affect anyone, but is most common in babies, young children,
			teenagers and young adults.
    </p>
    <p>
			Meningitis can be very serious if not treated quickly, causing life-threatening blood
			poisoning and result in permanent damage to the brain or nerves. It is usually caught from
			people who carry these viruses or bacteria in their nose or throat but are not ill themselves.
    </p>
    <p>
			Meningitis can be caused by a number of different infections, so several vaccinations offer
			some protection against it.
    </p>
    <p>
			The Meningitis B vaccine is a new vaccine that offers protection against meningococcal group B
			bacteria, which are a common cause of meningitis in young children in the UK.
    </p>
    <p>
			The Pneumococcal Vaccine offers protection against serious infections caused by pneumococcal
			bacteria, including meningitis.
    </p>
    <p>
			The Meningitis C Vaccine offers protection against a type of bacteria called meningococcal
			group C bacteria, which can cause meningitis. The MMR Vaccine offers protection against
			measles, mumps and rubella.
    </p>
    <p>Meningitis can sometimes occur as a complication of these infections. </p>
    <p>
			The Meningitis ACWY Vaccines offers protection against 4 types of bacteria that can cause
			meningitis: meningococcal groups A, C, W and Y.
    </p>
  </>
);
const Rabies = () => (
  <>
    <p>
			Rabies is a rare but very serious infection of the brain and nerves. It's usually caught from
			the bite or scratch of an infected animal, most often a dog.
    </p>
    <p>
			Rabies is found throughout the world, particularly in Asia, Africa, Central and South America.
    </p>
    <p>It's not found in the UK, except in a small number of wild bats.</p>
    <p>
			It's almost always fatal once symptoms appear, but treatment before this is very effective.
    </p>
    <p>
			You should consider getting vaccinated against rabies if you're travelling to an area of the
			world where rabies is common, you plan to stay for a month or more, or there's unlikely to be
			quick access to appropriate medical care.
    </p>
    <p>
			The vaccine should also be considered if you plan to do activities that could put you at
			increased risk of exposure to animals with rabies, such as running or cycling.
    </p>
  </>
);
const TBE = () => (
  <>
    <p>
			Tick-borne Encephalitis (TBE) is an infection that can affect the central nervous system/brain
			and is transmitted to humans by a bite from an infected tick.
    </p>
    <p>
			Ticks that spread it are found in parts of Europe and Asia, and some parts of the UK. Ticks
			live in forests and grassy areas.
    </p>
    <p>
			You're more at risk of being bitten if you do activities such as hiking and camping. Not all
			ticks spread TBE. Even if you're bitten, the risk of getting seriously ill is low.
    </p>
    <p>
			Consider having the TBE vaccine if you're visiting a country where the infection is common and
			you're planning to do outdoor activities when you get there.
    </p>
  </>
);
const TB = () => (
  <>
    <p>
			Tuberculosis (TB) is a serious bacterial infection that affects the lungs and sometimes other
			parts of the body, such as the bones, joints, nervous system and kidneys.
    </p>
    <p>It can also cause meningitis.</p>
    <p>
			It is a serious condition, but it can be cured if it's treated with the right antibiotics.
    </p>
    <p>
			TB is spread through inhaling tiny droplets from the coughs or sneezes of an infected person.
    </p>
    <p>Countries with high rates of TB include:</p>
    <ul className='shwd-list'>
      <li>West Africa – particularly Sub-Sahara and West Africa</li>
      <li>South Asia – including India, Pakistan, Indonesia and Bangladesh</li>
      <li>The Western Pacific region – including Vietnam, Cambodia and the Philippines</li>
      <li>Russia</li>
      <li>China</li>
      <li>South America</li>
    </ul>
  </>
);
const YellowFever = () => (
  <>
    <p>Yellow Fever is a serious virus spread by mosquito bites.</p>
    <p>
			There's no cure for yellow fever, but the symptoms can be treated while your body fights off
			the infection. Most people make a full recovery after 3 or 4 days.
    </p>
    <p>
			The vaccine provides life long protection that can stop you getting it if you're travelling to
			an area where the infection is found.
    </p>
    <p>
			If you're travelling to an area where yellow fever is found, try to avoid being bitten by
			mosquito’s, even if you have been vaccinated.
    </p>
    <p>Yellow fever is found in:</p>
    <ul className='shwd-list'>
      <li>Sub-Saharan Africa</li>
      <li>Parts of Central America</li>
      <li>Parts of the Caribbean</li>
      <li>Most of South America</li>
    </ul>
  </>
);
const MeaslesPlus = () => (
  <>
    <p>
			Measles is a highly infectious viral illness. With cold like symptoms, a fever and rash,
			complications can lead to encephalitis (infection of the brain) and pneumonia (infection of
			the lungs) which can lead to death especially in young children.
    </p>
    <p>
			Mumps is a viral infection affecting the salivary glands, complicataions can lead to
			meningitis, deafness and orchitis (infection of the testicles).
    </p>
    <p>
			Rubella is a viral infection which in pregnancy is a severe and potentially fatal illness for
			the unborn baby.
    </p>
    <p>
			There is not specific treatment for any of these conditions but the MMR vaccine can prevent
			all three and is highly recommended before travelling to Asia, Africa and South America where
			infection is prevalent.
    </p>
  </>
);
