import React from 'react';
import './Shops/Shop.scss';

const Banner = ({ color = 'green', children }) => (
  <div className={`banner ${color}-background`}>
    {children}
  </div>
);

export default Banner;
