export const PRODUCTION = {
  baseURL: 'https://dochq.co.uk/',
  baseAPI: 'https://dochq.co.uk/api/',
  jsonAPI: 'https://api.dochq.co.uk',
  bookingAPI: 'https://dochq-booking-api.dochq.co.uk',
  discountAPI: 'https://services-discounts.dochq.co.uk/',
  covid19API: 'https://services-covid-19.dochq.co.uk/',
  contactAPI: 'https://api.dochq.co.uk/v1/contact',
  brochureAPI: 'https://hooks.zapier.com/hooks/catch/4296899/orfbark/',
  shopIframeUrl: 'https://airportal.dochq.co.uk/rapid-antigen-testing-order',
  myhealthUrl: 'https://myhealth.dochq.co.uk',
  websiteUrl: 'https://dochq.co.uk',
  portalUrl: 'https://my.dochq.health',
  featureFlags: {},
};
