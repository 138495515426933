import { useStaticQuery, graphql } from 'gatsby';
import ENV_VARS from '../config';

const useConfig = () => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        deploymentType
      }
    }
  }
`);
  return {
    config: ENV_VARS[data.site.siteMetadata.deploymentType],
    env: data.site.siteMetadata.deploymentType,
  };
};

export default useConfig;
