import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import './ShopBanner.scss';

export default function ShopBanner ({
  title,
  imageSrc,
  imageAlt,
  backgroundStyle,
  list,
  titleClassName = '',
  subTitle = '',
  listClassName = '',
  footer,
  side = 'left',
}) {
  return (
    <div className='shop-banner-container'>
      <div className='row'>
        {side === 'left' ? (
          <>
            <div className='col col-sm-12 col-md-6 col-lg-5' id='shop-left'>
              <ShopBannerImage
                imageAlt={imageAlt}
                imageSrc={imageSrc}
                backgroundStyle={backgroundStyle}
              />
            </div>
            <div className='col col-sm-12 col-md-6 col-lg-7' id='shop-right'>
              <div className='shop-banner-title'>
                <h2 className={`no-margin ${titleClassName}`}>
                  {title}
                </h2>
              </div>
              {subTitle && (
                <div>
                  <h4 className="shop-banner-subtitle">
                    {subTitle}
                  </h4>
                </div>
              )}
              <div className="shop-banner-tagline">
                <ul className={`shwd-list ${!!listClassName && listClassName}`}>
                  {list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
              {footer}
            </div>
          </>
        ) : (
          <>
            <div className='col col-sm-12 col-md-6 col-lg-5' id='shop-left'>
              <div className='shop-banner-title'>
                <h2 className="no-margin">
                  {title}
                </h2>
              </div>
              {subTitle && (
                <div>
                  <h4 className="shop-banner-subtitle">
                    {subTitle}
                  </h4>
                </div>
              )}
              <div className="shop-banner-tagline">
                <ul className={`shwd-list ${!!listClassName && listClassName}`}>
                  {list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
              {footer}
            </div>
            <div className='col col-sm-12 col-md-6 col-lg-7' id='shop-right'>
              <ShopBannerImage
                imageAlt={imageAlt}
                imageSrc={imageSrc}
                backgroundStyle={backgroundStyle}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
ShopBanner.defaultProps = {
  title: 'I am the title',
  imageSrc: '',
  imageAlt: 'People working hard',
};

ShopBanner.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};
const ShopBannerImage = ({ imageSrc, imageAlt, backgroundStyle }) => (
  <StaticQuery
    query={graphql`
			{
				background: allImageSharp(filter: { original: { src: { regex: "/" } } }) {
					edges {
						node {
							fluid(maxWidth: 1000) {
								originalName
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`}
    render={data => {
      const myImg = data.background.edges.find(img => img.node.fluid.originalName === imageSrc);
      return (
        <BackgroundImage
          aria-label={imageAlt}
          role='img'
          className='shop-banner-image'
          fluid={myImg.node.fluid}
          style={{ position: 'static', ...backgroundStyle }}
        ></BackgroundImage>
      );
    }}
  ></StaticQuery>
);
