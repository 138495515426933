import React from 'react';
import LinkImage from '../LinkImage';
import { Box } from '@material-ui/core';
import './ImageBanner.scss';

const ImageBanner = ({
  imageSrc,
  imageAlt,
  imageSubtitle,
  contentTitle,
  contentSubTitle,
  contentBody,
  wideImg,
  generalTitle,
  imageLink,
  footer,
  greyBackground,
}) => (
  <Box style={{background: greyBackground ? '#efeff0' : '#ffffff'}}>
    <div className={'image-banner-container'}>
      <div className={`coming-soon-ad ${greyBackground && 'grey-background'}`}>
        {generalTitle && <h2 style={{ textAlign: 'center' }}>{generalTitle}</h2>}
        <div className='row'>
          <div className='coming-soon-item left col-sm-12 col-md-4'>
            <div className="special-image-container">
              <LinkImage
                linkSrc={imageLink}
                imageSubtitle={imageSubtitle}
                imageAlt={imageAlt}
                imageSrc={imageSrc}
                className={wideImg ? 'wideImg' : 'img'}
              />
            </div>
          </div>
          <div className='coming-soon-item col-sm-12 col-md-8'>
            {contentTitle && (
              <>
                <h2 className={`title ${wideImg ? 'wide-title' : ''}`} style={{ marginTop: '20px' }}>
                  {contentTitle}
                </h2>
                {contentSubTitle && (
                  <h4 className={`subtitle ${wideImg ? 'wide-subtitle' : ''}`}>
                    {contentSubTitle}
                  </h4>
                )}
              </>
            )}
            {!!contentBody && !!contentBody.length && (
              <Box className='row' pt={1}>
                <div className='iframe-side-content col-sm-12 col-md-4'>
                  <p className='bold' style={{ marginTop: '2rem' }}>We offer:</p>
                  <ul className='shwd-list shwd-list-partners'>
                    {
                      contentBody[0].map((listItem, index) => (
                        <li>{listItem}</li>
                      ))
                    }
                  </ul>
                </div>
                <div className='iframe-side-content col-sm-12 col-md-4' style={{ marginLeft: 20 }}>
                  <p className='bold' style={{ marginTop: '2rem' }}>You can:</p>
                  <ul className='shwd-list shwd-list-partners'>
                    {
                      !!contentBody[1] && contentBody[1].map((listItem, index) => (
                        <li>{listItem}</li>
                      ))
                    }
                  </ul>
                </div>
              </Box>
            )}
            {!contentBody || !contentBody.length && (
              <Box className='row' pt={1}>
                <div className='iframe-side-content col-sm-12 col-md-4'>
                  <p className='bold' style={{ marginTop: '2rem' }}>We offer:</p>
                  <ul className='shwd-list shwd-list-partners'>
                    <li>A team of nurses, available 24/7 to help with all your health questions</li>
                    <li>A team of doctors review your results and help your with your treatment options</li>
                    <li>Safe and secure platform to access results and view your health records</li>
                  </ul>
                </div>
                <div className='iframe-side-content col-sm-12 col-md-4' style={{ marginLeft: 20 }}>
                  <p className='bold' style={{ marginTop: '2rem' }}>You can:</p>
                  <ul className='shwd-list shwd-list-partners'>
                    <li>Speak with our care team anytime, 24/7</li>
                    <li>Book flexible online or in clinic appointments</li>
                    <li>Track your lab and health information from one place</li>
                  </ul>
                </div>
              </Box>
            )}
          </div>
          <div>{footer}</div>
        </div>
      </div>
    </div>
  </Box>
);

export default ImageBanner;
