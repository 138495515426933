import React from 'react';
import audience from '../../assets/icons/audience-engagement.svg';
import freeTool from '../../assets/icons/free-tool.svg';
import miyo from '../../assets/icons/miyo.svg';
import monetize from '../../assets/icons/monetize-blog.svg';
import otp from '../../assets/icons/on-time-payments.svg';
import invest from '../../assets/icons/zero-investment.svg';
import './AffiliateWhy.scss';

const AffiliateWhy = () => (
  <>
    <div className='affiliate-why-container'>
      <div className='affiliate-why'>
        <div className='row'>
          <h2>Why Register With Us?</h2>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${freeTool})` }}></div>
              <div className='icon-text'>
                <h4>Free Tool</h4>
                <p>To enable you to share medical information about travel vaccinations</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${monetize})` }}></div>
              <div className='icon-text'>
                <h4>Monetize Your Blog</h4>
                <p>
									Earn revenue by promoting vaccination information in your content and sharing with
									your followers
                </p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${audience})` }}></div>
              <div className='icon-text'>
                <h4>Audience Engagement</h4>
                <p>
									By writing about travel health, you are adding engaging content for your readers.
                </p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${miyo})` }}></div>
              <div className='icon-text'>
                <h4>Make it Your Own</h4>
                <p>Add your own brand colours to the free tool to make it your own.</p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${otp})` }}></div>
              <div className='icon-text'>
                <h4>On Time Payments</h4>
                <p>
									Our transactions are transparent with appropriate invoices and on time payments.
                </p>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='icon-container'>
              <div className='icon' style={{ backgroundImage: `url(${invest})` }}></div>
              <div className='icon-text'>
                <h4>Zero Investment</h4>
                <p>See your revenue grow with absolutely zero investment and minimal effort.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <h2 style={{ color: 'var(--doc-pink)' }}>
						Earn up to 50% in commission on each transaction
          </h2>
        </div>
      </div>
    </div>
  </>
);

export default AffiliateWhy;
