import React from 'react';
import './Benefits.scss';

const DataSources = ({ title, subtitle, content, button }) => (
  <div className='benefits-container'>
    <div className='row padded-row' style={{ paddingTop: 0 }}>
      <div className='col-12 col-md-5 col-lg-4 benefits-left'>
        {typeof title !== 'undefined' && (
          <h1 className='benefits-title'>
            {title}
            <i className='fas fa-circle html-period'></i>
          </h1>
        )}
        {typeof subtitle !== 'undefined' && <h4 className='text-white'>{subtitle}</h4>}
        {typeof button !== 'undefined' && <div>{button}</div>}
      </div>
      <div className='col-12 col-md-7 col-lg-8 row benefits-right' style={{ margin: 'auto' }}>
        {typeof content !== 'undefined' &&  content }
      </div>
    </div>
  </div>
);

export default DataSources;
