import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import HeaderWithContent from '../components/HeaderWithContent/HeaderWithContent';
import '../assets/styles/covid-risk-page.scss';

// TODO! Add covid test kit links when product page created
const Covid19RiskAssessmentTool = () => (
  <>
    <HeroBanner
      title='Worried about Covid-19'
      tagline='Fill in this free Covid-19 risk assessment tool for detailed guidance on your next steps'
      imageAlt='Lady swabbing mouth'
      imageSrc='swab-test.jpg'
    />
    <div className='covid-risk-assessment-container'>
      <iframe id='hf-iframe' src='https://covid19.infermedica.com/en?header=false'></iframe>
    </div>
    <HeaderWithContent
      header=''
      content={
        <>
          <p>
						You should consider testing for Covid-19 if you are experiencing Covid-19 symptoms, you
						have recently travelled to a high-risk country or you think you may have come into
						contact with someone who has Covid-19.
          </p>
          <h3 style={{ marginBottom: '0.5rem' }}>Covid-19 Home Test Kit</h3>
        </>
      }
      color='var(--doc-white)'
      backgroundColor='var(--doc-pink)'
      hasButton={true}
      buttonColor='green'
      buttonLinkSrc='/covid-19-home-test-kit'
      buttonText='Buy Now'
    />
  </>
);

export default Covid19RiskAssessmentTool;
