import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Step from './Step';
import './HowTestWorks.scss';
import mobile from '../../assets/icons/mobile.svg';
import list from '../../assets/icons/list-with-bottle.svg';
import doctor from '../../assets/icons/doctor.svg';

const HowTestWorks = ({
  icons,
  steps,
  header,
}) => {
  const defaultSteps = steps || [
    'Book your Appointment',
    'Do your supervised test',
    'Review outcome with a Doctor',
  ];
  const defaultIcons = icons || [
    mobile,
    list,
    doctor,
  ];
  const defaultStepsAdditionalText = {
  };

  return (
    <Grid container alignContent="center" justify="center" className="how-test-works-box" my={4}>
      <Grid item xs={10}>
        <h3 style={{ textAlign: 'center', fontSize: 30, paddingBottom: 20 }}>
          {header || 'How DocHQ Tests Work'}
        </h3>
        <Grid className='steps-container' container justify="space-around" alignItems="center" my={4}>
          {defaultSteps.map((label, i) => {
            let result = label;
            if (defaultStepsAdditionalText[i]) {
              result = (
                <p className="step-label no-margin">
                  {label}
                  <br />
                  <Typography className="additional-text">
                    {defaultStepsAdditionalText[i]}
                  </Typography>
                </p>
              );
            }
            return (
              <Grid key={i} item xs={12} md={3} style = {{minWidth: '295px'}}>
                <Step
                  icon={defaultIcons[i]}
                  number={i + 1}
                  customIcons={!!icons}
                >
                  {result}
                </Step>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowTestWorks;
