import React from 'react';
import './LoadingSpinner.scss';
const LoadingSpinner = () => (
  <>
    <div className='loading-spinner-container'>
      <div className='loading-spinner'></div>
      <p>Loading</p>
    </div>
  </>
);

export default LoadingSpinner;
