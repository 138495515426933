import React from 'react';
import { Box } from '@material-ui/core';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import ComingSoonAdExtended from '../components/ComingSoonAdExtended/ComingSoonAdExtended';
import vision from '../assets/images/vision.jpg';
import mission from '../assets/images/mission.jpg';
import TeamBlock from '../components/TeamBlock/TeamBlock';

const AboutUsPage = () => (
  <>
    <SEO
      title='Contact Us | DocHQ – Your Health | Your Choice | Your Control'
      description='At DocHQ, our vision for healthcare is one that is driven by information, powered by technology, and accessible to everyone.'
      keywords='DocHQ, DocHQ team, DocHQ people, DocHQ vision, DocHQ mission, Health Tech, Healthcare'
    />
    <HeroBanner
      title='About us'
      tagline='DocHQ. Your wellbeing partner.'
      imageAlt='workplace'
      imageSrc='workplace.jpg'
    />
    <WhoWeAre />
    <Box>
      <ComingSoonAdExtended
        contentTitle='Our Vision'
        contentBody={<Vision />}
        imageAlt='city view with binocular'
        imageSrc={vision}
      />
    </Box>
    <Box>
      <ComingSoonAdExtended
        contentTitle='Our Mission'
        contentBody={<Mission />}
        imageAlt='Target'
        imageSrc={mission}
        side={'left'}
      />
    </Box>
    <TeamBlock
      title='Our Team'
      greyBackground
      members={[
        {
          image: 'madhur.jpg',
          name: 'Madhur Srivastava',
          position: 'CEO',
        },
        {
          image: 'simon.jpg',
          name: 'Dr. Simon Chaplin Rogers',
          position: 'Medical Advisor',
          positionInfo: 'BDS (Honours) LDS RCS (Eng) MBBS (University College London)',
        },
        {
          image: 'harjeev.jpg',
          name: 'Dr. Harjeev Rai',
          position: 'Medical Advisor',
          positionInfo: 'MB BCh, DFFP, DRCOG, PG Dip Cardiology, MRCGP, PGCME (Cambridge)',
        },
        {
          image: 'graham.jpg',
          name: 'Graham Carter',
          position: 'Head of Operations',
        },
        {
          image: 'oleg.jpg',
          name: 'Oleg Vakheta',
          position: 'Head of Technlogy',
        },
      ]}
    />
  </>
);

export default AboutUsPage;

const OurAward = () => (
  <>
    <p style={{fontWeight: 'bold'}}>Green Borough and Healthy Community Awards</p>
    <p>Healthy Community Award winner</p>
  </>
);
const Vision = () => (
  <>
    <p>
      Our vision for healthcare is one that is driven by information, powered by technology, and accessible to everyone.
    </p>
  </>
);
const WhoWeAre = () => (
  <>
    <div className='who-we-are-container'>
      <h2 className='title'>Who We Are</h2>
      <h4 className='text'>
				DocHQ is a Health Tech company improving your choices.
        <br />
				We help you connect efficiently to health support and advice whether you are at home, work
				or travelling.
      </h4>
    </div>
  </>
);
const Mission = () => (
  <>
    <p className='text'>
      We are building a people centered, high-quality, integrated healthcare network using technology to access personalized medical answers and solutions to improve health and well being.
    </p>
  </>
);
