import React from 'react';
import Modal from '../../../Modal/Modal';
import '../Shop.scss';
import './AmexShop.scss';

const AmexModal = ({
  isVisible,
  onClose,
  isAmexIt,
}) => (
  <Modal
    isVisible={isVisible}
    title="Contact & Support"
    onClose={onClose}
  >
    <div className="shop-page amex-shop">
      {isAmexIt ? (
        <>
          <h4 className="amex-modal-title">
            American Express
          </h4>
          <p>
              Per domande relative ai voucher per i test rapidi, si prega di contattare&nbsp;
            <a href="mailto:CEG_Italy@aexp.com" className="link">
                CEG_Italy@aexp.com
            </a>
          </p>
          <h4 className="amex-modal-title">DocHQ</h4>
          <p>
            Per domande relative alla spedizione/consegna dei test rapidi e altro, si prega di contattare&nbsp;
            <a href="mailto:contactus@dochq.co.uk" className="link">
              contactus@dochq.co.uk
            </a>.
          </p>
        </>
      ) : (
        <>
          <h4 className="amex-modal-title">
            American Express
          </h4>
          <ul>
            <li>
              For queries related to lateral flow tests vouchers please contact&nbsp;
              <a href="mailto:healthylivinghub@aexp.com" className="link">
                healthylivinghub@aexp.com
              </a>
              &nbsp;or call <b>01273 668039 (Opt. 4).</b>
            </li>
            <li>
              Visit the&nbsp;
              <a
                target="_blank"
                className="link"
                href="https://isolate.menlosecurity.com/https://square.americanexpress.com/community/uk/pages/returning-to-office" rel="noreferrer"
              >
                Return to Office Square UK Page
              </a>
              &nbsp;which features additional information and resources about returning to the office.
            </li>
          </ul>
          <h4 className="amex-modal-title">DocHQ</h4>
          <p>
            For queries related to lateral flow tests product delivery/logistic and other please contact&nbsp;
            <a href="mailto:contactus@dochq.co.uk" className="link">
              contactus@dochq.co.uk
            </a>.
          </p>
        </>
      )}
    </div>
  </Modal>
);

export default AmexModal;
