import React from 'react';
import './CenterTwoColumns.scss';

const CenterTwoColumns = ({ hasTitle, title, left, right, hasBottom, bottom }) => (
  <>
    <div className='center-two-columns-container'>
      {hasTitle && typeof title !== 'undefined' && (
        <div className='row'>
          <h3>{title}</h3>
        </div>
      )}
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 border-right'>{left}</div>
        <div className='col-sm-12 col-md-6 col-lg-6'>{right}</div>
      </div>
      {hasBottom && typeof bottom !== 'undefined' && (
        <div className='row'>
          <div className='col-sm-12'>{bottom}</div>
        </div>
      )}
    </div>
  </>
);

export default CenterTwoColumns;
