import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const AvatarImage = ({ imageSrc, size }) => (
  <StaticQuery
    query={graphql`
			{
				background: allImageSharp(filter: { original: { src: { regex: "images/" } } }) {
					edges {
						node {
							fluid(maxWidth: 300) {
								originalName
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`}
    render={data => {
      const myImg = data.background.edges.find(img => {
        return img.node.fluid.originalName === imageSrc;
      });
      return (
        <Img
          fluid={myImg.node.fluid}
          style={{
            borderRadius: '50%',
            width: `${typeof size !== 'undefined' ? size.toString() : '200'}px`,
            height: `${typeof size !== 'undefined' ? size.toString() : '200'}px`,
            margin: 'auto',
          }}
        />
      );
    }}
  ></StaticQuery>
);

export default AvatarImage;

AvatarImage.propTypes = { imageSrc: PropTypes.string.isRequired };
