import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import IFrameWithSide from '../components/IFrameWithSide/IFrameWithSide';
import LinkButton from '../components/LinkButton/LinkButton';
import SEO from '../components/SEO/SEO';
import Benefits from '../components/Benefits/Benefits';

const TravelVaccinePage = () => (
  <>
    <SEO
      title='Travel Vaccines – Travel Health | DocHQ – Your Health | Your Choice | Your Control'
      description='You may need to be vaccinated against some of the serious diseases found in other parts of the world.'
      keywords='DocHQ, Travel, Travel Health, Vaccines, Travel Vaccines, Travel Planning'
    />
    <HeroBanner
      title='Travel Health'
      tagline='We take care of all your travel health needs, offering a complete range of travel vaccinations, antimalarials and expert advice.'
      imageAlt='Happy, health holidaymaker taking a selfie.'
      imageSrc='selfie-photo.png'
    />
    <IFrameWithSide
      iFrameTitle='Find out the recommended travel vaccinations for your trip'
      iFrameSrc='https://ui-travel-vaccine.dochq.co.uk/embed?a_aid=5e3849ac555fe&a_bid=11110007&source-country=GBR'
      sideContent={<SideContent />}
    />
    <Benefits
      title='Available Travel Vaccination'
      subtitle='View further details about the vaccines that we provide.'
      list={[
        'Vaccines protect travellers from serious diseases',
        'Depending on where you travel, you may come into contact with diseases like yellow fever',
        'Some vaccines may be mandatory for you to travel to certain places',
      ]}
      button={<LinkButton linkSrc='/travel-vaccination' text='Travel Vaccinations' color='pink' />}
    />
  </>
);

export default TravelVaccinePage;

const SideContent = () => (
  <>
    <div className='iframe-side-content'>
      <p className='bold' style={{ marginTop: '1rem', }}>Planning to travel outside the UK?</p>
      <p>We’ve made travel health easy, fast and accessible.</p>
      <p>
			You may need to be vaccinated against some of the serious diseases found in other parts of the
			world:
      </p>
      <ul className='shwd-list'>
        <li>Trusted vaccination recommendations</li>
        <li>Choice of clinics near you</li>
        <li>Convenient online booking</li>
      </ul>
      <p>
			Whether you are on business, holiday or planning an excursion, we have your health covered.
      </p>
    </div>
  </>
);
