import React, { useState } from 'react';
import './FAQ.scss';

const FAQ = ({ question, answer, startActive }) => {
  const [active, setActive] =
		typeof startActive !== 'undefined' ? useState(startActive) : useState(false);
  return (
    <>
      <div className='faq-item' aria-label='Frequently asked question'>
        <div className='faq-question-container'>
          <div className='faq-question' aria-label='Question'>
            {question}
          </div>
          <div
            className={`faq-toggler ${active ? 'active' : ''}`}
            onClick={() => setActive(!active)}
          >
            <i className='fa fa-chevron-down'></i>
          </div>
        </div>
        <div
          className={`faq-answer-container ${active ? 'active' : ''}`}
          aria-label='Content toggler'
        >
          <div className='faq-answer' aria-label='Answer'>
            <p>{answer}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
