import React from 'react';
import SEO from '../../components/SEO/SEO';
import AmexItShop from '../../components/Shop/Shops/Amex/AmexItShop';

const AmexItShopPage = () => (
  <>
    <SEO
      title='Amex Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <AmexItShop />
  </>
);

export default AmexItShopPage;
