import React, { useEffect } from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import SEO from '../../../SEO/SEO';
import ProductLink from '../../Products/ProductLink';
import americanExpressBanner from '../../../../assets/images/amex-banner.jpg';
import '../Shop.scss';
import './AmexShop.scss';

const AmexItShop = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.Trustpilot) {
      const trustpilotbox = document.getElementById('trustpilot_box');
      window.Trustpilot.loadFromElement(trustpilotbox);
      const trustpilotblock = document.getElementById('trustpilot_block');
      window.Trustpilot.loadFromElement(trustpilotblock);
    }
  }, []);

  return (
    <div className="shop-page amex-shop">
      <SEO
        title='Covid-19 Products'
        description='Covid-19 Products'
      />
      <div className='banner-container'>
        <img src={americanExpressBanner} className='banner-image' />
      </div>
      <Grid
        container
        className="delivery-container"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <Box p={{ xs: 2, md: 8 }}>
            <p className="amex-text">
							Tutti i colleghi e i contractors di American Express sono fortemente incoraggiati a fare un test
							COVID-19 a casa prima di entrare negli uffici Amex per aiutare a identificare le persone asintomatiche,
							fermare la diffusione del virus e proteggersi a vicenda.<br /><br />
							Amex offre test rapidi gratuiti a tutti i colleghi e contractors in collaborazione con DocHQ.
            </p>
          </Box>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Box p={{ xs: 2, md: 8 }}>
            <p className="amex-text">
							La prima scatola di test può essere ordinata da questo portale utilizzando i voucher forniti.
							Ulteriori scatole di test possono essere ritirate in ufficio.<br /><br />
							Tutti i kit sono approvati dalla FDA e conformi alle linee guida del governo italiano.
							È facile da usare con risultati garantiti in 20 minuti.
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        className="products-container"
      >
        <Grid item xs={12} md={4} py={1} style={{padding: '2px 2px'}}>
          <ProductLink
            title="Ordina i tuoi test rapidi gratuiti"
            body="Ordina oggi utilizzando i tuoi codici voucher in pochi passaggi. I kit verranno consegnati direttamente a casa tua."
            buttons={[
              {
                buttonColor: 'amex-blue',
                buttonText: 'Ordina Ora',
                linkSrc: '/checkout?source=amex-it',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{padding: '2px 2px'}}>
          <ProductLink
            title="Come fare il tuo test"
            body="Leggi la guida per comprendere i passaggi ed evitare risultati non validi."
            buttons={[
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'Scarica',
                linkSrc: '/partners/amex-uk/how-to-take-a-test?language=it',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{padding: '2px 2px'}}>
          <ProductLink
            title="Guarda il video"
            body="Guarda il video per comprendere i passaggi ed evitare risultati non validi."
            buttons={[
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'Guarda',
                linkSrc: '/partners/amex-uk/how-to-take-a-test-video?language=it',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <div id="trustpilot_block" className="trustpilot-widget trustpilot-box" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="60a81450941eea000194948f" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
            <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AmexItShop;
