import React from 'react';
import { get } from 'lodash';
import { Divider, IconButton } from '@material-ui/core';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import LinkButton from '../LinkButton/LinkButton';
import PRODUCTS from '../../helpers/products';
import './Shops/Shop.scss';

const INCREASE_DECREASE_RESTRICTED = [
  PRODUCTS.covidTestCertificate,
  PRODUCTS.antigenConsultation,
];

const Basket = ({
  open,
  setBasket,
  basket,
  handleClose,
  removeItem,
  params = {},
  awc = null,
  discountCode = '',
  source,
  portalUrl = '',
  discount = 0,
  isUefa = false,
}) => {
  const isPortalLink = params['customer_discount'] === 'DOCHQAPP30';
  const basketButtonsBlock = (
    <>
      <div className="row space-between">
        <Button
          text="Back to products"
          color="pink-outlined action-btn"
          onClick={handleClose}
        />
        {portalUrl && !isPortalLink && (
          <LinkButton
            text="Login"
            color="green-outlined action-btn"
            linkSrc={`${portalUrl}/login`}
          />
        )}
        {((!portalUrl && !isPortalLink) || (portalUrl && isPortalLink)) && (
          <LinkButton
            text="Go to checkout"
            color="pink action-btn"
            linkSrc={`/checkout?${basket.map(({ id, numberOfKits, delivery }) => `id${id}delivery${delivery === 'royal' ? 2 : delivery ? 1 : 0 }=${numberOfKits}`).join('&')}&source=${source}${awc ? `&awc=${awc}` : ''}${discountCode ? `&discount_code=${discountCode}` : ''}`}
          />
        )}
      </div>
      {portalUrl && !isPortalLink && (
        <div className="row center">
          <LinkButton
            text="Go to checkout"
            color="flat action-btn"
            linkSrc={`/checkout?${basket.map(({ id, numberOfKits, delivery }) => `id${id}delivery${delivery === 'royal' ? 2 : delivery ? 1 : 0 }=${numberOfKits}`).join('&')}&source=${source}${awc ? `&awc=${awc}` : ''}${discountCode ? `&discount_code=${discountCode}` : ''}`}
          />
        </div>
      )}
    </>
  );

  return(
    <Modal
      isVisible={open}
      title={<span className="modal-title no-margin">Basket</span>}
      onClose={handleClose}
    >
      <div className="modal-box">
        <div className="kits-container">
          <div className="kits-box">
            {basket.map(({ sku, numberOfKits, price, title, id, delivery, requiresReturn }) => (
              <div key={sku}>
                <div className="row space-between align-start">
                  <div>
                    <p className="basket-item-title">
                      <b>Product: </b>{title}
                    </p>
                    <div className="row align-center">
                      <p className="basket-item-title no-margin">
                        <b>Quantity:</b>
                      </p>
                      <div className="row align-center" style={{ paddingLeft: 10 }}>
                        {INCREASE_DECREASE_RESTRICTED.includes(sku) ? (
                          <h4 className="no-margin">
                            {numberOfKits}
                          </h4>
                        ) : (
                          <>
                            <Button
                              text={<i className="fa fa-minus" aria-hidden="true"></i>}
                              color='green add-btn'
                              onClick={() => {
                                const newBasket = [...basket];
                                const item = newBasket.find(({ id: tmpId }) => tmpId === id);
                                if (item.numberOfKits === 1) {
                                  removeItem([id]);
                                } else {
                                  const pricePer1 = item.price / item.numberOfKits;
                                  item.numberOfKits -= 1;
                                  item.price = item.numberOfKits * pricePer1;
                                  setBasket(newBasket);
                                }
                              }}
                            />
                            <h4 style={{ margin: '0 10px' }}>
                              {numberOfKits}
                            </h4>
                            <Button
                              text={<i className="fa fa-plus" aria-hidden="true"></i>}
                              color='green add-btn'
                              onClick={() => {
                                if (isUefa) return;
                                const newBasket = [...basket];
                                const item = newBasket.find(({ id: tmpId }) => tmpId === id);
                                const pricePer1 = item.price / item.numberOfKits;
                                if (item.numberOfKits < 15) {
                                  item.numberOfKits += 1;
                                  item.price = pricePer1 * item.numberOfKits;
                                  setBasket(newBasket);
                                }
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <p className="basket-item-title">
                      <b>Price:</b> £{price.toFixed(2)}
                    </p>
                    {!!delivery && (
                      <p className="basket-item-title">
                        <b>Return:</b> {delivery === 'royal' ? `£${(numberOfKits * 10 * requiresReturn * (1 - (discount / 100))).toFixed(2)}` : <span className="green-text">Included</span>}
                      </p>
                    )}
                  </div>
                  <IconButton onClick={() => {
                    if (INCREASE_DECREASE_RESTRICTED.includes(sku)) {
                      removeItem([get(basket.find(({ sku: itemSku }) => itemSku === INCREASE_DECREASE_RESTRICTED[0]), 'id', 0), get(basket.find(({ sku: itemSku }) => itemSku === INCREASE_DECREASE_RESTRICTED[1]), 'id', 0)]);
                    } else removeItem([id]);
                  }}>
                    <i className="fas fa-trash-alt delete-icon"/>
                  </IconButton>
                </div>
                {numberOfKits >= 15 && (
                  <div className='row center bulk-notification'>
                    If you are a business and you want to make a bulk order, please contact us at: <strong className="red-text">orders@dochq.co.uk</strong>
                  </div>
                )}
                <Divider className="items-divider" />
              </div>
            ))}
          </div>
          <div className="price-box">
            <h4 className="total-price">
	  					Total price: £{basket.reduce((sum, { price, numberOfKits, delivery, requiresReturn }) => sum + price + (delivery === 'royal' ? (numberOfKits * 10 * requiresReturn * (1 - (discount / 100))) : 0 ), 0).toFixed(2)}
            </h4>
            {basketButtonsBlock}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Basket;
