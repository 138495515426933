import React, { useState } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import './IFrameWithSide.scss';

const IFrameWithSide = ({ iFrameTitle, iFrameSrc, iFrameProps, sideContent, side, hasLoading }) => {
  const [isLoading, setIsLoading] = useState(true);
  return typeof hasLoading === 'undefined' || hasLoading === false ? (
    <>
      <div className='iframe-with-side-container'>
        <div className='width-container'>
          <div className='row'>
            {(side === 'left' || typeof side === 'undefined') && (
              <div className='col-sm-12 col-md-4 right-border'>{sideContent}</div>
            )}
            {iFrameSrc && iFrameProps && (
              <div className='iframe-container col-sm-12 col-md-8'>
                {typeof iFrameTitle !== 'undefined' && (
                  <div className='row'>
                    <h4>{iFrameTitle}</h4>
                  </div>
                )}
                <div className='row iframe-row'>
                  <iframe className='iframe' src={iFrameSrc} {...iFrameProps} />
                </div>
              </div>
            )}
            {iFrameSrc && typeof iFrameProps === 'undefined' && (
              <div className='iframe-container col-sm-12 col-md-8'>
                {typeof iFrameTitle !== 'undefined' && (
                  <div className='row'>
                    <h4>{iFrameTitle}</h4>
                  </div>
                )}
                <div className='row iframe-row'>
                  <iframe className='iframe' src={iFrameSrc} />
                </div>
              </div>
            )}
            {side === 'right' && (
              <div className='col-sm-12 col-md-4 left-border'>{sideContent}</div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className='iframe-with-side-container'>
        <div className='width-container'>
          <div className='row'>
            {(side === 'left' || typeof side === 'undefined') && (
              <div className='col-sm-12 col-md-4 right-border'>{sideContent}</div>
            )}
            {iFrameSrc && iFrameProps && (
              <div className='iframe-container col-sm-12 col-md-8'>
                <div className='row'>
                  <h4>{iFrameTitle}</h4>
                </div>
                <div className='row iframe-row'>
                  <iframe src={iFrameSrc} {...iFrameProps} onLoad={() => setIsLoading(false)} />
                  {isLoading && (
                    <div className='loading'>
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              </div>
            )}
            {iFrameSrc && typeof iFrameProps === 'undefined' && (
              <div className='iframe-container col-sm-12 col-md-8'>
                <div className='row'>
                  <h4>{iFrameTitle}</h4>
                </div>
                <div className='row iframe-row'>
                  <iframe
                    className={`iframe ${isLoading ? 'iframe-loading' : ''}`}
                    src={iFrameSrc}
                    onLoad={() => setIsLoading(false)}
                  />
                  {isLoading && (
                    <div className='loading'>
                      <LoadingSpinner />
                    </div>
                  )}
                </div>
              </div>
            )}
            {side === 'right' && (
              <div className='col-sm-12 col-md-4 left-border'>{sideContent}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IFrameWithSide;
