import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import WhatWeDo from '../components/WhatWeDo/WhatWeDo';
import PartnersLogoCollection from '../components/PartnersLogoCollection/PartnersLogoCollection';
import HowTestWorks from '../components/HowTestWorks/HowTestWorks';
import OurNumbers from '../components/OurNumbers/OurNumbers';
import ImageBanner from '../components/ImageBanner/ImageBanner';
import antigenTesting from '../assets/icons/antigen-testing.png';
import guidedTesting from '../assets/icons/guided-testing.png';
import pcrTesting from '../assets/icons/pcr-testing.png';
import weCare from '../assets/images/solution-of-a-self-testing.jpg';
import kitsBulk from '../assets/icons/kits-bulk.png';
import kitsDistribute from '../assets/icons/kits-distribute.png';
import kitsResults from '../assets/icons/kits-results.png';
import aon from '../assets/images/partners-logo/aon.png';
import bristolAirport from '../assets/images/partners-logo/bristol-airport.png';
import gtcGroup from '../assets/images/partners-logo/gtc-group.png';
import insurefor from '../assets/images/partners-logo/insurefor.png';
import oceanFlorida from '../assets/images/partners-logo/ocean-florida.png';
import oncologica from '../assets/images/partners-logo/oncologica.jpg';
import synlabUk from '../assets/images/partners-logo/synlab.png';
import tuiGroup from '../assets/images/partners-logo/tui-de.svg';
import vistaHealth from '../assets/images/partners-logo/vista-health.png';
import attractionWorld from '../assets/images/partners-logo/attraction-world.jpg';

const BusinessCovidTesting = () => {

  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.Trustpilot) {
      const trustpilotbox = document.getElementById('trustpilot_box');
      window.Trustpilot.loadFromElement(trustpilotbox);
    }
  }, []);

  return (
    <>
      <SEO
        title='Home'
        description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
      />
      <HeroBanner
        button
        linkSrc="#whatWeDo"
        buttonColor="green"
        buttonText="What We Do"
        imageAlt='Lady on a flight'
        imageSrc='antigen-virus-self-test.jpg'
        title={
          <>
						COVID-19 bulk testing for your organisation
          </>
        }
        backgroundStyle={{ backgroundPosition: 'top' }}
        tagline='Ensure that your employees stay safe with regular testing and the convenience of self or guided testing from home.'
      />
      <WhatWeDo
        title='What We Do'
        anchorId="whatWeDo"
        subtitle='We provide a range of tools and services to manage your health when you are travelling or at home.'
        hideBrochure
        singlePage
        contentItems={[
          {
            imageSrc: antigenTesting,
            linkSrc: '/',
            title: 'Antigen Testing',
            text: 'Lateral flow or Antigen kits are a fast way to check if your employee is positive at a low cost',
          },
          {
            imageSrc: guidedTesting,
            linkSrc: '/',
            title: 'Guided Testing',
            text: 'Before employees return to work after Covid-19 sickness, join an online guided appointment to register result',
          },
          {
            imageSrc: pcrTesting,
            title: 'PCR Testing',
            linkSrc: '/',
            text: 'PCR testing with Lab analysis and result certification',
          },
        ]}
      />
      <Box>
        <ImageBanner
          contentTitle='Benefits'
          contentSubTitle='All tests and processes are reviewed by a medical team, we provide trusted, CE and UKAS approved tests for our customers across Europe'
          contentBody={[
            [
              'An easy-to-use and comfortable collection kit',
              'Bespoke solution build for delivery to office or direct to employee house',
              'Branded shop to enable employees to order online with vouchers',
              'Optional nurse guided testing of employees for return to work post sickness',
              '90 days payment term for business using American Express BIP',
            ],
            [
              'Allow your employees to test for COVID-19 at home before coming into work with our bulk testing solution',
              'Reduce the spread of Covid-19 in the workplace',
              'Offer employees peace of mind',
              'Use nurse guided testing before employees return to work post covid infection',
              'Access to a registered doctor who can advice employees if they test positive',
            ],
          ]}
          wideImg
          imageAlt='Target'
          imageSrc={weCare}
          greyBackground
        />
      </Box>
      <HowTestWorks
        header='How our testing service works?'
        icons={[
          kitsBulk,
          kitsDistribute,
          kitsResults
        ]}
        steps={[
          'Order kits in bulk or provide vouchers for employees',
          'Distribute test kits to your employees',
          'Get test results and support for Covid-19 positive employees',
        ]}
      />
      <OurNumbers />
      <div id="trustpilot_box" className="trustpilot-widget trustpilot-box" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="60a81450941eea000194948f" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
        <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noreferrer"></a>
      </div>
      <PartnersLogoCollection
        title='Companies we work with:'
        greyBackground
        icons={[
          bristolAirport,
          gtcGroup,
          oceanFlorida,
          synlabUk,
          oncologica,
          aon,
          insurefor,
          vistaHealth,
          tuiGroup,
          attractionWorld,
        ]}
      />
    </>
  );
};

export default BusinessCovidTesting;
