import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Badge from '@material-ui/core/Badge';
import Button from '../../../Button/Button';
import {
  IconButton,
  Grid,
  Box,
} from '@material-ui/core';
import PartnersProduct from '../../Products/PartnersProduct';
import Basket from '../../Basket';
import SEO from '../../../SEO/SEO';
import ShopBanner from '../../../ShopBanner/ShopBanner';
import dropboxSvc from '../../../../services/dropbox.service';
import productSvc from '../../../../services/product.service';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import getURLParams from '../../../../helpers/getURLParams';
import PRODUCTS from '../../../../helpers/products';
import discountSvc from '../../../../services/discounts.service';
import Banner from '../../Banner';
import CertificateProduct from '../../Products/CertificateProduct';
import useConfig from '../../../../helpers/useConfig';
import '../Shop.scss';
import './InsureforShop.scss';

const InsureforShop = ({ location }) => {
  const outboundTestsRef = useRef(null);
  const inboundTestsRef = useRef(null);
  const otherTestRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState();
  const [dropboxes, setDropboxes] = useState([]);
  const [basket, setBasket] = useState([]);
  const [products, setProducts] = useState([]);
  const locationArray = location ? location.split('/') : [''];
  const params = getURLParams(locationArray[locationArray.length - 1]);
  const discountCode = params['customer_discount'];
  const policyId = params['policy_id'];
  let policyCode = params['policy_code'];
  policyCode = policyCode ? moment.utc(moment.unix(policyCode.slice(3, -2))).startOf('day').format() : '';
  const inboundProducts = [...products].filter((product) => {
    const tags = get(product, 'tags', []);
    return tags.includes('inbound');
  });
  const outboundProducts = [...products].filter((product) => {
    const tags = get(product, 'tags', []);
    return tags.includes('outbound');
  });
  const isAnyActiveDropbox = !!(dropboxes.filter(({ active }) => !!active).length);
  const totalAmount = basket.reduce((sum, { numberOfKits }) => sum + numberOfKits, 0);
  const antigenConsultation = products.find(({sku}) => PRODUCTS.antigenConsultation == sku);
  const covidTestCertificate = products.find(({sku}) => PRODUCTS.covidTestCertificate == sku);
  const isCertificateProductsActive = !!antigenConsultation && !!antigenConsultation.active && !!covidTestCertificate && !!covidTestCertificate.active;
  const openBasket = () => setOpen(true);
  const closeBasket = () => setOpen(false);
  const addToBasket = (item) => {
    const newItems = [...basket];
    const isItemAlreadyAdded = newItems.find(({ id }) => id === item.id);
    if (isItemAlreadyAdded) {
      isItemAlreadyAdded.price += item.price;
      isItemAlreadyAdded.numberOfKits += item.numberOfKits;
      isItemAlreadyAdded.delivery = item.delivery;
    } else {
      newItems.push(item);
    }
    setBasket(newItems);
    openBasket();
  };
  const addDoubleProductToBasket = (items) => {
    const newItems = [...basket];
    items.map((item) => {
      const isItemAlreadyAdded = newItems.find(({ id }) => (id === item.id));
      if (isItemAlreadyAdded) {
        isItemAlreadyAdded.price += item.price;
        isItemAlreadyAdded.numberOfKits += item.numberOfKits;
        isItemAlreadyAdded.delivery = item.delivery;
      } else {
        newItems.push(item);
      }
      return item;
    });
    setBasket(newItems);
    openBasket();
  };
  const removeItem = (id) => {
    const newItems = [...basket].filter((item) => !id.includes(item.id));
    setBasket(newItems);
    if (!newItems.length) {
      closeBasket();
    }
  };
  const { config, env } = useConfig();
  const isStaging = env === 'staging';
  const jsonAPI =	config.jsonAPI;
  const myhealthUrl = config.myhealthUrl;
  const portalUrl = config.portalUrl;

  const getData = async () => {
    setLoading(true);
    await dropboxSvc.getDropboxes(jsonAPI)
      .then(result => {
        if (result.success && result.dropboxes) {
          setDropboxes(result.dropboxes.filter(({ active }) => !!active));
        } else {
		  		setDropboxes([]);
        }
      })
      .catch(() => {
        setDropboxes([]);
        console.log('error');
      });
    await productSvc.getProducts(jsonAPI, policyCode && policyId ? `policy_date=${policyCode}&policy_id=${policyId}` : 'tags=dochq')
      .then(result => {
        if (result.success && result.products) {
          setProducts(result.products);
        } else {
		  	setProducts([]);
        }
      })
      .catch(() => {
        setProducts([]);
        console.log('error');
	  });
    if (discountCode) {
      await discountSvc.getDiscountDetails(jsonAPI, discountCode)
        .then(result => {
          if (result.success && result.data && result.data.value && !!result.data.uses) {
            setDiscount(result.data);
          } else {
            console.log('Invalid discount code');
          }
        })
        .catch(() => {
          console.log('Invalid discount code');
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (loading || !products.length) ? (
    <div className='row' style={{ justifyContent: 'center' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <div className="shop-page insurefor-shop">
      <SEO
        title='Covid-19 Products'
        description='Covid-19 Products'
      />
      {(!!basket.length && !open) && (
        <div className="shopping-cart-box">
          <IconButton aria-label="cart" onClick={openBasket}>
            <Badge badgeContent={totalAmount} color="secondary">
              <i className="fas fa-shopping-cart" />
            </Badge>
          </IconButton>
        </div>
      )}
      <Box pr={1}>
        <ShopBanner
          imageAlt='People with mask'
          imageSrc='family-in-masks.png'
          title={<span>All DocHQ Covid-19 tests for <a href="insurefor.com" className="pink-text">Insurefor.com</a> customers include</span>}
          list={[
            'Convenient live online consultation with a trained healthcare professional​',
            'All test results are certified for travel by a GMC registered doctor',
            'PCR test analysis is conducted by ISO 15189 and UKAS accredited laboratory',
            'Antigen test kits are CE marked, FDA approved, and adhere to UK Government specificity and sensitivity requirements​',
          ]}
          backgroundStyle={{ backgroundPosition: 'top' }}
          footer={
            <div className="row space-evenly mob-nav-col banner-footer">
              <Button
                text='UK Outbound Tests'
                color='pink-outlined action-btn mob-btn-margin'
                onClick={() => outboundTestsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              />
              <Button
                text='UK Inbound Tests'
                color='pink-outlined action-btn mob-btn-margin'
                onClick={() => inboundTestsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
              />
              {/* <Button
								text='Other testing choices'
								color='pink-outlined action-btn mob-btn-margin'
								onClick={() => otherTestRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
							/> */}
            </div>
          }
        />
      </Box>
      <div className="gov-link-box">
        <p><b>
					Please check <a target='_blank' rel='noopener noreferrer' href="https://www.gov.uk/foreign-travel-advice">https://www.gov.uk/foreign-travel-advice</a>&nbsp;to confirm travel advice and test requirements as these may change.
        </b></p>
      </div>
      <Banner color="green">
        <h5  style={{ textAlign: 'center' }}>
          Please <a href={`${portalUrl}/login`} className="link underline">Sign In </a>to see your results online and avoid the worry of losing them to the spam folder.
        </h5>
      </Banner>
      <div className="products-padding-box">
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className="products-container"
        >
          {!!outboundProducts.length && (
            <>
              <Grid item xs={12} ref={outboundTestsRef}>
                <h3 className="block-title no-margin">
									UK Outbound Tests
                </h3>
                <h4 className="second-block-title no-margin">
									Please add your selection to the basket
                </h4>
              </Grid>
              {outboundProducts.map((product) => {
                const sku = get(product, 'sku', '');

                return (
                  <Grid key={sku} item xs={12} md={6}>
                    {sku === PRODUCTS.antigenConsultation && isCertificateProductsActive ? (
                      <CertificateProduct
                        jsonAPI={jsonAPI}
                        basket={basket}
                        product1={product}
                        discount={discount}
                        isStaging={isStaging}
                        myhealthUrl={myhealthUrl}
                        product2={covidTestCertificate}
                        addToBasket={addDoubleProductToBasket}
                      />
                    ) : (
                      <PartnersProduct
                        product={product}
                        discount={discount}
                        isStaging={isStaging}
                        addToBasket={addToBasket}
                        myhealthUrl={myhealthUrl}
                        isAnyActiveDropbox={isAnyActiveDropbox}
                      />
                    )}
                  </Grid>
                );
              })}
            </>
          )}
          {!!inboundProducts.length && (
            <>
              <Grid item xs={12} ref={inboundTestsRef}>
                <h3 className="block-title no-margin">
									UK Inbound Tests
                </h3>
                <h4 className="second-block-title no-margin">
									Please add your selection to the basket
                </h4>
              </Grid>
              {inboundProducts.map((product) => {
                const sku = get(product, 'sku', '');

                return (
                  <Grid key={sku} item xs={12} md={4}>
                    {sku === PRODUCTS.antigenConsultation && isCertificateProductsActive ? (
                      <CertificateProduct
                        jsonAPI={jsonAPI}
                        basket={basket}
                        product1={product}
                        discount={discount}
                        isStaging={isStaging}
                        myhealthUrl={myhealthUrl}
                        product2={covidTestCertificate}
                        addToBasket={addDoubleProductToBasket}
                      />
                    ) : (
                      <PartnersProduct
                        smallSize
                        product={product}
                        discount={discount}
                        isStaging={isStaging}
                        myhealthUrl={myhealthUrl}
                        addToBasket={addToBasket}
                      />
                    )}
                  </Grid>
                );
              })}
            </>
          )}
          <Basket
            open={open}
            source="ROCS"
            setBasket={setBasket}
            handleClose={closeBasket}
            basket={basket}
            removeItem={removeItem}
            discount={discount ? discount.value : 0}
            discountCode={discount ? discountCode : ''}
          />
        </Grid>
      </div>
    </div>
  );
};

export default InsureforShop;
