import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../Button/Button';
import CTN_DISCOUNT_CODES from '../../../helpers/ctnDiscountCodes';
import '../Shops/Shop.scss';

const CtnProduct = ({
  product,
  addToBasket,
  myhealthUrl,
  maxQuantity,
  discount: outDiscount = null,
  smallSize = false,
  isStaging = false,
  getDiscountDetails,
  isAnyActiveDropbox = false,
}) => {
  const [numberOfKits, setNumberOfKits] = useState(1);
  const [insideDiscount, setInsideDiscount] = useState();
  const price = product.price;
  const id = product.id;
  const discount = maxQuantity >= numberOfKits ? outDiscount : insideDiscount;
  const title = get(product, 'title', '');
  const subTitle = get(product, 'sub_title', '');
  const service = get(product, 'service', '');
  const description = get(product, 'description', '');
  const points = get(product, 'points', []);
  const footer = get(product, 'footer', '');
  const cardColour = get(product, 'card_colour', 'doc-green');
  const outOfStock = get(product, 'out_of_stock', false);
  const isDiscountAvailable = !!discount && !get(product, 'discount_exempt', false);
  const requiresReturn = get(product, 'tags', []).find((tagName) => tagName === 'postal_return') ? 1 : 0;
  const quantityRestriction =  get(product, 'quantityRestriction', false);
  const [delivery, setDelivery] = useState(requiresReturn === 0 ? '' : isAnyActiveDropbox ? 'drop' : 'royal');
  const returnPrice = (delivery === 'royal' ? (10 * numberOfKits * requiresReturn) : 0);
  const returnPriceWithDiscount = returnPrice * (isDiscountAvailable ? 1 - (discount.value / 100) : 1);
  const priceWithoutDiscount = (price * numberOfKits) + (isDiscountAvailable ? returnPriceWithDiscount : returnPrice);
  const priceWithDiscount = isDiscountAvailable ? priceWithoutDiscount - (priceWithoutDiscount * (discount.value / 100)) : priceWithoutDiscount;

  const checkDiscount = async () => {
    if (numberOfKits > maxQuantity) {
      const discountResult = await getDiscountDetails(numberOfKits >= 4 ? CTN_DISCOUNT_CODES[4] : CTN_DISCOUNT_CODES[numberOfKits]);
      if (discountResult && discountResult.value && discountResult.code) {
        setInsideDiscount(discountResult);
      } else setInsideDiscount();
    } else setInsideDiscount();
  };

  useEffect(() => {
    checkDiscount();
  }, [numberOfKits]);

  return (
    <div className="tile">
      <div className={`${smallSize ? 'title-box title-box-small' : 'title-box'} background--${cardColour}`}>
        <h5 className="product-title">
          {service} {isStaging && (
            <a href={`${myhealthUrl}/super_admin/product/${id}`} target="_blank" rel="noopener noreferrer">id:{id}</a>
          )}
        </h5>
        <Divider className="title-divider"/>
        <h4 className="product-sub-title">
          {title}
        </h4>
        {!!subTitle && (
          <p className="additional-title-text">
            {subTitle}
          </p>
        )}
      </div>
      <div className="tile-content">
        <div>
          <p className={`${smallSize ? 'description-small' : 'description'}`}>
            {description}
          </p>
        </div>
        {!!points.length && (
          <ul className={`${smallSize ? 'shwd-list shwd-list-small' : 'shwd-list'}`}>
            {points.map(({ text, colour }, indx) => (
              <li key={indx} className={colour}>
                {text}
              </li>
            ))}
            {numberOfKits > 1 && (
              <li className="doc-pink">
								Price per unit £{(price * (isDiscountAvailable ? 1 - (discount.value / 100) : 1)).toFixed(2)}
              </li>
            )}
          </ul>
        )}
        <div className={`${smallSize ? 'buy-action-box buy-action-box-small' : 'buy-action-box'}`}>
          <div>
            <h3 style={{ margin: '0px 0', textAlign: 'center' }}>
              {isDiscountAvailable ? (
                <>
                  <span className="line-thought">
										£{priceWithoutDiscount.toFixed(2)}
                  </span>
                  <span>
										&nbsp;&nbsp;£{priceWithDiscount.toFixed(2)}
                  </span>
                </>
              ) : (
                <>
									£{priceWithoutDiscount.toFixed(2)}
                </>
              )}
            </h3>
          </div>
          <div className={`row ${requiresReturn ? 'space-between' : 'center'} mob-col ${smallSize ? 'action-small-row center' : 'action-row'}`}>
            {isAnyActiveDropbox && (
              <FormControl component="fieldset">
                <RadioGroup name="delivery" value={delivery} onChange={(event) => setDelivery(event.target.value)}>
                  <FormControlLabel
                    value="drop"
                    control={<Radio />}
                    label={<p className="no-margin"><b>Drop Off return - <span className="green-text">Included</span></b></p>}
                  />
                  <FormControlLabel
                    value="royal"
                    control={<Radio />}
                    label={<p className="no-margin"><b>Royal Mail return (+ £10.00 per kit)</b></p>}
                  />
                </RadioGroup>
              </FormControl>
            )}
            <div className="row space-between align-center">
              {!quantityRestriction && (
                <div className="row align-center" style={{ paddingRight: 30 }}>
                  <Button
                    text={<i className="fa fa-minus" aria-hidden="true"></i>}
                    color='green add-btn'
                    onClick={() => numberOfKits > 1 ? setNumberOfKits(numberOfKits - 1) : null}
                  />
                  <h4 style={{ margin: '0 10px' }}>
                    {numberOfKits}
                  </h4>
                  <Button
                    text={<i className="fa fa-plus" aria-hidden="true"></i>}
                    color='green add-btn'
                    onClick={() => {
                      if (numberOfKits < 15) {
                        setNumberOfKits(numberOfKits + 1);
                      }
                    }}
                  />
                </div>
              )}
              {outOfStock ? (
                <p className="star-text">Out of Stock</p>
              ) : (
                <Button
                  text='Add to basket'
                  color='pink action-btn add-to-basket'
                  disabled={requiresReturn ? !delivery : false}
                  onClick={() => {
                    addToBasket({ price: price * numberOfKits, numberOfKits, title, id, delivery, requiresReturn });
                    setNumberOfKits(1);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {numberOfKits >= 15 && (
          <div className='row center bulk-notification' style={{margin: '10px 0px'}}>
            If you are a business and you want to make a bulk order, please contact us at: <strong className="red-text">orders@dochq.co.uk</strong>
          </div>
        )}
        {footer && (
          <div className={`${smallSize ? 'text-after-price-box text-after-price-box-small' : 'text-after-price-box'}`}>
            <p className="star-text">
              {footer}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CtnProduct;
