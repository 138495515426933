import React from 'react';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
const styles = {
  coverPage: {
    minHeight: '500px',
    backgroundColor: 'var(--doc-light-grey)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
};

const Page404 = () => (
  <>
    <SEO title='Page Not Found' />
    <div style={styles.coverPage}>
      <HeroBanner
        title='404'
        imageAlt='Man hiking in the wilderness'
        imageSrc='404.png'
        tagline="It seems you've lost your way, here's a helping hand to get back on track."
        backgroundStyle={{ backgroundPosition: 'center -50px' }}
      />
    </div>
  </>
);

export default Page404;
