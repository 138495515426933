import React from 'react';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import BackgroundWCenterContent from '../components/BackgroundWCenterContent/BackgroundWCenterContent';
import DrugSchedules from '../components/DrugSchedules/DrugSchedules';
import DataSources from '../components/Benefits/DataSources';
import Banner from '../components/Shop/Banner';
import '../components/Shop/Shops/Shop.scss';

const TravelMedicineRestrictionsPage = () => (
  <>
    <SEO
      title='Travel Medicine Restrictions – Travel Health | DocHQ – Your Health | Your Choice | Your Control'
      description='An ideal tool to use before your trip, to help you identify if you are carrying your medicine legally.'
      keywords='DocHQ, Travel Medicine, Medicine Restrictions, Travel Health, Travel Planning'
    />
    <HeroBanner
      title='Travel Medicine Restrictions'
      tagline='An ideal tool to use before your trip, to help you identify if you are carrying your medicine legally.'
      imageAlt='Happy, health holidaymaker taking a selfie.'
      imageSrc='airport.jpeg'
      backgroundStyle={{ transform: 'scaleX(-1)' }}
    />
    <div className='medicine-restriction-iframe-container'>
      <iframe className='mr-iframe' src='https://ui-drug-restrictions.dochq.co.uk?company=dochq'></iframe>
    </div>
    <DataSources
      title='Medicine Restriction Data Sources'
      content={<DataContent />}
    />
    <DrugSchedules />
  </>
);

export default TravelMedicineRestrictionsPage;

const DataContent = () => (
  <>
    <p>This is a world-first service that combines data available from different sources such as:</p>
    <div className='data-content-container'>
      <h4 className='data-content-item-first'>INCB	</h4>
      <h4 className='data-content-item'>Fit to Travel NHS</h4>
      <h4 className='data-content-item'>Travax	</h4>
      <h4 className='data-content-item'>NHS digital	</h4>
      <h4 className='data-content-item'>NICE BNF	</h4>
      <h4 className='data-content-item'>WHO	</h4>
      <h4 className='data-content-item'>ISTM	</h4>
      <h4 className='data-content-item'>undocs.org	</h4>
      <h4 className='data-content-item'>CDC	</h4>
    </div>
  </>
);


const SideContent = () => (
  <>
    <h4>Before travelling check your medication.</h4>
    <h3>
			You could get a fine or go to prison if you travel with medicine that's illegal in another
			country, so check what rules apply to your medicine.
    </h3>
    <ul className='shwd-list'>
      <li>Trusted source to help you stay legal</li>
      <li>Choose any destination</li>
      <li>Quick search of medicine</li>
      <li>Fast advice identifying additional requirements</li>
    </ul>
    <p>To avoid starting your travel on the wrong foot, we have your travel medication covered.</p>
  </>
);

const Content = () => (
  <>
    <h3>Do you need any travel vaccinations before your trip?</h3>
    <p>
			If you are planning a trip abroad, you may need to be vaccinated against some of the serious
			diseases found in other parts of the world.
    </p>
    <h4>Find out what vaccinations are required for your trip.</h4>
  </>
);
