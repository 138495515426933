import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CountrySearch = ({ label, updateLocation, countries, disabled, defaultLocationId }) => (
  <Autocomplete
    freeSolo
    autoHighlight
    disableClearable
    options={countries}
    disabled={disabled}
    value={defaultLocationId}
    renderOption={option => option.name}
    onChange={(event, newValue) => {
      updateLocation(newValue);
    }}
    onInputChange={(event, newValue) => {
      if (newValue === '') {
        updateLocation('');
      }
    }}
    getOptionLabel={(option) => option.name}
    filterOptions={(options, params) => {
      let filtered = [];
      if (params.inputValue.length >= 2) {
        filtered = options.filter(option => {
          const countryName = option.name
            .toLowerCase()
            .startsWith(params.inputValue.toLowerCase());
          return countryName;
        });
      } else {
        filtered = countries;
      }
      return filtered;
    }}
    renderInput={params => (
      <TextField
        {...params}
        label={label}
        variant='filled'
        InputProps={{ ...params.InputProps, type: 'search' }}
      />
    )}
    noOptionsText='Cannot find country'
    variant='filled'
  />
);

export default CountrySearch;
