import React, { useEffect } from 'react';
import { useCookie } from 'react-use';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Footer from '../../components/Footer/Footer';
import NavBar from '../../components/NavBar/NavBar';
import getURLParams from '../../helpers/getURLParams';
import ENV_VARS from '../../config';
import './MainLayout.scss';

export let DeploymentType;
const MainLayout = ({ children }) => {
  const params = getURLParams(children.props.location.href);
  const awc = params['awc'];
  const [value, updateCookie, deleteCookie] = useCookie('awc');
  const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					siteUrl
					deploymentType
				}
			}
		}
	`);
  DeploymentType = data.site.siteMetadata.deploymentType;
  if (typeof window !== 'undefined') {
    window.dochq = data.site;
    data.site.siteMetadata.deploymentType === 'staging'
      ? ENV_VARS.staging
      : ENV_VARS.production;
    // if (typeof window._iub === 'undefined') {
    // 	var _iub = _iub || [];

    // 	_iub.csConfiguration = {
    // 		lang: 'en',
    // 		siteId: 1807825,
    // 		gdprAppliesGlobally: false,
    // 		consentOnScroll: false,
    // 		whitelabel: false,
    // 		cookiePolicyId: 47439358,
    // 		banner: {
    // 			acceptButtonDisplay: true,
    // 			customizeButtonDisplay: true,
    // 			position: 'float-top-center',
    // 			textColor: 'white',
    // 			backgroundColor: '#333333',
    // 			acceptButtonColor: '#e5014d',
    // 			acceptButtonCaptionColor: 'white',
    // 			customizeButtonColor: '#212121',
    // 			customizeButtonCaptionColor: 'white',
    // 		},
    // 	};
    // 	typeof window !== 'undefined' ? (window._iub = _iub) : null;
    // 	console.log('iub config layout');
    // }
  }

  useEffect(() => {
    if (awc) {
      updateCookie(awc);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
        <meta name='robots' content='none' />
        <link rel='canonical' href={data.site.siteMetadata.siteUrl} />
      </Helmet>
      <NavBar
        path={children.props.location.pathname}
        params={params}
      />
      <div>
        <main>{children}</main>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
