function calculatePriceWithDiscount(quantity, price, discount) {
  if (typeof price === 'string') {
    price = parseInt(price, 10);
  }
  if (discount && discount.valid && discount.type && discount.value) {
    if (
      discount.type === 'percentage' &&
			typeof discount.value === 'number' &&
			discount.value > 0
    ) {
      return (price * quantity * ((100 - discount.value) / 100)).toFixed(2);
    } else {
      return (price * quantity - discount.value).toFixed(2);
    }
  } else {
    return quantity * price;
  }
}

module.exports = calculatePriceWithDiscount;
