import React, { useState, useEffect } from 'react';
import EmailInputElement from '../EmailInputElement';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import DateOfBirth from '../DateOfBirth';
import './PersonalDetails.scss';

const PersonalDetails = ({ update, updateStatus }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('01/01/1980');

  useEffect(() => {
    update({ email, phone, dateOfBirth });
  }, [email, phone, dateOfBirth]);
  return (
    <>
      <div className='personal-details-container'>
        <div className='row'>
          <h4>Contact Details</h4>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <EmailInputElement
              value={email}
              onChange={setEmail}
              id='email-input'
              required={true}
              updateStatus={updateStatus}
            />
          </div>
          <div className='col-sm-12 col-md-6 input-item'>
            <PhoneNumber
              value={phone}
              onChange={setPhone}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-6 input-item'>
            <DateOfBirth
              dateOfBirth={dateOfBirth}
              onChange={setDateOfBirth}
              required={true}
              updateStatus={updateStatus}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
