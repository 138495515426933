import React, { useEffect } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import './PhoneNumber.scss';
import { useState } from 'react';

const PhoneNumber = ({ value, onChange, updateStatus, required }) => {
  const [placeholder, setPlaceholder] = useState('Phone Number *');
  const [completedInput, setCompletedInput] = useState(false);
  const [initialErrorFieldSubmitted, setInitialErrorFieldSubmitted] = useState(false);

  if (
    initialErrorFieldSubmitted === false &&
		updateStatus &&
		required === true &&
		value.length === 0
  ) {
    updateStatus(false, 'phone number');
    setInitialErrorFieldSubmitted(true);
  }
  useEffect(() => {
    if (typeof updateStatus !== 'undefined') {
      updateStatus(isPossiblePhoneNumber(value), 'phone');
    }
  }, [completedInput]);
  return required === true ? (
    <PhoneInput
      className={`${completedInput && !isPossiblePhoneNumber(value) ? 'invalid' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={() => {
        setPlaceholder('');
        setCompletedInput(false);
      }}
      onBlur={() => {
        setPlaceholder('Phone Number *');
        !completedInput ? setCompletedInput(true) : null;
      }}
      error={completedInput && !isPossiblePhoneNumber(value) ? 'true' : null}
      minLength={5}
      required
      style={{ flex: 1 }}
    />
  ) : (
    <PhoneInput
      className={`${completedInput && !isPossiblePhoneNumber(value) ? 'invalid' : ''}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onFocus={() => {
        setPlaceholder('');
        setCompletedInput(false);
      }}
      onBlur={() => {
        setPlaceholder('Phone Number *');
        !completedInput ? setCompletedInput(true) : null;
      }}
      error={completedInput && !isPossiblePhoneNumber(value) ? 'true' : null}
      minLength={5}
      style={{ flex: 1 }}
    />
  );
};

export default PhoneNumber;
