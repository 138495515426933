import React from 'react';
import LinkButton from '../LinkButton/LinkButton';
import './Benefits.scss';

const Benefits = ({ title, subtitle, list, button }) => (
  <div className='benefits-container'>
    <div className='row padded-row'>
      <div className='col-12 col-md-5 col-lg-4 benefits-left'>
        {typeof title !== 'undefined' && (
          <h1 className='benefits-title'>
            {title}
            <i className='fas fa-circle html-period'></i>
          </h1>
        )}
        {typeof subtitle !== 'undefined' && <h4 className='text-white'>{subtitle}</h4>}
        {typeof button === 'undefined' ? (
          <div className="row" style={{ justifyContent: 'flex-end', paddingRight: 5 }}>
            <LinkButton
              color='green'
              text='Speak With Us'
              linkSrc='https://landing.dochq.co.uk/video-call/'
            />
          </div>
        ) : (
          <div className="row" style={{ justifyContent: 'flex-end', paddingRight: 5 }}>
            {button}
          </div>
        )}
      </div>
      <div className='col-12 col-md-7 col-lg-8 row benefits-right' style={{margin: 'auto'}}>
        <ul className='shwd-list'>
          {list.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default Benefits;
