import React from 'react';
import SEO from '../../components/SEO/SEO';
import GovShop from '../../components/Shop/Shops/Gov/GovShop';

const PartnersShopPage = ({ location: { href }}) => (
  <>
    <SEO
      title='Gov Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <GovShop location={href} />
  </>
);

export default PartnersShopPage;
