import React, { useState } from 'react';
import { Popover, MenuItem, Box } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Button from '../Button/Button';
import './MenuButton.scss';

const MenuButton = ({ links, text, }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={'menuButtonNavPart'} onClick={handleClick}>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          text={text}
          color="link"
          aria-expanded={open ? 'true' : undefined}
          style={{paddingRight: '0px'}}
        />
        <Box mt={0.5} className='arrows' onClick={handleClick} style={{cursor: 'pointer',}}>
          {
            open
              ? <KeyboardArrowUpIcon fontSize={'large'} className={'arrow'}/>
              : <KeyboardArrowDownIcon fontSize={'large'} className={'arrow'}/>
          }
        </Box>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -5.5,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className="popover"
      >
        {links.map((item, indx) => (
          <MenuItem
            key={indx}
            onClick={handleClose}
            style={{lineHeight: '46px', fontSize: '19px', fontWeight: '400px', fontFamily: 'Roboto', fontStyle: 'normal',}}
          >
            <div className='itemText'>{item}</div>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
export default MenuButton;
