import React from 'react';
import { Grid } from '@material-ui/core';
import AvatarImage from '../AvatarImage/AvatarImage';
import './TeamBlock.scss';

const TeamBlock = ({
  title,
  members,
  greyBackground,
}) => (
  <>
    <Grid
      container
      className={`ourTeamContainer ${greyBackground && 'greyBackground'}`}
      direction='column'
      alignItems='center'
    >
      <Grid
        item
        className='blockTitle'
      >
        <h3>{title}</h3>
      </Grid>
      <Grid
        item
        container
        className='members'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {
          members.map((member, index) => (
            <Grid
              item
              container
              sm={12}
              md={4}
              key={index}
              className='memberContainer'
            >
              <Grid item sm={0} md={2}/>
              <Grid
                item
                sm={12}
                md={8}
                container
                direction='column'
                className='member'
              >
                <Grid
                  item
                  className='image'
                >
                  <AvatarImage
                    imageSrc={member.image}
                    size={218}
                  />
                </Grid>
                <Grid
                  item
                  className='name'
                >
                  <div>{member.name}</div>
                </Grid>
                <Grid
                  item
                  className='position'
                >
                  <div>{member.position}</div>
                </Grid>
                {member.positionInfo && (
                  <Grid
                    item
                    className='position'
                  >
                    <div>{member.positionInfo}</div>
                  </Grid>
                )}
              </Grid>
              <Grid item sm={0} md={2}/>
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  </>
);

export default TeamBlock;
