import Badge from '@material-ui/core/Badge';
import { useCookie } from 'react-use';
import React, {
  useState,
  useEffect,
} from 'react';
import {
  IconButton,
  Grid,
  Box,
} from '@material-ui/core';
import { get } from 'lodash';
import ShopBanner from '../../../ShopBanner/ShopBanner';
import SearchDropbox from '../../SearchDropbox';
import HowTestWorks from '../../HowTestWorks';
import Button from '../../../Button/Button';
import Product from '../../Products/Product';
import Basket from '../../Basket';
import SEO from '../../../SEO/SEO';
import dropboxSvc from '../../../../services/dropbox.service';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import PRODUCTS from '../../../../helpers/products';
import productSvc from '../../../../services/product.service';
import getURLParams from '../../../../helpers/getURLParams';
import discountSvc from '../../../../services/discounts.service';
import CheckAvailability from '../../CheckAvailability';
import Banner from '../../Banner';
import CertificateProduct from '../../Products/CertificateProduct';
import LinkButton from '../../../LinkButton/LinkButton';
import useConfig from '../../../../helpers/useConfig';
import '../Shop.scss';
import './GtcShop.scss';

const GtcShop = ({ location }) => {
  const locationArray = location ? location.split('/') : [''];
  const params = getURLParams(locationArray[locationArray.length - 1]);
  const utm_source = params['utm_source'];
  const [discount, setDiscount] = useState();
  const [value, updateCookie] = useCookie('utm_source');
  const [awcValue, awcUpdateCookie] = useCookie('awc');
  const awc = params['awc'] || awcValue;
  const discountCode = '';
  const [open, setOpen] = useState(false);
  const [dropboxModal, setDropboxModal] = useState(false);
  const [testModal, setTestModal] = useState(false);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [dropboxes, setDropboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const isAnyActiveDropbox = !!(dropboxes.filter(({ active }) => !!active).length);
  const [basket, setBasket] = useState([]);
  const [products, setProducts] = useState([]);
  const inboundProducts = [...products].filter((product) => {
    const tags = get(product, 'tags', []);
    return tags.includes('inbound');
  });
  const outboundProducts = [...products].filter((product) => {
    const tags = get(product, 'tags', []);
    return tags.includes('outbound');
  });
  const totalAmount = basket.reduce((sum, { numberOfKits }) => sum + numberOfKits, 0);
  const antigenConsultation = products.find(({sku}) => PRODUCTS.antigenConsultation == sku);
  const covidTestCertificate = products.find(({sku}) => PRODUCTS.covidTestCertificate == sku);
  const isCertificateProductsActive = !!antigenConsultation && !!antigenConsultation.active && !!covidTestCertificate && !!covidTestCertificate.active;
  const openBasket = () => setOpen(true);
  const closeBasket = () => setOpen(false);
  const addToBasket = (item) => {
    const newItems = [...basket];
    const isItemAlreadyAdded = newItems.find(({ id }) => (id === item.id));
    if (isItemAlreadyAdded) {
      isItemAlreadyAdded.price += item.price;
      isItemAlreadyAdded.numberOfKits += item.numberOfKits;
      isItemAlreadyAdded.delivery = item.delivery;
    } else {
      newItems.push(item);
    }
    setBasket(newItems);
    openBasket();
  };
  const addDoubleProductToBasket = (items) => {
    const newItems = [...basket];
    items.map((item) => {
      const isItemAlreadyAdded = newItems.find(({ id }) => (id === item.id));
      if (isItemAlreadyAdded) {
        isItemAlreadyAdded.price += item.price;
        isItemAlreadyAdded.numberOfKits += item.numberOfKits;
        isItemAlreadyAdded.delivery = item.delivery;
      } else {
        newItems.push(item);
      }
      return item;
    });
    setBasket(newItems);
    openBasket();
  };
  const removeItem = (id) => {
    const newItems = [...basket].filter((item) => !id.includes(item.id));
    setBasket(newItems);
    if (!newItems.length) {
      closeBasket();
    }
  };
  const { config, env } = useConfig();
  const isStaging = env === 'staging';
  const jsonAPI = config.jsonAPI;
  const myhealthUrl = config.myhealthUrl;
  const portalUrl = config.portalUrl;

  const getData = async () => {
    setLoading(true);
    await dropboxSvc.getDropboxes(jsonAPI)
      .then(result => {
        if (result.success && result.dropboxes) {
          setDropboxes(result.dropboxes.filter(({ active }) => !!active));
        } else {
		  		setDropboxes([]);
        }
      })
      .catch(() => {
        setDropboxes([]);
        console.log('error');
	  	});
    await productSvc.getProducts(jsonAPI, 'tags=gtc-shop')
      .then(result => {
        if (result.success && result.products) {
          setProducts(result.products);
        } else {
		  		setProducts([]);
        }
      })
      .catch(() => {
        setProducts([]);
        console.log('error');
	  	});
    if (discountCode) {
      await discountSvc.getDiscountDetails(jsonAPI, discountCode)
        .then(result => {
          if (result.success && result.data && result.data.value && !!result.data.uses) {
            setDiscount(result.data);
          } else {
            console.log('Invalid discount code');
          }
        })
        .catch(() => {
          console.log('Invalid discount code');
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (utm_source === 'ga') {
      updateCookie('ga');
    }
    if (awc) {
      awcUpdateCookie(awc);
    }
    getData();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.Trustpilot) {
      const trustpilotbox = document.getElementById('trustpilot_box');
      window.Trustpilot.loadFromElement(trustpilotbox);
      const trustpilotblock = document.getElementById('trustpilot_block');
      window.Trustpilot.loadFromElement(trustpilotblock);
    }
  }, [loading]);

  return (loading || !products.length) ? (
    <div className='row' style={{ justifyContent: 'center' }}>
      <LoadingSpinner />
    </div>
  ) : (
    <div className="shop-page gtc-shop">
      <SEO
        title='Covid-19 Products'
        description='Covid-19 Products'
      />
      {(!!basket.length && !open) && (
        <div className="shopping-cart-box">
          <IconButton aria-label="cart" onClick={openBasket}>
            <Badge badgeContent={totalAmount} color="secondary">
              <i className="fas fa-shopping-cart" />
            </Badge>
          </IconButton>
        </div>
      )}
      <Box pr={1}>
        <ShopBanner
          imageAlt='People with mask'
          imageSrc='family-in-masks.png'
          title='All DocHQ Covid-19 tests for Global Travel Collection include'
          listClassName="shwd-list-partners"
          list={[
            'Convenient live online consultation with a trained healthcare professional​',
            'All test results are certified for travel by a GMC registered doctor',
            'PCR test analysis is conducted by ISO 15189 and UKAS accredited laboratory',
            'Antigen test kits are CE marked, FDA approved, and adhere to UK Government specificity and sensitivity requirements​',
          ]}
          backgroundStyle={{ backgroundPosition: 'top' }}
          footer={
            <>
              <p className="shop-banner-bottom">
								*DocHQ is on the GOV.UK list of approved providers for the Covid-19 Test scheme
              </p>
            </>
          }
        />
      </Box>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        style={{ padding: 10 }}
        className="products-container"
      >
        <Grid
          item
          container
          spacing={1}
          alignItems='center'
          justifyContent='center'
          direction='row'
          className="buttons-container"
        >
          <Grid
            item xs={12}
            md={4}
            className="button-item-container"
          >
            <Button
              text="How DocHQ testing works"
              color='pink-outlined action-btn sector-btn'
              onClick={() => setTestModal(true)}
            />
          </Grid>
          <Grid
            item xs={12}
            md={4}
            className="button-item-container"
          >
            <LinkButton
              centered
              newTab
              text="VIP - Meet and Greet"
              color='pink action-btn sector-btn'
              linkSrc="mailto:gtc@dochq.co.uk"
            />
          </Grid>
          <Grid
            item xs={12}
            md={4}
            className="button-item-container"
          >
            <Button
              text="Check Availability"
              color='pink-outlined action-btn sector-btn'
              onClick={() => setAvailabilityModal(true)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <LinkButton
            newTab
            text="VIP - Meet and Greet"
            color='pink action-btn sector-btn'
            linkSrc="mailto:gtc@dochq.co.uk"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            text="Check Availability"
            color='pink-outlined action-btn sector-btn'
            onClick={() => setAvailabilityModal(true)}
          />
        </Grid>
        <Banner color="green">
          <h5  style={{ textAlign: 'center' }}>
            Please <a href={`${portalUrl}/login`} className="link underline">Sign In </a>to see your results online and avoid the worry of losing them to the spam folder.
          </h5>
        </Banner>
        {!!outboundProducts.length && (
          <>
            <Grid item xs={12}>
              <h3 className="block-title no-margin">
								UK Outbound Tests
              </h3>
              <h4 className="second-block-title no-margin">
								Please add your selection to the basket
              </h4>
            </Grid>
            {outboundProducts.map((product) => {
              const sku = get(product, 'sku', '');

              return (
                <Grid key={sku} item xs={12} md={4}>
                  {sku === PRODUCTS.antigenConsultation && isCertificateProductsActive ? (
                    <CertificateProduct
                      jsonAPI={jsonAPI}
                      basket={basket}
                      product1={product}
                      discount={discount}
                      isStaging={isStaging}
                      myhealthUrl={myhealthUrl}
                      product2={covidTestCertificate}
                      addToBasket={addDoubleProductToBasket}
                    />
                  ) : (
                    <Product
                      product={product}
                      discount={discount}
                      isStaging={isStaging}
                      addToBasket={addToBasket}
                      myhealthUrl={myhealthUrl}
                      isAnyActiveDropbox={isAnyActiveDropbox}
                    />
                  )}
                </Grid>
              );
            })}
          </>
        )}
        {!!inboundProducts.length && (
          <>
            <Grid item xs={12}>
              <h3 className="block-title no-margin">
								UK Inbound Tests
              </h3>
              <h4 className="second-block-title no-margin">
								Please add your selection to the basket
              </h4>
            </Grid>
            {inboundProducts.map((product) => {
              const sku = get(product, 'sku', '');

              return (
                <Grid key={sku} item xs={12} md={4}>
                  {sku === PRODUCTS.antigenConsultation && isCertificateProductsActive ? (
                    <CertificateProduct
                      jsonAPI={jsonAPI}
                      basket={basket}
                      product1={product}
                      discount={discount}
                      isStaging={isStaging}
                      myhealthUrl={myhealthUrl}
                      product2={covidTestCertificate}
                      addToBasket={addDoubleProductToBasket}
                    />
                  ) : (
                    <Product
                      product={product}
                      discount={discount}
                      isStaging={isStaging}
                      addToBasket={addToBasket}
                      myhealthUrl={myhealthUrl}
                      isAnyActiveDropbox={isAnyActiveDropbox}
                    />
                  )}
                </Grid>
              );
            })}
          </>
        )}
        <Grid item xs={12}>
          <div id="trustpilot_block" className="trustpilot-widget trustpilot-box" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="60a81450941eea000194948f" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
            <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
          </div>
        </Grid>
        <HowTestWorks
          open={testModal}
          handleClose={() => setTestModal(false)}
        />
        <CheckAvailability
          open={availabilityModal}
          bookingUrl={config.bookingAPI}
          handleClose={() => setAvailabilityModal(false)}
        />
        <SearchDropbox
          open={dropboxModal}
          handleClose={() => setDropboxModal(false)}
        />
        <Basket
          awc={awc}
          open={open}
          source="gtc"
          discountCode={discount ? discountCode : ''}
          setBasket={setBasket}
          handleClose={closeBasket}
          basket={basket}
          discount={discount ? discount.value : 0}
          removeItem={removeItem}
        />
      </Grid>
    </div>
  );
};

export default GtcShop;
