import React from 'react';
import './AffiliateRegister.scss';
import LinkButton from '../LinkButton/LinkButton';
import bg from '../../assets/images/register.png';

const AffiliateRegister = () => (
  <>
    <div className='affiliate-register-container'>
      <div className='row'>
        <div
          className='affiliate-left col-md-6 hide-on-small'
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <div className='affiliate-right col-sm-12 col-md-6'>
          <h2>It's easy to register</h2>
          <p>
						Click on the button below, fill in your details and we will get in touch with all the
						information you need to get things up and running
          </p>
          <LinkButton
            text='Sign Up!'
            color='green'
            linkSrc='https://dochq.postaffiliatepro.com/affiliates/signup.php#SignupForm'
            newTab={true}
          />
        </div>
      </div>
    </div>
  </>
);

export default AffiliateRegister;
