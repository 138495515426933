import React from 'react';
import './DocHQAddress.scss';
const DocHQAddress = () => (
  <>
    <div className='address-container'>
      <div className='row'>
        <div className='address-list'>
          <address>

            <h4>DocHQ</h4>
            <p>First Floor</p>
            <p>Teme House</p>
            <p>Whittington Road</p>
            <p>Whittington</p>
            <p>Worcestershire WR5 2RY2HJ</p>
            <p>
							UK Tel: <a href='tel:+443300880645'>03300 880645</a>
            </p>
          </address>
          <p>Reg: 11589439 </p>
          <p>VAT Exempt</p>
        </div>
      </div>
    </div>
  </>
);

export default DocHQAddress;
