import React, { useState, useEffect } from 'react';
import './Carousel.scss';

const Carousel = ({ leftVisible, rightVisible, buttonsVisible, slideContent, center }) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    const id = setTimeout(() => goRight(), 4000);
    return () => clearTimeout(id);
  }, [active]);

  function goLeft() {
    if (active === 0) {
      setActive(slideContent.length - 1);
    } else {
      setActive(active - 1);
    }
  }
  function goRight() {
    if (active === slideContent.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  }
  function goToSlide(slideNum) {
    setActive(slideNum);
  }
  return (
    <div className='slide-container'>
      {leftVisible && <Left setItem={goLeft} />}
      {slideContent &&
				slideContent.map((slide, x) => <Slide key={x} content={slide} slideNum={x} active={active} />)}
      {buttonsVisible && (
        <ButtonContainer content={slideContent} active={active} setItem={goToSlide} />
      )}
      {rightVisible && <Right setItem={goRight} />}
    </div>
  );
};

const Slide = ({ content, slideNum, active }) => (
  <div className='slide' key={slideNum} style={{ transform: `translateX(-${active * 100}%)` }}>
    {content}
  </div>
);

const Left = ({ setItem }) => (
  <div className='left-button' onClick={setItem}>
    <i className='fa fa-chevron-left fa-2x'></i>
  </div>
);
const Right = ({ setItem }) => (
  <div className='right-button' onClick={setItem}>
    <i className='fa fa-chevron-right fa-2x'></i>
  </div>
);
const ButtonContainer = ({ setItem, content, active }) => (
  <div className='button-container'>
    {content.map((item, i) => (
      <div key={i} className={`button ${i === active ? 'active' : ''}`} onClick={() => setItem(i)}>
        <i className='fa fa-circle'></i>
      </div>
    ))}
  </div>
);
export default Carousel;
