import React, { useEffect } from 'react';
import './Modal.scss';

const Modal = ({ isVisible, title, content, footer, onClose, children }) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose && onClose();
    };
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.addEventListener('keydown', closeOnEscapeKeyDown);
      return function cleanup() {
        document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      };
    };
  }, []);

  if (typeof document !== 'undefined' && !isVisible) {
    document.body.style.overflow = 'auto';
    return null;
  } else if (typeof document !== 'undefined' && isVisible) {
    document.body.style.overflow = 'hidden';
    return (
      <div className='modal' onClick={onClose}>
        <div className='modal-dialog' onClick={e => e.stopPropagation()}>
          <div className='modal-header'>
            <h3 className='modal-title'>{title}</h3>
            <span className='modal-close' onClick={onClose}>
              <i className='fa fa-times close-button'></i>
            </span>
          </div>
          <div className='modal-body' id="modal">
            <div className='modal-content'>{content || children}</div>
          </div>
          {footer && <div className='modal-footer'>{footer}</div>}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;
