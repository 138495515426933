import React, { useEffect } from 'react';
import { useCookie } from 'react-use';
import useConfig from '../../helpers/useConfig';
import './Checkout.scss';

const Checkout = ({ title, params }) => {
  const [value] = useCookie('utm_source');
  let discountCode;

  const queryParams = Object.entries(params).map(([name, value]) => `${name}=${value}`).join('&');
  const { config } = useConfig();

  useEffect(() => {
    if (localStorage) {
      if (params['source'] === 'ctn') {
        discountCode = localStorage.getItem('discountCode');
        localStorage.removeItem('discountCode');
      }
      localStorage.removeItem('basket');
    }
  }, []);

  return (
    <div className="checkout">
      <div className='form-with-side-container' style={{ paddingTop: 0 }}>
        <div className='width-container'>
          <div className='row center'>
            <div className='form-container col-sm-12 col-md-8'>
              <div className='row center' style={{ paddingBottom: 12 }}>
                <h4>{title}</h4>
                <iframe id='hf-iframe' className="hf-iframe" style={{ maxWidth: 'unset' }} src={`${config.shopIframeUrl}?${queryParams}${value ? `&utm_source=${value}` : ''}${discountCode ? `&discount_code=${discountCode}` : ''}`}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
