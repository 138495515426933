import React from 'react';
import { Grid } from '@material-ui/core';
import LinkImage from '../LinkImage';
import './ComingSoonAdExtended.scss';

const ComingSoonAdExtended = ({
  imageSrc,
  imageAlt,
  imageSubtitle,
  contentTitle,
  contentBody,
  side,
  imageLink,
  greyBackground,
}) => {
  return side && side === 'left' ? (
    <>
      <div className="coming-soon-ad-container-extended">
        <div className={`coming-soon-ad ${greyBackground && 'grey-background'}`}>
          <div className='row'>
            <div className='coming-soon-item text-part-right col-sm-12 col-md-6'>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item sm={0} md={2}/>
                <Grid
                  item
                  sm={12}
                  md={8}
                  direction='column'
                  alignItems='flex-start'
                  className='textPartContainer'
                >
                  {contentTitle && (
                    <h3 className="title">{contentTitle}</h3>
                  )}
                  <div className='text'>{contentBody}</div>
                </Grid>
                <Grid item sm={0} md={2}/>
              </Grid>
            </div>
            <div className='coming-soon-item col-sm-12 col-md-6'>
              <div className="image-container">
                <LinkImage
                  linkSrc={imageLink}
                  imageSubtitle={imageSubtitle}
                  imageAlt={imageAlt}
                  imageSrc={imageSrc}
                  className="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className='coming-soon-ad-container-extended'>
        <div className={`coming-soon-ad ${greyBackground && 'grey-background'}`}>
          <div className='row'>
            <div className='coming-soon-item col-sm-12 col-md-6'>
              <div className="image-container">
                <LinkImage
                  linkSrc={imageLink}
                  imageSubtitle={imageSubtitle}
                  imageAlt={imageAlt}
                  imageSrc={imageSrc}
                  className="img"
                />
              </div>
            </div>
            <div className='coming-soon-item text-part-right col-sm-12 col-md-6'>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item sm={0} md={2}/>
                <Grid
                  item
                  sm={12}
                  md={8}
                  direction='column'
                  alignItems='flex-start'
                  className='textPartContainer'
                >
                  {contentTitle && (
                    <h3 className="title">{contentTitle}</h3>
                  )}
                  <div className='text'>{contentBody}</div>
                </Grid>
                <Grid item sm={0} md={2}/>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoonAdExtended;
