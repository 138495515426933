import { Box } from '@material-ui/core';
import React from 'react';
import TestTutorialVideoEn from '../../../assets/videos/test-tutorial-en.mp4';
import TestTutorialVideoEs from '../../../assets/videos/test-tutorial-es.mp4';
import TestTutorialVideoDe from '../../../assets/videos/test-tutorial-de.mp4';
import TestTutorialVideoIt from '../../../assets/videos/test-tutorial-it.mp4';
import getURLParams from '../../../helpers/getURLParams';

const HowToMakeATestVideo = ({ location: { href } }) => {
  const param = getURLParams(href);
  let video;
  switch (param['language']) {
  case 'en':
    video = TestTutorialVideoEn;
    break;
  case 'de':
    video = TestTutorialVideoDe;
    break;
  case 'es':
    video = TestTutorialVideoEs;
    break;
  case 'it':
    video = TestTutorialVideoIt;
    break;
  default:
    video = TestTutorialVideoEn;
    break;
  }
  return (
    <>
      <h4 className="no-margin" style={{ textAlign: 'center' }}>How to take a test?</h4>
      <Box display="flex" justifyContent="center" p={4} pt={0}>
        <video controls className="video-container">
          <source src={video} type="video/mp4" />
        </video>
      </Box>
    </>
  );
};

export default HowToMakeATestVideo;
