import React from 'react';
import { Link } from 'gatsby';
import Brochure from '../Brochure/Brochure';
import Carousel from '../Carousel/Carousel';
import './WhatWeDo.scss';

const WhatWeDo = ({ contentItems, title, subtitle, hideBrochure, singlePage, anchorId }) => {
  return (
    <div className='what-we-do-container' id={anchorId}>
      {title && (
        <h2 className='title'>{title}</h2>
      )}
      {subtitle && (
        <p className='text'>{subtitle}</p>
      )}
      <div className='row'>
        {typeof contentItems !== 'undefined' &&
					contentItems.length &&
					contentItems.length > 0 &&
					(typeof singlePage ===
						'undefined' || singlePage === false) && (
          <Carousel
            rightVisible
            buttonsVisible
            slideContent={[
              <Slide1 content={contentItems} />,
              <Slide2 content={contentItems} />,
            ]}
          />
        )}
        {typeof singlePage !== 'undefined' && singlePage === true && (
          <Carousel slideContent={[<Slide2 content={contentItems} center={true}/>]} />
        )}
      </div>
      {(typeof hideBrochure === 'undefined' || hideBrochure === false) && (
        <div className='row brochure-container'>
          <Brochure />
        </div>
      )}
    </div>
  );
};

export default WhatWeDo;

const Slide1 = ({ content }) => (
  <>
    <div className='row'>
      {content.map(({ imageAlt, imageSrc, title, text, linkSrc }, i) =>
        i < 4 ? null : (
          linkSrc ? (
            <div className="col-sm-3 col-md-3 wwd-item" key={i}>
              <Link to={linkSrc} className="col-sm-3 col-md-3 wwd-item">
                <img src={imageSrc} alt={imageAlt || title} />
                <h4>{title}</h4>
                <p className="no-margin">{text}</p>
              </Link>
            </div>
          ) : (
            <div className={'col-sm-3 col-md-3 wwd-item'} key={i}>
              <img src={imageSrc} alt={imageAlt || title} />
              <h4>{title}</h4>
              <p className="no-margin">{text}</p>
            </div>
          )
        )
      )}
    </div>
  </>
);

const Slide2 = ({ content, center }) => (
  <>
    <div className='row' style={{justifyContent: center ? 'center' : ''}}>
      {content.map(({ imageAlt, imageSrc, title, text, comingSoon, linkSrc }, i) =>
        i >= 4 ? null : (
          linkSrc ? (
            <div className="col-sm-3 col-md-3 wwd-item" key={i}>
              <Link to={linkSrc} className="col-sm-3 col-md-3 wwd-item">
                <img src={imageSrc} alt={imageAlt || title} />
                <h4>{title}</h4>
                <p className="no-margin">{text}</p>
                {typeof comingSoon !== 'undefined' && <p style={{fontStyle:'italic'}}>{comingSoon}</p>}
              </Link>
            </div>
          ) : (
            <div className="col-sm-3 col-md-3 wwd-item" key={i}>
              <img src={imageSrc} alt={imageAlt || title} />
              <h4>{title}</h4>
              <p className="no-margin">{text}</p>
              {typeof comingSoon !== 'undefined' && <p style={{fontStyle:'italic'}}>{comingSoon}</p>}
            </div>
          )
        )
      )}
    </div>
  </>
);
