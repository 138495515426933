import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import Modal from '../Modal/Modal';
import TextInputElement from '../FormComponents/TextInputElement';
import dropboxSvc from '../../services/dropbox.service';
import useConfig from '../../helpers/useConfig';
import './Shops/Shop.scss';

const SearchDropbox = ({
  open,
  handleClose,
}) => {
  const [postcode, setPostcode] = useState('');
  const [postcodeError, setPostcodeError] = useState('');
  const [dropboxes, setDropboxes] = useState([]);
  const { config } = useConfig();
  const getDropboxes = async () => {
    await dropboxSvc.searchDropbox(config.jsonAPI, postcode)
      .then(result => {
        if (result.success && result.dropboxes) {
          setDropboxes(result.dropboxes.filter(({ active }) => !!active));
        } else {
          setDropboxes([]);
        }
      })
      .catch(() => {
        setDropboxes([]);
        console.log('error');
      });
  };

  useEffect(() => {
    setPostcodeError();
  }, []);

  useDebounce(() => {
    if (postcode) {
      if (postcode.match(new RegExp(/^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/))) {
        setPostcodeError();
        getDropboxes();
      } else setPostcodeError('Invalid postcode');
    } else {
      setDropboxes([]);
      setPostcodeError();
    }
  }, 300, [postcode]);

  return (
    <Modal
      isVisible={open}
      title={<h4 className="modal-title no-margin">Search nearest DocHQ Dropbox</h4>}
      onClose={() => {
        setDropboxes([]);
        setPostcode();
        setPostcodeError();
        handleClose();
      }}
    >
      <div className="search-dropbox-modal-box">
        <h5 style={{ margin: 0, padding: '10px 0 10px 0' }}>
          Enter postcode
        </h5>
        <div className='row'>
          <TextInputElement
            value={postcode}
            id='postcode'
            error={!!postcodeError}
            helperText={!!postcodeError && postcodeError}
            label='Postcode'
            onChange={setPostcode}
          />
        </div>
        {!!dropboxes.length && (
          <div className='row'>
            <ul className="shwd-list">
              {dropboxes.map(({ facility, distance_to_customer = 0 }, i) => (
                <li key={i}>
                  {facility.name} - {facility.city} {!!distance_to_customer && (
                    <>
                      - {distance_to_customer.toFixed(2)} miles
                    </>
                  )}
                  <br />
                  {facility.address_1}<br />
                  {!!facility.address_2 && (
                    <>
                      {facility.address_2}<br />
                    </>
                  )}
                  {facility.postcode}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SearchDropbox;
