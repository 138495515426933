import React from 'react';
import LinkImage from '../LinkImage';
import './ComingSoonAd.scss';

const ComingSoonAd = ({
  imageSrc,
  imageAlt,
  imageTitle,
  imageSubtitle,
  contentTitle,
  generalTitle,
  contentBody,
  side,
  imageLink,
  footer,
  greyBackground,
}) => {
  return side && side === 'left' ? (
    <>
      <div className="coming-soon-ad-container">
        <div className={`coming-soon-ad ${greyBackground && 'grey-background'}`}>
          {generalTitle && <h2 style={{ fontSize: 19.2 }}>{generalTitle}</h2>}
          <div className='row'>
            <div className='coming-soon-item col-sm-12 col-md-8'>
              {contentTitle && <h2 className="title">{contentTitle}</h2>}
              <div className='text'>{contentBody}</div>
            </div>
            <div className='coming-soon-item left col-sm-12 col-md-4 left-border'>
              <h2>{imageTitle}</h2>
              <div className="image-container">
                <LinkImage
                  linkSrc={imageLink}
                  imageSubtitle={imageSubtitle}
                  imageAlt={imageAlt}
                  imageSrc={imageSrc}
                  className="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className='coming-soon-ad-container'>
        <div className={`coming-soon-ad ${greyBackground && 'grey-background'}`}>
          {generalTitle && <h2 style={{ textAlign: 'center' }}>{generalTitle}</h2>}
          <div className='row'>
            <div className='coming-soon-item left col-sm-12 col-md-4 right-border'>
              <h2>{imageTitle}</h2>
              <div className="image-container">
                <LinkImage
                  linkSrc={imageLink}
                  imageSubtitle={imageSubtitle}
                  imageAlt={imageAlt}
                  imageSrc={imageSrc}
                  className="img"
                />
              </div>
            </div>
            <div className='coming-soon-item col-sm-12 col-md-8'>
              {contentTitle && <h2 className="title">{contentTitle}</h2>}
              <div className='text'>{contentBody}</div>
            </div>
            <div>{footer}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoonAd;
