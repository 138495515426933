import { createMuiTheme } from '@material-ui/core';

export const stepperTheme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      'root': {
        '&$disabled': {
          'color': 'var(--doc-green) !important'
        },
        '&$active': {
          'color': 'var(--doc-green) !important'
        },
        '&$completed': {
          'color': 'var(--doc-green) !important'
        },
      },
    },
    MuiStepLabel: {
      root: {
        paddingBottom: 15,
      }
    }
  },
});
