import React from 'react';
import './OurNumbers.scss';

const OurNumbers = () => (
  <div className='numbers-container'>
    <h4 className='container-title no-margin'>Our Numbers</h4>
    <div className='row chart-row'>
      <div className='col-sm-12 col-md-3 chart-col'>
        <h4 className='title-text'>
          <span className='number green'>100,000</span><span className='number'>+</span><br /><br />Covid-19 travel certificates issued across Europe
        </h4>
        <p></p>
      </div>
      <div className='col-sm-12 col-md-3 chart-col'>
        <h4 className='title-text'>
          <span className='number green'>4.8</span><span className='number'>/5</span><br/><br />Trust Pilot Rating
        </h4>
      </div>
      <div className='col-sm-12 col-md-3 chart-col'>
        <h4 className='title-text'>
          <span className='number green'>10,000</span><span className='number'>+</span><br /><br />Trust Pilot Reviews
        </h4>
      </div>
    </div>
  </div>
);

export default OurNumbers;
