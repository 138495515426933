import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import SEO from '../components/SEO/SEO';
import GoogleMapLocation from '../components/GoogleMapLocation/GoogleMapLocation';
import DocHQAddress from '../components/DocHQAddress/DocHQAddress';
import '../assets/styles/contact-page.scss';

const ContactUsPage = () => (
  <>
    <SEO
      title='Contact Us'
      description="Have some questions for us? Send them over and we'll get back to you as swiftly as possible."
    />
    <HeroBanner
      title='Contact Us'
      tagline='We would love to hear from you. Fill in the details below and we will be in touch to answer any questions or provide further information.'
      imageAlt='Person sending message on computer whilst smiling.'
      imageSrc='contact-us.png'
    />
    <div className='row' style={{ paddingTop: 40 }}>
      <div className='col-sm-12 col-md-3'>
        <div className='item'>
          <DocHQAddress />
        </div>
      </div>
      <div className='col-sm-12 col-md-3'>
        <div className='item'>
          <h4>Contact Us:</h4>
          <p>
					  Email:<a href='mailto:support@dochq.co.uk'> support@dochq.co.uk</a>
          </p>
          <p>
					  Phone: 03300 880645
          </p>
        </div>
      </div>
      <div className='col-sm-12 col-md-6'>
        <div className='item'>
          <GoogleMapLocation />
        </div>
      </div>
    </div>
  </>
);

export default ContactUsPage;
