import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import './PartnersLogoCollection.scss';

const PartnersLogoCollection = ({
  icons,
  title,
  greyBackground
}) => (
  <Grid container xs={12} className={`collection-container ${greyBackground ? 'grey-background' : ''}`} direction='column' fullWidth alignItems='center' justifyContent='center'>
    <Grid item xs={12} className='title-container'>
      <h2 className='title'>{title}</h2>
    </Grid>
    <Grid item container xs={12} className='img-collection-container' direction='row' justifyContent='center' alignItems='center' spacing={50}>
      <>
        {
          icons.map((icon, index) => (
            <Grid item container xs={12} sm={4} md={2} key={index} className='img-container' spacing={2} justifyContent='center' alingItems='center'>
              <img src={icon} className='img' style={{borderRadius: '50%', backgroundColor: 'white',}}></img>
            </Grid>
          ))
        }
      </>
    </Grid>
  </Grid>
);

export default PartnersLogoCollection;
