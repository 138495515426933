import React, { useEffect } from 'react';
import SEO from '../components/SEO/SEO';

const TermsAndConditionsPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.pathname = 'terms-and-conditions';
    }
  }, []);

  return (
    <SEO
      title='Terms and Conditions Covid Testing'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
  );
};


export default TermsAndConditionsPage;
