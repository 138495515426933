import React, { useEffect, useState } from 'react';
import './CardDetails.scss';
import TextInputElement from '../TextInputElement';

const CardDetails = ({ update, updateStatus }) => {
  const [cvv, setCVV] = useState('');
  const [expiration_date, setExpiry] = useState('');
  const [number, setNumber] = useState('');

  function updateExpiry(expiry, lastKey) {
    if (lastKey !== 'Backspace' && !expiry.includes('/') && expiry.length === 2) {
      expiry += '/';
    }
    console.log(expiry);
    setExpiry(expiry);
  }
  useEffect(() => {
    update({ cvv, expiration_date, number });
  }, [cvv, expiration_date, number]);
  return (
    <>
      <div className='row'>
        <div className='col-sm-12 col-md-6 input-item'>
          <TextInputElement
            value={number}
            id='card-number'
            label='Card Number'
            onChange={setNumber}
            autoComplete='cc-number'
            pattern={new RegExp(/^[0-9]{15,16}/)}
            inputProps={{ minLength: '15' }}
            required={true}
            type='number'
            updateStatus={updateStatus}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-6 input-item'>
          <Expiry value={expiration_date} onChange={updateExpiry} updateStatus={updateStatus} />
        </div>
        <div className='col-sm-12 col-md-6 input-item'>
          <TextInputElement
            value={cvv}
            id='card-csc'
            label='Card CVV'
            onChange={setCVV}
            autoComplete='cc-csc'
            pattern={new RegExp(/^[0-9]{3,4}$/)}
            inputProps={{ minLength: '3', maxLength: '4' }}
            required={true}
            type='number'
            style={{ maxWidth: '200px', flex: 1 }}
            updateStatus={updateStatus}
          />
        </div>
      </div>
    </>
  );
};

export default CardDetails;

const Expiry = ({ value, onChange, required, updateStatus, ...rest }) => {
  const [lastKey, setLastKey] = useState();
  useEffect(() => {
    if (updateStatus)
      updateStatus(
        new RegExp(/^(([1-9])|(0[1-9])|(1[0-2]))[\/]+((2[0-9]))$/).test(value),
        'expiry-date'
      );
  }, [value]);
  return required === true ? (
    <TextInputElement
      value={value}
      id='card-expiration_date'
      label='Card Expiry (MM/YY)'
      onChange={value => {
        onChange(value, lastKey);
      }}
      autoComplete='cc-exp'
      pattern={new RegExp(/^(([1-9])|(0[1-9])|(1[0-2]))[\/]+((2[0-9]))$/)}
      inputProps={{ minLength: '5', maxLength: '5' }}
      {...rest}
      type='text'
      onKeyDown={e => setLastKey(e.key)}
      style={{ maxWidth: '200px', flex: 1 }}
      required={true}
    />
  ) : (
    <TextInputElement
      value={value}
      id='card-expiration_date'
      label='Card Expiry (MM/YY)'
      onChange={value => {
        onChange(value, lastKey);
      }}
      autoComplete='cc-exp'
      pattern={new RegExp(/^(([1-9])|(0[1-9])|(1[0-2]))[\/]+((2[0-9]))$/)}
      inputProps={{ minLength: '5', maxLength: '5' }}
      {...rest}
      type='text'
      onKeyDown={e => setLastKey(e.key)}
      style={{ maxWidth: '200px', flex: 1 }}
    />
  );
};
