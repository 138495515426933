import React from 'react';
import Checkout from '../components/Checkout/Checkout';
import SEO from '../components/SEO/SEO';
import getURLParams from '../helpers/getURLParams';

const CheckoutPage = ({ location: { href }}) => {
  const locationArray = href ? href.split('/') : [''];
  const queryParams = locationArray[locationArray.length - 1];
  const params = getURLParams(queryParams);
  const ref = params['ref'];
  const isAmex = params['source'] === 'amex-uk';

  return (
    <>
      <SEO
        title='Checkout | Order Confirmation | DocHQ'
      />
      <Checkout
        title={ref ? 'Purchase your video consultation' : isAmex ? 'Purchase Details' : 'Purchase travel test'}
        params={params}
      />
    </>
  );
};

export default CheckoutPage;
