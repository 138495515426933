import React from 'react';
import Link from '@material-ui/core';

const LinkImage = ({ linkSrc, imageSubtitle, imageSrc, imageAlt, className }) => {
  if (typeof linkSrc === 'undefined') {
    return (
      <>
        <img src={imageSrc} alt={imageAlt} className={className} />
        {typeof imageSubtitle !== 'undefined' && <i>{imageSubtitle}</i>}
      </>
    );
  } else if (
    (typeof linkSrc !== 'undefined' && linkSrc.includes('https://')) ||
		linkSrc.includes('http://')
  ) {
    return (
      <a href={linkSrc} target='_blank' rel='noopener noreferrer'>
        <img src={imageSrc} alt={imageAlt} className={className} />
        {typeof imageSubtitle !== 'undefined' && <i>{imageSubtitle}</i>}
      </a>
    );
  } else if (
    (typeof linkSrc !== 'undefined' && !linkSrc.includes('https://')) ||
		!linkSrc.includes('http://')
  ) {
    return (
      <Link to={imageLink}>
        <img src={imageSrc} alt={imageAlt} className={className} />
        {typeof imageSubtitle !== 'undefined' && <i>{imageSubtitle}</i>}
      </Link>
    );
  } else {
    return (
      <>
        <img src={imageSrc} alt={imageAlt} className={className} />
        {typeof imageSubtitle !== 'undefined' && <i>{imageSubtitle}</i>}
      </>
    );
  }
};

export default LinkImage;
