import { DeploymentType } from '../layouts/MainLayout/MainLayout';
import ENV_VARS from '../config';
import axios from 'axios';

const config =
	DeploymentType !== 'staging'
	  ? ENV_VARS.staging
	  : ENV_VARS.production;
const api = axios.create({
  baseURL: config.discountAPI,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
});

const discountSvc = {
  async getDiscountDetails(baseURL, discountCode) {
    return await new Promise((resolve, reject) => {
      axios({
        url: `${baseURL}/v1/discount/${discountCode}`,
        method: 'GET',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data) {
            resolve({
              success: true,
              data: response.data,
            });
          } else {
            reject({
              success: false,
              error: 'Unable to find discount',
            });
          }
        })
        .catch(() => reject({ success: false, error: 'Server Error Occurred' }));
    });
  },
};
export default discountSvc;
