
import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import SEO from '../components/SEO/SEO';
import '../helpers/themes/tac-pp-theme.scss';

const TermsAndConditionsPage = () => (
  <>
    <SEO
      title='Terms and Conditions'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <HeroBanner
  	  title='Terms and Conditions'
  	  tagline=''
  	  imageAlt='Padlock secured'
  	  imageSrc='privacy.jpg'
  	/>
    <div className='content-container'>
      <h2 className='h2Tag'>IMPORTANT EXCLUSIONS</h2>
      <p className='pTag'>
        WE RELY ON THE CUSTOMERS ACCURATE AND DETAILED DESCRIPTION OF ANY HEALTH
        INFORMATION AND THE CUSTOMER SHOULD NOT IN ANY CIRCUMSTANCES ACCESS THIS
        SERVICE IN AN EMERGENCY OR PRE-EXISTING CONDITION.
        THIS COVID TEST IS A SCREENING TEST FOR ASYMPTOMATIC INDIVIDUALS. IN THE EVENT OF
        SYMPTOMS AND/OR CLINICAL SUSPICION OF AN INFECTION WITH THE CORONAVIRUS COVID-
        19 YOU NEED A DIAGNOSTIC TEST INSTEAD, PLEASE CONTACT YOUR FAMILY DOCTOR OR
        ANOTHER LOCAL MEDICAL FACILITY.
        BY PURCHASING A COVID PCR OR ANTIGEN TEST AND CERTIFICATE, ALL CUSTOMERS PROVIDE
        CONSENT TO SHARE INFORMATION WITH AND AGREED TO FOLLOW GUIDANCE PROVIDED BY
        DOCHQ TRAINED HEALTHCARE PROFESSIONALS OR THIRD-PARTY PROVIDERS.
        THESE TERMS AND CONDITIONS ARE SOLELY FOR THE COVID ANTIGEN AND PCR CERTIFICATE
        SERVICE OFFERED BY DOCHQ. FOR ANY OTHER SERVICE OFFERED CUSTOMERS WILL HAVE TO
        ACCEPT DIFFERENT TERMS AND CONDITIONS.
        PLEASE NOTE THAT THESE TERMS AND CONDITIONS COVER ALL USE OF OUR SERVICES AS SET
        OUT IN OUR WEBSITE AND WE RESERVE THE RIGHT TO AMEND, MODIFY AND/OR CHANGE
        THE SAME FROM TIME TO TIME. FOR THE AVOIDANCE OF DOUBT, THE TERMS APPLICABLE TO
        THE CUSTOMER SHALL (SAVE AND EXCEPT ANY CHANGES IMPOSED BY LAW) BE THOSE TERMS
        AS VALID AT THE TIME OF ANY RELEVANT TRANSACTION.
      </p>
      <h2 className='h2Tag'>Terms and Conditions for the Supply of Services between DocHQ and the Customer</h2>
      <h2 className='h2Tag blue'>1. Interpretation</h2>
      <h3 className='h3Tag blue'>1.1 Definitions. In these Conditions, the following definitions apply:</h3>
      <div className='pTag'><span className='bold'>"Conditions"</span> - these terms and conditions are amended from time to time by DocHQ.</div><br/>
      <div className='pTag'><span className='bold'>"Contract"</span> - the contract between the Customer and DocHQ for the supply of a
      Covid Antigen Fit to
      Travel Certificate.</div><br/>
      <div className='pTag'><span className='bold'>"Customer”</span> - any UK resident over the age of 18 years of age or legal guardian of a UK resident
      under the age of 18 who seeks a Covid Antigen Fit to Travel Certificate for their individual trip.</div><br/>
      <div className='pTag'><span className='bold'>"Healthcare Professional"</span> - registered nurse, GP or other
      relevant qualified medical professional
      who is adequately trained and is knowledgeable in providing online swab sample guidance and
      reporting for a customer.</div><br/>
      <div className='pTag'><span className='bold'>"Intellectual Property Rights”</span> - all customers, rights to inventions, utility models, copyright and
      related rights, trade marks, service marks, trade, business and domain names, rights in trade dress or
      get-up, rights in goodwill or to sue for passing off, unfair competition rights, rights in designs, rights
      in computer software, database right, topography rights, moral rights, rights in confidential
      information (including know-how and trade secrets) and any other intellectual property rights, in each
      case whether registered or unregistered and including all applications for and renewals or extensions
      of such rights, and all similar or equivalent rights or forms of protection in any part of the world.</div><br/>
      <div className='pTag'><span className='bold'>"DocHQ"</span> - DocHQ Limited, business address Wessex House, Upper Market Street, Eastleigh, SO50
      9FD, registered address Teme House Whittington Road, Whittington, Worcester, United Kingdom,
      WR5 2RY (company number 11589439), a 100% subsidiary of HEALTHHQ GROUP LTD
      (company number 11186392). DocHQ Ltd</div><br/>
      <div className='pTag'><span className='bold'>"Appointment Booking”</span> - an online booking made on DocHQ website for online consultation
      with a Healthcare Professional.</div><br/>
      <div className='pTag'><span className='bold'>"Online consultation”</span> - an appointment via a link with a DocHQ Healthcare professional or CQC
      approved partner, providing identification, guidance, instruction and test reporting of the
      customer’s self swab test, to allow certificate generation. This online consultation will take place
      before the customers trip.</div><br/>
      <div className='pTag'><span className='bold'>"Website”</span> - shall mean the websites dochq.co.uk, DocHQ App, and any iFrames or APIs
      developed in relation to health advice created by or on behalf of DocHQ.</div><br/>
      <div className='pTag'><span className='bold'>“CQC Approved Clinics”</span> - means all third party partner clinics. Our partner clinics are registered
      with and comply to the standards required
      by the Care Quality Commission. <a className='aTag pink' href='https://www.cqc.org.uk/'>https://www.cqc.org.uk/</a></div><br/>
      <h3 className='h3Tag blue'>1.2 Construction. In these Conditions, the following rules apply:</h3>
      <div className='pTag'>1.2.1 a person includes a natural person, corporate or unincorporated body (whether or not having
      separate legal personality);</div><br/>
      <div className='pTag'>1.2.2 a reference to a party includes its personal representatives, successors or permitted assigns;</div><br/>
      <div className='pTag'>1.2.3 a reference to a statute or statutory provision is a reference to such statute or statutory provision
      as amended or re-enacted. A reference to a statute or statutory provision includes any subordinate
      legislation made under that statute or statutory provision, as amended or re-enacted;</div><br/>
      <div className='pTag'>1.2.4 a reference to writing includes faxes, texts and e-mails.</div><br/>

      <h2 className='h2Tag blue'>2. Basis of Contract between DocHQ and the Customer</h2>
      <div className='pTag'>2.1 The Contract constitutes the entire agreement between DocHQ and the Customer and any
      variation to these conditions shall have no effect unless expressly agreed in writing by DocHQ.</div><br/>
      <div className='pTag'>2.2 These Conditions apply to the Contract to the exclusion of any other terms that the Customer
      seeks to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</div><br/>
      <div className='pTag'>2.3 The Customer accepting these terms and conditions consents to DocHQ using the Customer's
      personal/sensitive data in accordance with DocHQ's privacy policy.</div><br/>

      <h2 className='h2Tag blue'>3. COVID ANTIGEN CERTIFICATE Supply of services</h2>
      <div className='pTag'>3.1 DocHQ agrees to provide to the Customer:</div><br/>
      <div className='pTag'>3.1.1 One Antigen self-test kit, if the customer has purchased a product that includes an Antigen test
      kit. If the customer has purchased a consultation exclusive product no test kit will be provided.</div><br/>
      <div className='pTag'>3.1.2 Access to book an online consultation</div><br/>
      <div className='pTag'>3.1.3 Link to join the online consultation at the agreed time with a trained healthcare professional.</div><br/>
      <div className='pTag'>3.1.4 A certificate with medical oversight from a GMC registered doctor. A negative or not detected
      test result will allow you to travel with your certificate.</div><br/>
      <div className='pTag'>3.2 DocHQ will use its reasonable endeavours to ensure that the information provided in the
      Appointment Booking and certificate ordering is accurate at the time of its creation. The information
      used to provide the Appointment Booking and certificate ordering is also reliant upon the accuracy
      of the data provided by the Customer.</div><br/>
      <div className='pTag'>3.3 Once the Customer has completed the Appointment Booking information and successfully
      booked an online consultation with DocHQ or one of its partners, the booking details will be emailed
      to the customer.</div><br/>
      <div className='pTag'>3.4 A trained Healthcare Professional will be available online at the agreed time. As part of the
      online guidance for sampling, your identity will be checked against a valid identification document.
      DocHQ aims to produce valid and correct results through controlled processes under medical control
      and in accordance with medical standards. A test certificate can only be issued if the sampling is
      carried out under the supervision of the healthcare professionals during the online consultation and
      the specified processes are followed. DocHQ’s representatives are instructed not to accept the sample
      if there is doubt about the identity of the person to be tested, if the instructions for taking the sample
      are not followed or if there are other doubts about the correctness of the sample. In such a case, any
      reimbursement of costs and liability on the part of DocHQ or one of its partners for any damage
      incurred is excluded.</div><br/>
      <div className='pTag'>3.5.1 A certificate will be issued following the test result. Only valid, not detected test results will
      allow you to travel. We will do our best to issue your certificate as quickly as possible.</div><br/>
      <div className='pTag'>3.5.2 The test certificate contains information about yourself, including your surname, first name,
      date of birth, gender, ID (passport, card or driver’s licence number), details of the test procedure, test
      date and time, sensitivity and specificity for antigen tests, responsible medical service, responsible
      doctor, information about the laboratory in case of a PCR test. The certificate is issued in English.
      This meets the criteria for most occasions where a negative COVID-19 test is required for travelling,
      but DocHQ and its partners cannot guarantee that this certificate will be recognised for the desired
      purpose.</div><br/>
      <div className='pTag'>3.6 Certificates will be issued for Positive, Negative or Invalid results:</div><br/>
      <div className='pTag'>Negative – SARS-CoV-2 is not detected. You can continue on your journey with your certificate.</div><br/>
      <div className='pTag'>Positive – SARS-CoV-2 is detected. Please contact the NHS who will require you to take a PCR test.</div><br/>
      <div className='pTag'>Please self isolate and follow government rules and guidelines DocHQ will inform the relevant
      authority of the positive result.</div><br/>
      <div className='pTag'>Invalid – If no control line is visible the result should be considered invalid. You will need to take
      another test</div><br/>
      <div className='pTag'>Certificates will not be issued if:</div><br/>
      <ul className='pTag'>
        <li>You fail to join the appointment at the arranged time</li><br/>
        <li>Your kit is opened before the online consultation commences</li><br/>
        <li>You do not give permission for the healthcare professional to share their medical information</li><br/>
        <li>The test is not taken as per the healthcare professional’s instruction</li><br/>
        <li>Abusive or inappropriate behaviour/language is directed at the healthcare professional</li><br/>
      </ul>

      <h2 className='h2Tag blue'>4. COVID ANTIGEN CERTIFICATE Customer's Obligations and Understandings</h2>
      <div className='pTag'>4.1 If you are purchasing a test for the purposes of travelling, it is critical that you check both with
      the destination authority and your airline to ensure you give us the exact requirements applicable to
      your trip, and we will base our schedule on the requirements you provide us. Due to the daily
      variations and requirements, we are unable to verify this on your behalf. We will endeavour to share
      our best knowledge, which should not be construed as advice.</div><br/>
      <div className='pTag'>4.2 Order your kit in plenty of time. Allow yourself time to receive the kit, book and join your online
      consultation, take the swab sample and receive your certificate. Due to demand, it is advisable to test
      at the earliest opportunity within your allowable window.</div><br/>
      <div className='pTag'>4.3 To complete your Antigen swab and receive your certificate, you will need to book and join an
      online consultation with our Healthcare Professional. They will provide instruction and guidance for
      your self swab.</div><br/>
      <div className='pTag'>4.4 Any information you provide in completing the Appointment Booking form are fully complete
      and accurate. You will assure that the sample/s originate exclusively from the named person/s and
      will be obtained in accordance with the written and verbal instructions provided during the online
      consultation.</div><br/>
      <div className='pTag'>4.5 By providing an e-mail address, the customer assures that the e-mail address belongs to the
      customer and that the customer wants the test results (which contain health data) to be sent to this e-
      mail address and that he or she agrees to the non-secure communication channel. You may want to
      consider multiple orders if you would like multiple test certificates sent to individual email addresses
      for confidentiality reasons. DocHQ cannot disclose results of any customer to a 3rd party (including
      relatives) other than a consenting adult.</div><br/>
      <div className='pTag'>4.6 If you have placed an order for, and are a legal guardian of a UK resident under the age of 18yrs
      who seeks a Covid Antigen Fit to Travel Certificate, you are required to be present during the online
      consultation, otherwise the Healthcare Professional cannot proceed and will reject the certificate.</div><br/>
      <div className='pTag'>4.7 You are aware that children under 18 years can only attend the online video appointment with an
      adult (18+) present from the same Appointment Booking.</div><br/>
      <div className='pTag'>4.8 If done correctly the test sampling is harmless, however unpleasant body reactions can occur.
      These can vary depending upon the individual and the sampling method used. Possible reactions are
      gagging, coughing, sneezing, watery eyes, slight bleeding from the mucous membranes and dizziness.
      We recommend informing an adult who is nearby about the upcoming test, so that they can provide
      help if necessary. Your address will be requested for your online guidance, so that our team can
      organise help if necessary. If you feel any discomfort during the online consultation, please inform
      your healthcare professional immediately.</div><br/>
      <div className='pTag'>4.9 Do not drink, eat, use mouthwash, brush teeth, or chew gum 30 minutes prior to online
      consultation. The quality of the swab sample depends largely on correct sampling. It is therefore
      important that you follow instructions provided in writing and given during the online consultation.</div><br/>
      <div className='pTag'>4.10 Any opened test kits prior to the online consultation will be deemed as unusable, and the
      healthcare professional will be unable to proceed. Certificates will also be marked as rejected for
      reasons such as, if you fail to join the appointment at the arranged time, you do not give permission
      for the healthcare professional to share your medical information, the test kit is contaminated, the
      test is not taken as per the healthcare professional’s instruction or abusive or inappropriate
      behaviour/language is directed at the healthcare professional. On these occasions you would need to
      reorder and retake your test at your own expense.</div><br/>
      <div className='pTag'>4.11 A negative test result (not detected) will allow you to continue on your journey but does not
      release you from your duty to follow current government recommendations and guidelines.</div><br/>
      <div className='pTag'>4.12 In case of a positive test result detected) you must consider yourself infectious and follow the
      current local government rules regarding self-isolation and diagnostic confirmation. We will inform
      you of the test result but will be unable to supply you with a Negative (not detected) Covid PCR
      Certificate.</div><br/>
      <div className='pTag'>4.13 False negative and false positive test results are possible. This means: despite a negative test
      result, you can be infected with SARS-CoV-2, and conversely, a positive test result does not mean
      that you actually have an acute infection with SARS-CoV-2. A positive result in the rapid antigen
      test must be confirmed by a diagnostic PCR test. To do this, please contact your family doctor, a
      public health service test centre or another medical facility and get advice and testing. Until you
      receive the result of this confirmatory test, you must consider yourself infectious and follow the
      local regulations on self-isolation.</div><br/>
      <div className='pTag'>4.14 As with any other tests, a small percentage of samples can be inconclusive or invalid for
      reasons including insufficient DNA match, not following instructions strictly, contamination of the
      sample or insufficient material. In the unlikely event that this happens, you understand and agree
      that you will need to retake the test and the same charges will apply. We will inform you of the test
      result but will be unable to supply you with a negative (not detected) Covid Antigen Certificate.</div><br/>
      <div className='pTag'>4.15 The Healthcare Professional may deem the appointment as rejected, for reasons such as: you
      do not have sufficient ID, you do not follow instruction and guidance, you are unable to join the
      appointment link, you are aggressive or abusive, a child has joined the appointment without their
      adult in the appointment booking, On these such occasions you would need to reorder and retake
      your test at your own expense.</div><br/>
      <div className='pTag'>4.16 Be aware that this online guided Covid-19 self-test is a screening method, not a diagnostic
      service. It does not replace a consultation with a medical doctor.</div><br/>
      <div className='pTag'>4.17 By purchasing this test, you acknowledge and agree to the terms above and that DocHQ
      cannot be held liable for any damage to health, losses or delays due to the many factors involved
      which are outside our control. Your purchase constitutes formal consent for DocHQ to share your
      details with the Laboratory assigned to your test and relevant third party suppliers and releases the
      involved doctors and medical staff from their confidentiality agreement. The necessary involved
      partners are: Park and St Francis Surgery, Ciconia Recovery, UK Public Health England and any
      relevant regulatory authorities.</div><br/>
      <div className='pTag'>4.18 The product is non-transferable.</div><br/>

      <h2 className='h2Tag blue'>5. COVID PCR CERTIFICATE Supply of services</h2>
      <div className='pTag'>5.1 DocHQ agrees to provide to the Customer:</div><br/>
      <div className='pTag'>5.1.1 Provision of a PCR self test kit</div><br/>
      <div className='pTag'>5.1.2 Access to book an online consultation on the Website</div><br/>
      <div className='pTag'>5.1.3 Link to join the online consultation at the agreed time with a trained healthcare professional.</div><br/>
      <div className='pTag'>5.1.4 Provision of a PCR test result after analysis at our lab partners</div><br/>
      <div className='pTag'>5.1.5 A certificate with medical oversight from a GMC registered doctor. A negative or not detected
      test result will allow you to travel with your certificate.</div><br/>
      <div className='pTag'>5.2 DocHQ will use its reasonable endeavours to ensure that the information provided in the
      Appointment Booking and certificate ordering is accurate at the time of its creation. The information
      used to provide the Appointment Booking and certificate creation is also reliant upon the accuracy
      of the data provided by the Customer.</div><br/>
      <div className='pTag'>5.3 Once the Customer has completed the Appointment Booking information and successfully
      booked an online consultation with DocHQ or one of its partners, the booking details will be emailed
      to the customer.</div><br/>
      <div className='pTag'>5.4 A trained Healthcare Professional will be available online at the agreed time. As part of the
      online guidance for sampling, your identity will be checked against a valid identification document.
      DocHQ aims to produce valid and correct results through controlled processes under medical control
      and in accordance with medical standards. A test certificate can only be issued if the sampling is
      carried out under the supervision of the healthcare professionals during the online consultation and
      the specified processes are followed. DocHQ’s representatives are instructed not to accept the sample
      if there is doubt about the identity of the person to be tested, if the instructions for taking the sample
      are not followed or if there are other doubts about the correctness of the sample. In such a case, any
      reimbursement of costs and liability on the part of DocHQ or one of its partners for any damage
      incurred is excluded.</div><br/>
      <div className='pTag'>5.5.1 A certificate will be issued following the test result. The certificate has medical oversight from
      a GMC registered doctor. Only valid, not detected test results will allow you to travel on your
      journey. We will do our best to issue your certificate as quickly as possible. However, delays in the
      process are possible, therefore, the timely presentation of the test results (e.g. before departure of a
      flight) cannot be guaranteed. Incorrect packaging or labelling can mean that a sample cannot be
      assigned or used so it’s essential that you follow the instructions provided. On rare occasions a
      sample may not be evaluated by the laboratory (so called inhibited sample, termination of the run,
      etc.) or the result takes longer without DocHQ or its partners being at fault. In these cases, we will
      offer you a repeat test at no additional cost. However, in such instances the test result will take
      additional time and we are not liable for any damage or travel disruption caused by such a delay.</div><br/>
      <div className='pTag'>5.5.2 The test certificate contains information about yourself, including your surname, first name,
      date of birth, gender, ID (passport, card or driver’s licence number), details of the test procedure,
      test date and time, sensitivity and specificity for antigen tests, responsible medical service,
      responsible doctor, information about the laboratory in case of a PCR test. The certificate is issued
      in English. This meets the criteria for most occasions where a negative COVID-19 test is required
      for travelling , but DocHQ and its partners cannot guarantee that this certificate will be recognised
      for the desired purpose.</div><br/>
      <div className='pTag'>5.6 Certificates will be issued for Positive, Negative or Invalid results:</div><br/>
      <div className='pTag'>Negative – SARS-CoV-2 is not detected. You can continue on your journey with your certificate</div><br/>
      <div className='pTag'>Positive – SARS-CoV-2 is detected. Please self isolate and follow government rules and guidelines</div><br/>
      <div className='pTag'>DocHQ will inform the relevant authority of the positive result</div><br/>
      <div className='pTag'>Indeterminate – The lab will provide a reason for your indeterminate result. You will need to take
      another test</div><br/>
      <div className='pTag'>Certificates will not be issued if:</div><br/>
      <ul className='pTag'>
        <li>You fail to join the appointment at the arranged time</li><br/>
        <li>Your kit is opened before the online consultation commences</li><br/>
        <li>You do not give permission for the healthcare professional to share their medical information</li><br/>
        <li>The test is not taken as per the healthcare professional’s instruction</li><br/>
        <li>Abusive or inappropriate behaviour/language is directed at the healthcare professional</li><br/>
      </ul>

      <h2 className='h2Tag blue'>6. COVID PCR CERTIFICATE Customer's Obligations and Understandings</h2>
      <div className='pTag'>6.1 If you are purchasing a test for the purposes of travelling, it is critical that you check both with
      the destination authority and your airline to ensure you give us the exact requirements applicable to
      your trip, and we will base our schedule on the requirements you provide us. Due to the daily
      variations and requirements, we are unable to verify this on your behalf. We will endeavour to share
      our best knowledge, which should not be construed as advice.</div><br/>
      <div className='pTag'>6.2 Order your kit in plenty of time. Allow yourself time to receive the kit, book and join your online
      consultation, take the swab sample and receive your certificate. Due to demand, it is advisable to test
      at the earliest opportunity within your allowable window.</div><br/>
      <div className='pTag'>6.3 To complete your PCR swab and receive your certificate, you will need to book and join an
      online consultation with our Healthcare Professional. They will confirm customer identity, provide
      instruction and guidance for your self swab.</div><br/>
      <div className='pTag'>6.5 When sending your swab sample to the Laboratory, you must ensure your sample date and time
      meets your testing window and reaches the Laboratory at the correct time. We supply you with free
      Royal Mail Track 24 delivery prepaid postage in your kit, or alternatively you can arrange for a
      courier service to take your swab to the lab at your own expense. Samples arriving outside the correct
      window may lead to delays or incorrect timing of the test certificate. By confirming your order you
      acknowledge that it is your responsibility to check travel requirements carefully, as under no
      circumstance will DocHQ be responsible for missed flights or any losses incurred in connection with
      testing, including in the event of a positive result.</div><br/>
      <div className='pTag'>6.6 Please be aware that due to Royal Mail UK services our product may not be suitable if you
      require a swab sample on a Saturday (expected delivery to lab on Monday) or Sunday (expected
      delivery to lab on Tuesday. Please check your time window. You are welcome to arrange a courier at
      your own expense, to deliver your swab to the lab to meet your time window requirements.</div><br/>
      <div className='pTag'>6.7 Any information you provide in completing the Order or Appointment Booking form are fully
      complete and accurate. You will assure that the sample/s originate exclusively from the named
      person/s and will be obtained in accordance with the written and verbal instructions provided during
      the online consultation.</div><br/>
      <div className='pTag'>6.8 By providing an e-mail address, the customer assures that the e-mail address belongs to the
      customer and that the customer wants the test results (which contain health data) to be sent to this e-
      mail address and that he or she agrees to the non-secure communication channel. You may want to
      consider multiple orders if you would like multiple test certificates sent to individual email addresses
      for confidentiality reasons. DocHQ cannot disclose the results of any customer to a 3rd party
      (including relatives).</div><br/>
      <div className='pTag'>6.9 If you have placed an order for, and are a legal guardian of a UK resident under the age of 18yrs
      who seeks a Covid PCR Fit to Travel Certificate, you are required to be present during the online
      consultation, otherwise the Healthcare Professional cannot proceed and will reject the certificate.</div><br/>
      <div className='pTag'>6.10 You are aware that children under 18 years can only attend the online video appointment with an
      adult (18+) present from the same Appointment Booking.</div><br/>
      <div className='pTag'>6.11 If done correctly the test sampling is harmless, however unpleasant body reactions can occur.
      These can vary depending upon the individual and the sampling method used. Possible reactions are
      gagging, coughing, sneezing, watery eyes, slight bleeding from the mucous membranes and dizziness.
      We recommend informing an adult who is nearby about the upcoming test, so that they can provide
      help if necessary. Your address will be requested for your online guidance, so that our team can
      organise help if necessary. If you feel any discomfort during the online consultation, please inform
      your healthcare professional immediately.</div><br/>
      <div className='pTag'>6.12 You or the individual being tested must not drink, eat, use mouthwash, brush teeth, or chew gum
      30 minutes prior to online consultation. The quality of the swab sample depends largely on correct
      sampling. It is therefore important that you follow instructions provided in writing and given during
      the online consultation.</div><br/>
      <div className='pTag'>6.13 Please make sure your swab sample is labelled correctly with the name as shown in your
      passport including all middle names and correct date of birth. Errors that occur during the sampling
      and shipment processes can lead to delays or make a sample unreadable or invalid.</div><br/>
      <div className='pTag'>6.14 Any opened test kits prior to the online consultation will be deemed as unusable, and the
      healthcare professional will be unable to proceed. Certificates will also be marked as rejected for
      reasons such as, if you fail to join the appointment at the arranged time, you do not give permission
      for the healthcare professional to share your medical information, the test kit is contaminated, the test
      is not taken as per the healthcare professional’s instruction or abusive or inappropriate
      behaviour/language is directed at the healthcare professional On these such occasions you would
      need to reorder and retake your test at your own expense.</div><br/>
      <div className='pTag'>6.15 A negative test result (not detected) will allow you to continue on your journey but does not
      release you from your duty to follow current government recommendations and guidelines.</div><br/>
      <div className='pTag'>6.16 In case of a positive test result (detected) you must consider yourself infectious and follow the
      current local rules regarding self-isolation and diagnostic confirmation. We will inform you of the
      test result but will be unable to supply you with a Negative (not detected) Covid PCR Certificate.</div><br/>
      <div className='pTag'>6.17 False negative and false positive test results are possible. This means: despite a negative test
      result, you can be infected with SARS-CoV-2, and conversely, a positive test result does not mean
      that you actually have an acute infection with SARS-CoV-2. Please contact your family doctor, a
      public health service test centre or another medical facility and get advice and testing. Until you
      receive the result of this confirmatory test, you must consider yourself infectious and follow the
      local regulations on self-isolation.</div><br/>
      <div className='pTag'>6.18 As with any other tests, a small percentage of samples can be inconclusive or invalid for
      reasons including insufficient DNA match, not following instructions strictly, contamination of the
      sample or insufficient material. In the unlikely event that this happens, you understand and agree
      that you will need to re-take the test and the same charges will apply. We will inform you of the test
      result but will be unable to supply you with a negative (not detected) Covid PCR Certificate.</div><br/>
      <div className='pTag'>6.19 The online guided Covid-19 self-test is a screening method, not a diagnostic service. It does
      not replace a consultation with a medical doctor.</div><br/>
      <div className='pTag'>6.20 By purchasing this test, you acknowledge and agree to the terms above and that DocHQ
      cannot be held liable for any damage to health, losses or delays due to the many factors involved
      which are outside our control. Your purchase constitutes formal consent for DocHQ to share your
      details with the Laboratory assigned to your test and relevant third-party suppliers and releases the
      involved doctors and medical staff from their confidentiality agreement. The necessary involved
      partners are: Park and St Francis Surgery, Ciconia Recovery, UK Public Health England and any
      relevant regulatory authorities.</div><br/>
      <div className='pTag'>6.21 When purchasing Test to Release, Day 2 and Day 8 Testing - Please enter the Order ref
      number (5 digits, 7 numbers) into your Passenger Locator Form (PLF).<br/>
      <a className='aTag pink' href='https://www.gov.uk/provide-journey-contact-details-before-travel-uk'>
        https://www.gov.uk/provide-journey-contact-details-before-travel-uk
      </a></div><br/>
      <div className='pTag'>Please book your Day 2 appointment on the second day of your arrival back into the UK.</div><br/>
      <div className='pTag'>Please book your Test to Release appointment on the fifth day of your arrival back into the UK</div><br/>
      <div className='pTag'>Please book your Day 8 appointment on the eighth day of your arrival back into the UK.</div><br/>
      <div className='pTag'>6.23 The Product is non transferable</div><br/>

      <h2 className='h2Tag blue'>7. Charges and Refunds</h2>
      <div className='pTag'>7.1 Covid PCR or Antigen Certificate fee is paid upfront and includes the booking of an online
      consultation with a Healthcare Professional. The fee is stated on the DocHQ website.</div><br/>
      <div className='pTag'>7.2 Your Covid purchase is non-returnable and non-refundable under any circumstances once
      dispatched due to the medical nature of the product.</div><br/>
      <div className='pTag'>7.3 All inbound products, defined as all tests that has to be taken after landing into the UK, are not
      cancellable and not refundable under any circumstances, as per government guidelines.</div><br/>
      <div className='pTag'>7.4 Cancellations before dispatch will incur a £5 transaction fee per each transaction. This amount
      will be deducted from the refund amount.</div><br/>
      <div className='pTag'>7.5 No refund will be given after a kit has been dispatched. Kits can be kept for future use within 6
      months from date of purchase.</div><br/>
      <div className='pTag'>7.6 The appointment can be cancelled or modified up to 24 hours before the appointment start time,
      after which the appointment cannot be changed. The appointment can be changed only once.</div><br/>
      <div className='pTag'>7.7 Failure to attend your online consultation will result in your certificate not being issued and there
      will be an additional charge of £15 to rebook a further appointment.</div><br/>
      <div className='pTag'>7.8 DocHQ has no control of Royal Mail delivery and is unable to take responsibility for any delay in
      delivery caused by Royal Mail. Any lost/damaged kits should be claimed for through Royal Mail.</div><br/>

      <h2 className='h2Tag blue'>8. Complaints</h2>
      <div className='pTag'>8.1 Any complaints about the services should be addressed to: DocHQ, Wessex House, Upper
      Market Street, Eastleigh, Hampshire, SO50 9FD</div><br/>
      <div className='pTag'>8.2 An acknowledgment of the complaint will be provided to the customer within 2 days of receipt
      (unless a full reply can be sent within 5 working days), however we aim to acknowledge within 24
      hours. A full response should normally be made back to the customer within 20 working days of
      receipt of the complaint. Where an investigation is still in progress, a letter explaining the reason for
      the delay will be sent to the customer and a full response made within 5 working days of completion
      of the investigation.</div><br/>
      <div className='pTag'>8.3 The DocHQ complaints policy applies to complaints against all personnel (both clinical and non-
      clinical), including those clinicians with practising privileges, as well as to those against the
      organisation. In addition, the organisation has a documented practising privileges agreement with
      independent practitioners which stipulates that handling complaints is a condition of the granting and
      continuation of privileges. The Medical Advisory Committee (MAC) together with the Clinical
      Director regularly review clinical complaints and advise accordingly on any clinical practice issues
      arising.</div><br/>
      <div className='pTag'>8.4 Depending on the content of the complaint, an appropriate senior manager will review the
      complaint and either confirm the findings and actions taken or offer to implement an alternative
      resolution via DocHQ’s Internal Appeal (including upholding the original complaint).</div><br/>

      <h2 className='h2Tag blue'>9. Intellectual Property Rights</h2>
      <div className='pTag'>9.1 All Intellectual Property Rights in or arising out of or in connection with the Order summary
      shall be owned by DocHQ.</div><br/>
      <div className='pTag'>9.2 The Customer acknowledges that, in respect of any third party Intellectual Property Rights, the
      Customer's use of any such Intellectual Property Rights is conditional on DocHQ obtaining a written
      licence from the relevant licensor on such terms as will entitle DocHQ to license such rights to the
      Customer.</div><br/>
      <div className='pTag'>9.3 The Customer agrees and warrants that it shall only utilise the order summary for its intended
      purpose.</div><br/>

      <h2 className='h2Tag blue'>10. Limitation of Liability:</h2>
      <div className='pTag'>10.1 Nothing in these Conditions shall limit or exclude DocHQ's liability for:</div><br/>
      <div className='pTag'>10.1.1 death or personal injury caused by its negligence, or the negligence of its employees, agents
      or subcontractors;</div><br/>
      <div className='pTag'>10.1.2 fraud or fraudulent misrepresentation; or</div><br/>
      <div className='pTag'>10.1.3 breach of the terms implied by section 2 of the Supply of Goods and Services Act 1982 (title
      and quiet possessions).</div><br/>
      <div className='pTag'>10.2 Subject to clause 10.1:</div><br/>
      <div className='pTag'>10.2.1 DocHQ shall not be liable to the Customer, whether in contract, tort (including negligence),
      breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss
      arising under or in connection with the contract.</div><br/>
      <div className='pTag'>10.2.2 DocHQ’s total liability to the Customer in respect of all other losses arising under or in
      connection with the Contract, whether in contract, tort (including negligence), breach of statutory
      duty, or otherwise, a deliberate breach of the Contract by DocHQ, its employees, agents or
      subcontractors shall not exceed £1,000,000 (one million pounds).</div><br/>
      <div className='pTag'>10.3 Except as set out in these Conditions, all warranties, conditions and other terms implied by
      statute or common law are, to the fullest extent permitted by law, excluded from the Contract.</div><br/>

      <h2 className='h2Tag blue'>11. Termination</h2>
      <div className='pTag'>11.1 Without limiting its other rights or remedies, DocHQ may terminate the Contract with
      immediate effect by giving written notice to the Customer if the Customer commits a material
      breach of the Contract and misuses the information provided by DocHQ.</div><br/>

      <h2 className='h2Tag blue'>12. No Partnership</h2>
      <div className='pTag'>12.1 Nothing in the Contract is intended to, or shall be deemed to, constitute a partnership or joint
      venture of any kind between any of the parties, nor constitute any party the agent of another party
      for any purpose. No party shall have authority to act as agent for, or to bind, the other party in any
      way.</div><br/>

      <h2 className='h2Tag blue'>13. Third Parties</h2>
      <div className='pTag'>13.1 A person who is not a party to the Contract shall not have any rights under or in connection
      with it.</div><br/>

      <h2 className='h2Tag blue'>14. Variation</h2>
      <div className='pTag'>14.1 Except as set out in these Conditions, any variation, including the introduction of any
      additional terms and conditions, to the Contract, shall only be binding when agreed in writing and
      signed by DocHQ.</div><br/>

      <h2 className='h2Tag blue'>15. Governing Law and Jurisdiction:</h2>
      <div className='pTag'>15.1 This Contract, and any dispute or claim arising out of or in connection with it or its subject
      matter or formation (including non-contractual disputes or claims), shall be governed by, and
      construed in accordance with English law.</div><br/>

      <br/><br/>
      <a className='aTag pink' href='/terms-and-conditions.pdf' about='_blank' rel='norefferer'>Terms and Conditions - PDF Format</a>
    </div>
  </>
);

export default TermsAndConditionsPage;
