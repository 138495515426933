import React, { useEffect } from 'react';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import PurchaseForm from '../components/PurchaseForm/PurchaseForm';
import HeaderWithContent from '../components/HeaderWithContent/HeaderWithContent';
import ThreeStepsCovid from '../components/ThreeStepsCovid/ThreeStepsCovid';
import HeroColumns from '../components/HeroColumns/HeroColumns';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { navigate } from 'gatsby';
import CenterTwoColumns from '../components/CenterTwoColumns/CenterTwoColumns';
import LinkButton from '../components/LinkButton/LinkButton';
import FAQ from '../components/FAQ/FAQ';

const FAQContent = [
  {
    question: 'What are the symptoms of Covid-19?',
    answer:
			'Symptoms may not appear for up to two weeks after you have become infected with the virus. This means that it is possible to spread the virus without having any symptoms. If present, symptoms of Covid-19 include: Fever, Cough, Fatigue and Shortness of Breath',
  },
  {
    question: 'Why should I consider a Covid-19 test?',
    answer:
			'Covid-19 is spreading quickly. You should consider testing for Covid-19 if you are experiencing any of the above symptoms, you have recently travelled to a high-risk country or you think you may have come into contact with someone who has Covid-19.',
  },
  {
    question: 'How does the test work?',
    answer:
			'Your Covid-19 home test requires a throat swab. Inside your home testing package, you will find a swab, a pathology form and a postage paid envelope. We will also provide you with a set of instructions detailing how to collect your sample. Once you have taken your sample, please use the postage paid envelope to send it to our accredited UK laboratory.',
  },
  {
    question: 'How accurate is the test?',
    answer: 'This Covid-19 test is highly accurate at 99.9%.',
  },
  {
    question: 'When will my home testing kit arrive?',
    answer:
			'Orders placed before 4pm, Monday to Friday, will be sent out on the same day by first-class post.',
  },
  {
    question: 'When will I receive my results?',
    answer:
			'Your test result will be available within two working days following receipt of your sample at our laboratory. You will be able to view your result in a secure pdf link.',
  },
  {
    question: 'What if I test positive?',
    answer:
			'Should your result be positive, you must immediately self-isolate to prevent spreading the virus to others. You should then contact 111 who will advise you on the next steps to take.',
  },
  {
    question: 'How can I order my home test?',
    answer:
			'To order your Covid-19 home test today, simply click Buy Now and follow the instructions.',
  },
  { question: 'What if I have more questions?', answer: 'Contact Us for more information' },
];

const Covid19HomeTestKitPage = () => {
  useEffect(() => {
    navigate('/shop');
  }, []);

  return (
    <>
      <SEO
        title='Covid-19 Products | DocHQ – Your Health | Your Choice | Your Control'
        description="Worried about Covid-19? Take our home test kit. A reliable PCR lab test for Covid-19 even when you don't show symptoms."
        keywords='DocHQ, Covid-19 travel certificates, Travel Planning, Travel Certificates'
      />
      {/* <HeroBanner
				title='Covid-19 Home Test Kit'
				tagline='Use our Home Test Kit, a reliable PCR lab test which tests for Covid-19 even when you don’t show symptoms.'
				imageSrc='swab-test.jpg'
				imageAlt='Person swabbing mouth'
			/>
			<HeaderWithContent
				header=''
				content={
					<p>
						We highly recommend you use our Covid-19 symptom checker before you take this Covid-19
						test. If the symptom checker doesn't give you a warning then it would be better to not
						take the test.
					</p>
				}
				color='var(--doc-white)'
				backgroundColor='var(--doc-pink)'
				hasButton={true}
				buttonColor='green'
				buttonLinkSrc='/covid-19-risk-assessment-tool'
				buttonText='Check Now'
			/>
			<ThreeStepsCovid />
			<HeroColumns
				numCols={1}
				title='Reliable and Accurate Test'
				tagline='We ensure support every step of the way.'
				column1={<Col1 />}
			/>
			<CenterTwoColumns left={<LeftCol />} right={<RightCol />} hasBottom bottom={<Bottom />} />
			<HeaderWithContent
				header=''
				content={
					<p>
						We highly recommend you use our Covid-19 symptom checker before you take this Covid-19
						test. If the symptom checker doesn't give you a warning then it would be better to not
						take the test.
					</p>
				}
				color='var(--doc-white)'
				backgroundColor='var(--doc-pink)'
				hasButton={true}
				buttonColor='green'
				buttonLinkSrc='/covid-19-risk-assessment-tool'
				buttonText='Check Now'
			/>
			<PurchaseForm />
			<div className='row' style={{ margin: 'auto', maxWidth: '1200px' }}>
				<h2>Frequently Asked Questions</h2>
			</div>
			<div className='row' style={{ margin: 'auto', maxWidth: '1200px', paddingBottom: '60px' }}>
				{FAQContent.map((item, i) => (
					<FAQ question={item.question} answer={item.answer} key={i} />
				))}
			</div> */}
    </>
  );
};

export default Covid19HomeTestKitPage;

const Col1 = () => (
  <>
    <p>
			If you are positive for Covid-19 through this private test you must immediately self isolate
			to prevent spreading the virus to others.
    </p>
    <p>You should then dial the NHS (111) who will advice you of the next steps to take.</p>
    <p>
			We are commited to offering support from our private service at this difficult time to help
			reduce the pressure on our much appreciated NHS and provide patient alternatives if that is
			what you are seeking.
    </p>
    <p>
			This service is offered in partnership with: UKAS ISO 15189:2012 standards Laboratory:
			Nationwide Pathology
    </p>
    <Image />
  </>
);
const Image = () => {
  const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "nationwide-pathology.jpeg" }) {
				childImageSharp {
					fluid(maxWidth: 200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} style={{ width: '200px' }} />;
};

const LeftCol = () => (
  <div style={{ textAlign: 'center' }}>
    <h3>Covid-19 PCR Home Test Kit</h3>
    <h4>
      <strong>Price: £195</strong>
    </h4>
    <LinkButton text='Buy Now' linkSrc='/covid-19-home-test-kit#order-form' color='pink' />
  </div>
);
const RightCol = () => (
  <div style={{ textAlign: 'center' }}>
    <h3>How do we compare to other home test kits?</h3>
    <p>Harley Street Clinic – PCR test: £375</p>
    <p>Better2Know – PCR test: £195</p>
    <p>Test My Blood – *Antibody test: £299</p>
  </div>
);
const Bottom = () => (
  <>
    <p style={{ width: '100%', textAlign: 'left' }}>
			Our accurate Covid-19 PCR test is based on the detection of nucleic material (RNA) within the
			virus. Detection of viral RNA allows for the detection of an infection at a much earlier stage
			following exposure. Other test methods are based on the detection of *antibodies that are only
			produced as a response to the infection and are identified at a much later stage following
			exposure.
    </p>
  </>
);
