import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import brochureSvc from '../../services/brochure.service';
import TextInputElement from '../FormComponents/TextInputElement';
import EmailInputElement from '../FormComponents/EmailInputElement';
import brochurePdf from '../../assets/files/dochq-brochure.pdf';

export default class Brochure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
    this.setIsVisible = setIsVisible.bind(this);
    function setIsVisible(value) {
      this.setState({ isVisible: value });
    }
  }

  render() {
    return (
      <>
        <Button
          text='Receive our Brochure'
          color='pink'
          onClick={() => {
            this.setIsVisible(true);
          }}
        />
        <Modal
          title='Receive our Brochure'
          isVisible={this.state.isVisible}
          onClose={() => {
            this.setIsVisible(false);
          }}
          content={<BrochureForm />}
        />
      </>
    );
  }
}
const BrochureForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  function updateStatus(valid, field) {
    if (valid) {
      const newErrors = errors.filter(fieldName => fieldName !== field);
      setErrors(newErrors);
    }
    if (typeof errors[field] === 'undefined' && !valid) {
      const newErrors = errors;
      newErrors.push(field);
      setErrors(newErrors);
    }
  }
  async function submitDetails() {
    if (errors.length === 0) {
      setLoading(true);
      setDisplayError(false);
      console.log('here');
      await brochureSvc
        .submitDetails({ email, name, accept: true })
        .then(resp => {
          if (resp) {
            setLoading(false);
            setSuccess(true);
            setDisplayError(false);
          }
        })
        .catch(err => {
          setLoading(false);
          setSuccess(false);
        });
    } else if (errors.length !== 0) {
      setDisplayError(true);
    }
  }

  return (
    <form noValidate>
      {!success && (
        <div className='row'>
          <div className='col-sm-12'>
            <TextInputElement
              value={name}
              id='full-name'
              label='Full Name'
              onChange={setName}
              autoComplete='name'
              pattern={new RegExp(/^[a-zA-Z ]{2,}$/)}
              inputProps={{ minLength: '2' }}
              required={true}
              updateStatus={updateStatus}
              style={{ width: '100%', margin: '20px 0px' }}
            />
            {displayError && errors.find(error => error === 'full name') && (
              <p style={{ color: 'var(--doc-pink)' }}>Please enter your name</p>
            )}
          </div>
          <div className='col-sm-12'>
            <EmailInputElement
              value={email}
              onChange={setEmail}
              id='email-input'
              required={true}
              updateStatus={updateStatus}
              style={{ width: '100%', margin: '20px 0px' }}
            />
            {displayError && errors.find(error => error === 'email') && (
              <p style={{ color: 'var(--doc-pink)' }}>Please enter a valid email address</p>
            )}
          </div>
        </div>
      )}
      {loading && (
        <div className='row' style={{ justifyContent: 'center' }}>
          <LoadingSpinner />
        </div>
      )}
      {!success && (
        <div className='row'>
          <div className='col-sm-12'>
            <p>
							By clicking submit you agree to our{' '}
              <a
                href='/terms-and-conditions'
                target="_blank"
                rel='noopener noreferrer'
                style={{ color: 'var(--doc-pink)' }}
              >
								Terms and Conditions
              </a>
            </p>
          </div>
        </div>
      )}
      {!success && (
        <div className='row' style={{ justifyContent: 'center' }}>
          <Button color='pink' text='Submit' onClick={submitDetails} />
        </div>
      )}
      {success && (
        <div className='row'>
          <div className='col-sm-12'>
            <a
              className='btn-swu-green'
              style={{ display: 'flex', placeContent: 'center center' }}
              href={brochurePdf}
              target='_blank'
              rel='noreferrer'
            >
							Download Brochure
            </a>
          </div>
        </div>
      )}
    </form>
  );
};
