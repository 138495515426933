import { DeploymentType } from '../layouts/MainLayout/MainLayout';
import ENV_VARS from '../config';
import axios from 'axios';

const config =
	DeploymentType !== 'staging'
	  ? ENV_VARS.staging
	  : ENV_VARS.production;
const api = axios.create({
  baseURL: config.covid19API,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' },
});

const covidSvc = {
  getHealth() {
    return new Promise((resolve, reject) => {
      api
        .get('/health')
        .then(result => {
          resolve(result.status === 200 || result.data.status === 'ok');
        })
        .catch(err => {
          resolve(false);
          console.error(err);
        });
    });
  },
  submitPayment(dataObject) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.covid19API, dataObject, { 'Content-Type': 'application/json' })
        .then(result => {
          if (result && result.data.order_id) {
            resolve({
              success: true,
              orderId: result.data.order_id,
              amount: parseFloat(result.data.amount),
            });
          } else {
            resolve({ success: false, error: result.data.gateway_rejection_reason || 'unknown' });
          }
        })
        .catch(err => {
          reject({ error: 'Service Unavailable' });
        });
    });
  },
  getPrice() {
    return new Promise((resolve, reject) => {
      api
        .get('/price')
        .then(resp => {
          resolve({ price: parseFloat(resp.data.replace('£', '')) });
        })
        .catch(err => {
          reject({ error: 'Price Unavailable', err: err });
        });
    });
  },
};
export default covidSvc;
