import React, { useState, useEffect }  from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../Button/Button';
import CountrySearch from '../FormComponents/CountrySearch';
import getValueFromObject from '../../helpers/getValueFromObject';
import './FitToFlyCountryChecker.scss';
import travelRestrictions from '../../assets/icons/travel-restriction-checker.svg';

const checkValue = (value) => !!value && value !== 'x' && value !== 'NR';

const FitToFlyCountryChecker = ({ title }) => {
  // const [fromCountry, setFromCountry] = useState({ name: "United Kingdom", iso3166: 'GBR' });
  // const [toCountry, setToCountry] = useState('');
  // const [fitToFlyInfo, setFitToFlyInfo] = useState();
  // const [showResult, setShowResult] = useState(false);

  // const countriesData = useStaticQuery(graphql`
  //   query {
  //     allGoogleSheetTestRow {
  //       edges {
  //         node {
  //           name
  //           iso3166
  //           pcr
  //           lamp
  //           antigen
  //           minage
  //           notes
  //         }
  //       }
  //     }
  //   }
  // `);


  // const filterCountriesArray = (arr) => (arr.map(({ node }) => ({ ...node })).filter(({ name, iso3166, pcr, lamp, antigen }) => ((!!iso3166 && !!name) && ((!!pcr && pcr !== 'x') || (!!lamp && lamp !== 'x') || (!!antigen && antigen !== 'x')))));
  // const countriesToFitToFly = filterCountriesArray(!!countriesData && !!countriesData.allGoogleSheetTestRow && !!countriesData.allGoogleSheetTestRow.edges && countriesData.allGoogleSheetTestRow.edges);

  // useEffect(() => {
  //   setShowResult(false);
  //   const toCountryInfo = countriesToFitToFly.find(({ iso3166 }) => toCountry.iso3166 === iso3166);
  //   const fromCountryInfo = countriesToFitToFly.find(({ iso3166 }) => fromCountry.iso3166 === iso3166);
  //   setFitToFlyInfo([
  //     toCountryInfo,
  //     fromCountryInfo,
  //   ]);
  // }, [toCountry, fromCountry]);

  return (
    <div className='form-with-side-container'>
      {/* <div className='width-container'>
        <div className='row center'>
          <div className='form-container col-sm-12 col-md-8'>
            <div className='row center' style={{ paddingBottom: 12 }}>
              <h4>{title}</h4>
            </div>
            <div className='container'>
              <div className='row form-row' align='center'>
                <div className='col-sm-12 col-md-6'>
                  <CountrySearch
                    disabled
                    label="Where from?"
                    defaultLocationId={fromCountry}
                    updateLocation={setFromCountry}
                    countries={countriesToFitToFly}
                  />
                </div>
                <div className='col-sm-12 col-md-6'>
                  <CountrySearch
                    label="Where to?"
                    updateLocation={setToCountry}
                    countries={countriesToFitToFly}
                  />
                </div>
                <div className='col-sm-12' align='center' style={{ paddingTop: 12 }}>
                  {!showResult && (
                    <Button
                      color='pink'
                      text='Get info'
                      style={{ margin: 10 }}
                      onClick={() => setShowResult(true)}
                      disabled={!(fromCountry && toCountry) && (fromCountry !== toCountry)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showResult && (
        <>
          <div className='row' style={{ paddingTop: 30 }}>
            {fitToFlyInfo.map((item, i) => {
              const pcr = getValueFromObject(item, 'pcr', '');
              const lamp = getValueFromObject(item, 'lamp', '');
              const antigen = getValueFromObject(item, 'antigen', '');
              const minage = getValueFromObject(item, 'minage', '');
              const notes = getValueFromObject(item, 'notes', '');
              const testIsNotRequired = pcr === 'NR' && lamp === 'NR' && antigen === 'NR';
              const isOutboundWay = i === 0;

              return (
                <div className='col-sm-12 col-md-6'>
                  <div className='row' style={{ alignItems: 'center' }}>
                    <img src={travelRestrictions} style={{ width: 65, marginRight: 10, ...(isOutboundWay && { transform: 'rotateY(180deg)'}) }} />
                    <div>
                      <h5 style={{ margin: 0 }}>{isOutboundWay ? 'Outbound' : 'Return'} journey</h5>
                      <h6 style={{ margin: 0 }}>
                        {isOutboundWay ? fromCountry.name : toCountry.name} - {isOutboundWay ? toCountry.name : fromCountry.name}
                      </h6>
                    </div>
                  </div>
                  <p>
                    To travel from <b>{isOutboundWay ? fromCountry.name : toCountry.name}</b> to <b>{isOutboundWay ? toCountry.name : fromCountry.name}</b>
                    {testIsNotRequired ? ':' : (
                      ` you will need to take a COVID test before you depart ${isOutboundWay ? fromCountry.name : toCountry.name}. The following tests are accepted:`
                    )}
                    <ul className='shwd-list'>
                      { testIsNotRequired ? (
                        <li>This country do not require you to take any test to enter</li>
                      ) : (
                        <>
                          {checkValue(pcr) && (
                            <li><b>PCR test</b>: within {pcr} hours from your arrival</li>
                          )}
                          {checkValue(lamp) && (
                            <li><b>Lamp test</b>: within {lamp} hours from your arrival</li>
                          )}
                          {checkValue(antigen) && (
                            <li className="no-margin"><b>Antigen test</b>: within {antigen} hours from your arrival</li>
                          )}
                        </>
                      )}
                    </ul>
                    <div style={{ marginTop: -12 }}>
                      {checkValue(minage) && (
                        <>
                          The minimum age to take the test is: {minage} years old<br />
                        </>
                      )}
                      {checkValue(notes) && (
                        <>
                          <b>Notes</b>: {notes}
                        </>
                      )}
                      {!isOutboundWay && (
                        <p>
                          To land into the UK, you will need to complete entry paperwork.
                          <a
                            target='_blank'
                            style={{ color: 'var(--doc-pink)' }}
                            rel='noopener noreferrer'
                            href='https://www.gov.uk/provide-journey-contact-details-before-travel-uk'
                          >
                          &nbsp;[Link]
                          </a><br /><br />
                          <b>More Info:</b>
                          <ul className='shwd-list'>
                            <li>
                              <div>
                                <b>Green country</b>: You will have to take PCR test on or before day 2 of their arrival back into England - but will not need to quarantine on return (unless they receive a positive result)
                              </div>
                            </li>
                            <li>
                              <div>
                                <b>Amber country</b>: arrivals will need to quarantine for a period of 10 days and take a pre-departure test, and a PCR test on day 2 and day 8 with the option for Test to release on day 5 to end self-isolation early
                              </div>
                            </li>
                            <li className="no-margin">
                              <div>
                                <b>Red country</b>: arrivals will be subject to restrictions currently in place for ‘red list’ countries which include a 10-day stay in managed quarantine hotel, pre-departure testing and PCR testing on day 2 and day 8
                              </div>
                            </li>
                          </ul>
                        </p>
                      )}
                    </div>
                  </p>
                </div>
              );
            })}
          </div>
          <div className='row center' style={{ paddingTop: 12 }}>
              <Button
                color='pink'
                text='New search'
                style={{ margin: 0 }}
                onClick={() => setShowResult(false)}
              />
          </div>
        </>
      )} */}
    </div>
  );
};

export default FitToFlyCountryChecker;
