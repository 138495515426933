import React from 'react';
import './ThreeSteps.scss';
import CartIcon from '../../assets/icons/cart-no-circle.svg';
import FormIcon from '../../assets/icons/form.svg';
import SendIcon from '../../assets/icons/message.svg';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ThreeStepsCovid = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <div className='three-steps-container'>
        <div className='row center'>
          <h2>Get tested for Coronavirus</h2>
        </div>
        <div className='row center'>
          <p>
						The Covid-19 home test requires a throat swab to be taken and sent to our accredited UK
						laboratory (UKAS ISO 15189:2012 standards) for testing.
          </p>
          <p>
						The kit is quick and simple to use and can be done from the comfort of your own home to
						maximise social distancing.
          </p>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='step-number'>
							Step <span className='pink'>1</span>
            </div>
            <div className='step-content'>
              <div
                className='step-img'
                role='img'
                alt='Add item to cart icon'
                aria-label='Add item to cart icon'
                style={{ backgroundImage: `url(${CartIcon})` }}
              ></div>
              <div className='step-text'>Purchase Kit</div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='step-number'>
							Step <span className='pink'>2</span>
            </div>
            <div className='step-content'>
              <div
                className='step-img'
                role='img'
                alt='Fast Postage'
                aria-label='Speedy Post'
                style={{ backgroundImage: `url(${SendIcon})` }}
              ></div>
              <div className='step-text'>Collect Sample &amp; Send</div>
            </div>
          </div>
          <div className='col-sm-12 col-md-4 col-lg-4'>
            <div className='step-number'>
							Step <span className='pink'>3</span>
            </div>
            <div className='step-content'>
              <div
                className='step-img'
                role='img'
                alt='Results document'
                aria-label='Results document with magnifying glass'
                style={{ backgroundImage: `url(${FormIcon})` }}
              ></div>
              <div className='step-text'>Receive Fast Results</div>
            </div>
          </div>
        </div>
        <div className='row center'>
          <Button
            text='How to take a swab'
            color='green'
            onClick={() => setIsVisible(!isVisible)}
          />
        </div>
        <Modal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          title='How to take a swab sample'
          content={<Image />}
        />
      </div>
    </>
  );
};

export default ThreeStepsCovid;
const Image = () => {
  const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "infographic.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};
