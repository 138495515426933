import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '../../Button/Button';
import getProductInfo from '../../../helpers/getProductInfo';
import '../Shops/Shop.scss';

const PartnersProduct = ({
  product,
  myhealthUrl,
  discount = null,
  smallSize = false,
  isStaging = false,
  isAnyActiveDropbox = false,
  addToBasket,
}) => {
  const [numberOfKits, setNumberOfKits] = useState(1);
  const {
    id,
    price,
    service,
    description,
    subTitle,
    title,
    points,
    footer,
    cardColour,
    outOfStock,
    priceWithoutDiscount,
    totalPrice,
    delivery,
    requiresReturn,
    quantityRestriction,
    isDiscountAvailable,
    setDelivery,
  } = getProductInfo(product, numberOfKits, discount, isAnyActiveDropbox);

  return (
    <div className="tile">
      <div className={`${smallSize ? 'title-box title-box-small' : 'title-box'} background--${cardColour}`}>
        <h5 className="product-title">
          {service} {isStaging && (
            <a href={`${myhealthUrl}/super_admin/product/${id}`} target="_blank" rel="noopener noreferrer">id:{id}</a>
          )}
        </h5>
        <Divider className="title-divider"/>
        <h4 className="product-sub-title">
          {title}
        </h4>
        {!!subTitle && (
          <p className="additional-title-text">
            {subTitle}
          </p>
        )}
      </div>
      <div className="tile-content">
        <div>
          <p className={`${smallSize ? 'description-small' : 'description'}`}>
            {description}
          </p>
        </div>
        <div className={`${smallSize ? 'buy-action-box buy-action-box-small' : 'buy-action-box'}`}>
          <div>
            <h3 style={{ margin: '0px 0', textAlign: 'center' }}>
              {isDiscountAvailable ? (
                <>
                  <span className="line-thought">
										£{priceWithoutDiscount.toFixed(2)}
                  </span>
                  <span>
										&nbsp;&nbsp;£{totalPrice.toFixed(2)}
                  </span>
                </>
              ) : (
                <>
									£{totalPrice.toFixed(2)}
                </>
              )}
            </h3>
          </div>
          <div className={`row ${requiresReturn ? 'space-between' : 'center'} mob-col ${smallSize ? 'action-small-row center' : 'action-row'}`}>
            {isAnyActiveDropbox && (
              <FormControl component="fieldset">
                <RadioGroup name="delivery" value={delivery} onChange={(event) => setDelivery(event.target.value)}>
                  <FormControlLabel
                    value="drop"
                    control={<Radio />}
                    label={<p className="no-margin"><b>Drop Off return - <span className="green-text">Included</span></b></p>}
                  />
                  <FormControlLabel
                    value="royal"
                    control={<Radio />}
                    label={<p className="no-margin"><b>Royal Mail return (+ £10.00 per kit)</b></p>}
                  />
                </RadioGroup>
              </FormControl>
            )}
            <div className="row space-between align-center">
              {!quantityRestriction && (
                <div className="row align-center" style={{ paddingRight: 30 }}>
                  <Button
                    text={<i className="fa fa-minus" aria-hidden="true"></i>}
                    color='green add-btn'
                    onClick={() => numberOfKits > 1 ? setNumberOfKits(numberOfKits - 1) : null}
                  />
                  <h4 style={{ margin: '0 10px' }}>
                    {numberOfKits}
                  </h4>
                  <Button
                    text={<i className="fa fa-plus" aria-hidden="true"></i>}
                    color='green add-btn'
                    onClick={() => {
                      if (numberOfKits < 15) {
                        setNumberOfKits(numberOfKits + 1);
                      }
                    }}
                  />
                </div>
              )}
              {outOfStock ? (
                <p className="star-text">Out of Stock</p>
              ) : (
                <Button
                  text='Add to basket'
                  color='pink action-btn add-to-basket'
                  disabled={requiresReturn ? !delivery : false}
                  onClick={() => {
                    addToBasket({ price: price * numberOfKits, numberOfKits, title, id, delivery, requiresReturn });
                    setNumberOfKits(1);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!!points.length && (
          <ul className='shwd-list shwd-list-partners'>
            {points.map(({ text, colour }, indx) => (
              <li key={indx} className={colour}>
                {text}
              </li>
            ))}
          </ul>
        )}
      </div>
      {numberOfKits >= 15 && (
        <div className='row center bulk-notification' style={{margin: '10px 0px'}}>
          If you are a business and you want to make a bulk order, please contact us at: <strong className="red-text">orders@dochq.co.uk</strong>
        </div>
      )}
      {footer && (
        <div className={`${smallSize ? 'text-after-price-box text-after-price-box-small' : 'text-after-price-box'}`}>
          <p className="star-text">
            {footer}
          </p>
        </div>
      )}
    </div>
  );
};

export default PartnersProduct;
