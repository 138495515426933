import React from 'react';
import SEO from '../components/SEO/SEO';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import AffiliateBullets from '../components/AffiliateBullets/AffiliateBullets';
import AffiliateRegister from '../components/AffiliateRegister/AffiliateRegister';
import AffiliateWhy from '../components/AffiliateWhy/AffiliateWhy';
import LinkButton from '../components/LinkButton/LinkButton';
const AffiliatePage = () => (
  <>
    <HeroBanner
      title='Become an Affiliate'
      tagline='Share travel health information through a unique tech solution supported by medical knowledge and advice.'
      imageAlt='Happy, healthy travellers'
      imageSrc='affiliate-header.jpg'
    />
    <AffiliateBullets />
    <AffiliateWhy />
    <AffiliateRegister />
    <ComingSoon />
    <FinalSignUp />
  </>
);

export default AffiliatePage;
const styles = {
  row: { maxWidth: '1200px', margin: 'auto' },
  h3: { fontSize: '2rem', textAlign: 'center' },
  h4: { fontSize: '1.7rem' },
  rightBorder: { borderRight: '2px solid var(--doc-light-grey)' },
  margin: { marginTop: '40px', marginBottom: '40px' },
};
const ComingSoon = () => (
  <>
    <div className='coming-soon-container' style={styles.margin}>
      <div className='row' style={(styles.row, { paddingLeft: '15px', paddingRight: '15px' })}>
        <h3 style={styles.h3}>Coming Soon:</h3>
      </div>
      <div className='row' style={styles.row}>
        <div className='col-sm-12 col-md-6 col-lg-6' style={styles.rightBorder}>
          <h4 style={styles.h4}>How can I protect myself against malaria?</h4>
          <p>
						Malaria is a preventable disease affecting approximately 216 million people around the
						world, killing 445,000 people. If you need antimalarials whilst travelling:
          </p>
          <ul className='shwd-list'>
            <li>Complete the online form to help you get the right medication for your needs</li>
            <li>Get your medication delivered straight to your door</li>
          </ul>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6'>
          <h4 style={styles.h4}>Can I take my medication abroad?</h4>
          <p>
						Different countries have different rules and regulations about the types of medicine
						they allow to be taken into the country and the maximum quantities you can take in.
          </p>
          <ul className='shwd-list'>
            <li>Check whether you can take your medication from your GP abroad</li>
            <li>Find out what documentation you may need</li>
          </ul>
        </div>
      </div>
    </div>
  </>
);
const FinalSignUp = () => (
  <div className='final-sign-up-container' style={styles.margin}>
    <div className='row'>
      <h2 style={{ margin: 'auto', fontSize: '2rem' }}>Sign up to receive product updates</h2>
    </div>
    <div
      className='row'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
      }}
    >
      <LinkButton
        color='green'
        text='Sign Up'
        linkSrc='https://dochq.postaffiliatepro.com/affiliates/signup.php#SignupForm'
      />
    </div>
  </div>
);
