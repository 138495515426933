import React from 'react';
import SEO from '../components/SEO/SEO';
import DochqShop from '../components/Shop/Shops/DochqShop';

const DochqShopPage = ({ location: { href }}) => (
  <>
    <SEO
      title='Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <DochqShop location={href} />
  </>
);

export default DochqShopPage;
