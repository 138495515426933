import React from 'react';
import LinkButton from '../LinkButton/LinkButton';

const HeaderWithContent = ({
  header,
  content,
  color,
  backgroundColor,
  hasButton,
  buttonText,
  buttonColor,
  buttonLinkSrc,
}) => (
  <div
    className='header-with-content-container'
    style={{
      backgroundColor: backgroundColor || 'var(--doc-light-grey)',
      color: color || 'var(--doc-dark-grey)',
      textAlign: 'center',
      padding: '20px',
    }}
  >
    <div className='row'>
      <h2 style={{ margin: 'auto' }}>{header}</h2>
    </div>
    <div className='row' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div style={{ width: '60%', minWidth: '250px', margin: 'auto' }}>{content}</div>
    </div>
    {hasButton && buttonText && buttonColor && buttonLinkSrc && (
      <div
        className='row'
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <LinkButton text={buttonText} color={buttonColor} linkSrc={buttonLinkSrc} />
      </div>
    )}
  </div>
);

export default HeaderWithContent;
