import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Box, Divider } from '@material-ui/core';
import getURLParams from '../../helpers/getURLParams';
import AmexModal from '../Shop/Shops/Amex/AmexModal';
import logo from '../../assets/icons/dochq-logo-rect.svg';
import oflLogo from '../../assets/images/oh-logo.png';
import synLabLogo from '../../assets/icons/synlab-logo.png';
import amexLogo from '../../assets/images/amex-logo.jpg';
import ENV_VARS from '../../config';
import NavigationDropDown from './NavigationDropDown';
import './NavBar.scss';

const activeEnv = process.env.GATSBY_ENVIRONMENT ? process.env.GATSBY_ENVIRONMENT : 'staging';
const isStaging = activeEnv === 'staging';
const config = isStaging
  ? ENV_VARS.staging
  : ENV_VARS.production;
const portalUrl = config.portalUrl;
const styles = { active: { color: 'var(--doc-pink)', fontWeight: 'bold' } };
const stylesForLogin = {
  login: {
    color: 'var(--doc-green)',
    backgroundColor: 'white',
    fontSize: '16px',
    marginTop: '7px',
    fontWeight: 700,
    lineHeight: '19px',
    height: '32px',
    width: '88px',
    border: '1px solid var(--doc-green)',
    borderRadius: '18px',
    marginLeft: '5px',
    cursor: 'pointer',
  }
};

const HIDE_NAV_LINKS = [
  '/checkout',
  '/checkout/',
  '/synlab-shop/',
  '/partners/insurefor',
  '/partners/insurefor/',
  '/partners/global_travel_collection',
  '/partners/global_travel_collection/',
];

export default class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      addedAwin: false,
      open: false,
      topActive: 'personal',
      isLogged: false,
    };
    this.getURLParams = getURLParams.bind(this);
    this.handleScroll = handleScroll.bind(this);
    this.toggleModal = toggleModal.bind(this);

    function handleScroll(event) {
      if (this.state.isMobileVisible) {
        this.setState({ isMobileVisible: false });
      }
    }
    function toggleModal() {
      this.setState({ open: !this.state.open });
    }
  }


  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined' && !!window.Trustpilot) {
      const trustpilotbox = document.getElementById('trustpilot_nav');
      window.Trustpilot.loadFromElement(trustpilotbox);
    }
  }

  render() {
    if (this.state.addedAwin === false && typeof window !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];

      const awMastertag = document.createElement('script');
      awMastertag.setAttribute('defer', 'defer');
      awMastertag.src = 'https://www.dwin1.com/19181.js';
      awMastertag.type = 'text/javascript';

      body.appendChild(awMastertag);

      this.setState({ addedAwin: true });
    }

    const isAmexUk = this.props.path.includes('amex-uk');
    const isAmexIt = this.props.path.includes('amex-it');
    const isAmexPartnersShop =  this.props.path.includes('/partners/amex');
    const isAmex = isAmexUk || isAmexIt;

    return (
      <>
        <nav role='navigation'>
          <div className='navigation-container'>
            <div className="row" style={{ width: '100%' }}>
              <div className="col col-sm-6 col-md-3 col-lg-4 trust-pilot-container" style={isAmex ? { placeContent: 'unset' } : {}}>
                <Link to='/'>
                  <img src={logo} alt='DocHQ Logo' style={{ paddingLeft: 60, }} />
                </Link>
                {isAmex && (
                  <>
                    <Divider orientation="vertical" style={{ marginLeft: 10, }} />
                    <img src={amexLogo} alt='OFL Logo' className='responsiveImg'/>
                  </>
                )}
                {(!this.props.path.includes('/shop') && !isAmexPartnersShop && !this.props.path.includes('/partners/ofl') && !this.props.path.includes('/checkout') && !this.state.isMobileVisible) && (
                  <div className="row trust-pilot-block">
                    <div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
                      <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
                    </div>
                  </div>
                )}
                {(!this.props.path.includes('/shop') && isAmexPartnersShop && !this.props.path.includes('/partners/ofl') && !this.props.path.includes('/checkout') && !this.state.isMobileVisible) && (
                  <div className="row trust-pilot-block amex">
                    <div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
                      <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
                    </div>
                  </div>
                )}
                {(this.props.path.includes('/partners/ofl')) && (
                  <img src={oflLogo} alt='OFL Logo' style={{ paddingLeft: 20 }} className='responsiveImg'/>
                )}
              </div>
              {!HIDE_NAV_LINKS.includes(this.props.path) && (
                <div className="col col-sm-6 col-md-9 col-lg-8 full-size-on-large" style={{ padding: 0 }}>
                  <div className={`navigation-items ${this.state.isMobileVisible ? 'mobile-nav' : ''}`}>
                    <div className={`row ${this.state.isMobileVisible ? 'column' : ''}`}>
                      {isAmex ? (
                        <>
                          {isAmexUk ? (
                            <>
                              <Link to="https://isolate.menlosecurity.com/https://square.americanexpress.com/community/uk/pages/returning-to-office" target="_blank">
                                <div className='nav-item'>UK RtO</div>
                              </Link>
                              <Link to="https://isolate.menlosecurity.com/https://square.americanexpress.com/docs/DOC-169784" target="_blank">
                                <div className='nav-item'>Amex FAQs</div>
                              </Link>
                              <div className='nav-item' onClick={this.toggleModal}>
																Contact & Support
                              </div>
                            </>
                          ) : (
                            <>
                              <Link to="https://isolate.menlosecurity.com/https://square.americanexpress.com/groups/hr-engagement-italy/content?filterID=contentstatus%5Bpublished%5D~category%5Bcovid-guidelines-italy%5D" target="_blank">
                                <div className='nav-item'>IT RtO</div>
                              </Link>
                              <Link to="https://isolate.menlosecurity.com/https://square.americanexpress.com/docs/DOC-200238" target="_blank">
                                <div className='nav-item'>Amex FAQs</div>
                              </Link>
                              <div className='nav-item' onClick={this.toggleModal}>
																Contact & Support
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <NavigationDropDown
                            title='Travel'
                            links={[
                              {
                                linkTo: '/travel-medicine-restrictions',
                                displayText: 'Travel Medicine',
                              },
                            ]}
                            activeStyle={styles.active}
                            isMobileView={this.state.isMobileVisible}
                          />
                          <NavigationDropDown
                            title="For Businesses"
                            links={[
                              {
                                linkTo: '/business-covid-testing',
                                displayText: 'Covid Testing',
                              },
                            ]}
                            activeStyle={styles.active}
                            isMobileView={this.state.isMobileVisible}
                          />
                          <Link to='/about-us' activeStyle={styles.active}>
                            <div className='nav-item'>About Us</div>
                          </Link>
                          <Link to='/contact-us' activeStyle={styles.active}>
                            <div className='nav-item'>Contact</div>
                          </Link>
                          {this.props.params['customer_discount'] !== 'DOCHQAPP30' && (
                            <Box>
                              <Link to={`${portalUrl}/login`}>
                                <button type='button' style={stylesForLogin.login} role='button'>
                                  Sign In
                                </button>
                              </Link>
                            </Box>
                          )}
                          {(!this.props.path.includes('/shop') && !this.props.path.includes('/checkout') && this.state.isMobileVisible) && (
                            <div className='trustpilot-widget-mobile'>
                              <div className="row">
                                <div id="trustpilot_nav" className="trustpilot-widget" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
                                  <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className='menu-bar display-on-sm'
                    role='navigation'
                    onClick={() => {
                      this.setState(prevState => ({ isMobileVisible: !prevState.isMobileVisible }));
                    }}
                  >
                    {this.state.isMobileVisible ? (
                      <i className='fa fa-chevron-right fa-2x'></i>
                    ) : (
                      <i className="fas fa-bars" />
                    )}
                  </div>
                </div>
              )}
              {(this.props.path.includes('/synlab-shop') && !this.state.isMobileVisible) && (
                <div className="synlab-logo-box" style={{ padding: 0 }}>
                  <img src={synLabLogo} alt='SynLab Logo' style={{ paddingLeft: 20, height: 'unset' }} />
                </div>
              )}
            </div>
          </div>
        </nav>
        <AmexModal
          isVisible={this.state.open}
          onClose={this.toggleModal}
          isAmexIt={isAmexIt}
        />
      </>
    );
  }
}
/*
          Business home
              how it works
							partnerships
							pricing
              faq
              contact
              |
              personal

          Personal home
              travel vaccines
							travel medicines
							about us
 */
