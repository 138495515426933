import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import LinkButton from '../LinkButton/LinkButton';
import './HeroBanner.scss';

export default function HeroBanner({
  title,
  tagline,
  imageSrc,
  imageAlt,
  backgroundStyle,
  button,
  buttonText,
  buttonColor,
  linkSrc,
}) {
  return (
    <div className='hero-banner-container'>
      <div className='row'>
        <div className='col col-sm-12 col-md-6 col-lg-5' id='hero-left'>
          <div className='hero-banner-title'>
            <h1>
              {title}
              <i className='fas fa-circle html-period'></i>
            </h1>
          </div>
          <div className='hero-banner-tagline'>
            <p>{tagline}</p>
            {(button || button === 'true') && buttonText && linkSrc && buttonColor && (
              <div className="row" style={{ justifyContent: 'flex-start', }}>
                <LinkButton
								  text={buttonText}
                  color={buttonColor || 'green'}
                  linkSrc={linkSrc}
                  style={{
                    width: '199px',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '19px',
                  }}/>
              </div>
            )}
          </div>
        </div>
        <div className='col col-sm-12 col-md-6 col-lg-7' id='hero-right'>
          <HeroBannerImage
            imageAlt={imageAlt}
            imageSrc={imageSrc}
            backgroundStyle={backgroundStyle}
          />
        </div>
      </div>
    </div>
  );
}
HeroBanner.defaultProps = {
  title: 'I am the title',
  tagline: 'Tagline goes here',
  imageSrc: '',
  imageAlt: 'People working hard',
};

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};
const HeroBannerImage = ({ imageSrc, imageAlt, backgroundStyle }) => (
  <StaticQuery
    query={graphql`
			{
				background: allImageSharp(filter: { original: { src: { regex: "hero-banner/" } } }) {
					edges {
						node {
							fluid(maxWidth: 1000) {
								originalName
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`}
    render={data => {
      const myImg = data.background.edges.find(img => img.node.fluid.originalName === imageSrc);
      return (
        <BackgroundImage
          aria-label={imageAlt}
          role='img'
          className='hero-banner-image'
          fluid={myImg.node.fluid}
          style={{ position: 'static', ...backgroundStyle }}
        ></BackgroundImage>
      );
    }}
  ></StaticQuery>
);
