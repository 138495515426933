import React from 'react';
import SEO from '../../components/SEO/SEO';
import InsureforShop from '../../components/Shop/Shops/Insurefor/InsureforShop';

const InsureforShopPage = ({ location: { href }}) => (
  <>
    <SEO
      title='Insurefor Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <InsureforShop location={href} />
  </>
);

export default InsureforShopPage;
