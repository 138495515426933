import React from 'react';
import Modal from '../Modal/Modal';
import { Grid, Typography, Step, StepLabel, Stepper, ThemeProvider } from '@material-ui/core';
import { stepperTheme } from '../../helpers/themes/stepperTheme';
import './Shops/Shop.scss';

const HowTestWorks = ({
  open,
  handleClose,
}) => {
  const antigenSteps = [
    'Purchase your product',
    'Book your online video appointment',
    'Perform the test',
    'Get your results and certificate',
  ];
  const antigenStepsAdditionalText = {
    2: 'with the guidance of a trained healthcare professional',
    3: 'via email in under 30 minutes',
  };

  const pcrSteps = [
    'Purchase your product',
    'Book your online video appointment',
    'Take your sample',
    'Return the sample to the laboratory',
    'Laboratory analyses your sample',
    'Get your results and certificate',
  ];

  const pcrStepsAdditionalText = {
    2: 'with the guidance of a trained healthcare professional',
    5: 'via email',
  };

  return (
    <div className="override-modal">
      <Modal
        isVisible={open}
        title={<h4 className="modal-title no-margin">How DocHQ tests work</h4>}
        onClose={handleClose}
      >
        <div className="search-dropbox-modal-box">
          <ThemeProvider theme={stepperTheme}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <h5 className="no-margin" style={{ textAlign: 'center' }}>
                  Antigen Test
                </h5>
                <Stepper orientation='vertical'>
                  {antigenSteps.map((label, i) => {
                    const labelProps = {};
                    if (antigenStepsAdditionalText[i]) {
                      labelProps.optional = <Typography variant="caption">
                        {antigenStepsAdditionalText[i]}
                      </Typography>;
                    }
                    return (
                      <Step key={i}>
                        <StepLabel active {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={6}>
                <h5 className="no-margin" style={{ textAlign: 'center' }}>
                  PCR Test
                </h5>
                <Stepper orientation='vertical'>
                  {pcrSteps.map((label, i) => {
                    const labelProps = {};
                    if (pcrStepsAdditionalText[i]) {
                      labelProps.optional = <Typography variant="caption">
                        {pcrStepsAdditionalText[i]}
                      </Typography>;
                    }
                    return (
                      <Step key={i}>
                        <StepLabel active {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
      </Modal>
    </div>
  );
};

export default HowTestWorks;
