const fireAwin = (saleAmount, discount_code, booking_reference) => {
  const body = document.getElementsByTagName('body')[0];
  if (typeof window !== 'undefined') {
    // Mandatory fallback
    const awinTrackingPixel = document.createElement('img');
    awinTrackingPixel.src = `https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=19181&amount=${parseFloat(
      saleAmount
    ).toFixed(2)}&ch=aw&parts=DEFAULT:${parseFloat(saleAmount).toFixed(2)}&vc=${discount_code ||
			''}&cr=GBP&ref=${booking_reference || 'unknownreference'}&testmode=0`;
    body.appendChild(awinTrackingPixel);

    /*** Do not change ***/
    window.AWIN = {};
    const AWIN = window.AWIN;
    AWIN.Tracking = {};
    AWIN.Tracking.Sale = {};
    AWIN.Tracking.Sale.amount = parseFloat(saleAmount).toFixed(2);
    AWIN.Tracking.Sale.channel = 'aw';
    AWIN.Tracking.Sale.voucher = discount_code || '';
    AWIN.Tracking.Sale.orderRef = booking_reference || 'unknownreference';
    AWIN.Tracking.Sale.parts = `DEFAULT:${parseFloat(saleAmount).toFixed(2)}`;
    AWIN.Tracking.Sale.currency = 'GBP';
    AWIN.Tracking.Sale.test = '0';

    const awMastertag = document.createElement('script');
    awMastertag.setAttribute('defer', 'defer');
    awMastertag.src = 'https://www.dwin1.com/19181.js';
    awMastertag.type = 'text/javascript';

    body.appendChild(awMastertag);

    window.processComplete = true;
    window.parent.postMessage('processComplete', '*');
  }
};

module.exports = fireAwin;
