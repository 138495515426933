exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-business-covid-testing-js": () => import("./../../../src/pages/business-covid-testing.js" /* webpackChunkName: "component---src-pages-business-covid-testing-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-19-home-test-kit-js": () => import("./../../../src/pages/covid-19-home-test-kit.js" /* webpackChunkName: "component---src-pages-covid-19-home-test-kit-js" */),
  "component---src-pages-covid-19-risk-assessment-tool-js": () => import("./../../../src/pages/covid-19-risk-assessment-tool.js" /* webpackChunkName: "component---src-pages-covid-19-risk-assessment-tool-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-partners-amex-it-js": () => import("./../../../src/pages/partners/amex-it.js" /* webpackChunkName: "component---src-pages-partners-amex-it-js" */),
  "component---src-pages-partners-amex-uk-how-to-take-a-test-js": () => import("./../../../src/pages/partners/amex-uk/how-to-take-a-test.js" /* webpackChunkName: "component---src-pages-partners-amex-uk-how-to-take-a-test-js" */),
  "component---src-pages-partners-amex-uk-how-to-take-a-test-video-js": () => import("./../../../src/pages/partners/amex-uk/how-to-take-a-test-video.js" /* webpackChunkName: "component---src-pages-partners-amex-uk-how-to-take-a-test-video-js" */),
  "component---src-pages-partners-amex-uk-index-js": () => import("./../../../src/pages/partners/amex-uk/index.js" /* webpackChunkName: "component---src-pages-partners-amex-uk-index-js" */),
  "component---src-pages-partners-ctn-js": () => import("./../../../src/pages/partners/ctn.js" /* webpackChunkName: "component---src-pages-partners-ctn-js" */),
  "component---src-pages-partners-global-travel-collection-js": () => import("./../../../src/pages/partners/global_travel_collection.js" /* webpackChunkName: "component---src-pages-partners-global-travel-collection-js" */),
  "component---src-pages-partners-gov-js": () => import("./../../../src/pages/partners/gov.js" /* webpackChunkName: "component---src-pages-partners-gov-js" */),
  "component---src-pages-partners-insurefor-js": () => import("./../../../src/pages/partners/insurefor.js" /* webpackChunkName: "component---src-pages-partners-insurefor-js" */),
  "component---src-pages-partners-ofl-js": () => import("./../../../src/pages/partners/ofl.js" /* webpackChunkName: "component---src-pages-partners-ofl-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-covid-testing-js": () => import("./../../../src/pages/terms-and-conditions-covid-testing.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-covid-testing-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-travel-medicine-restrictions-js": () => import("./../../../src/pages/travel-medicine-restrictions.js" /* webpackChunkName: "component---src-pages-travel-medicine-restrictions-js" */),
  "component---src-pages-travel-restriction-checker-js": () => import("./../../../src/pages/travel-restriction-checker.js" /* webpackChunkName: "component---src-pages-travel-restriction-checker-js" */),
  "component---src-pages-travel-vaccination-js": () => import("./../../../src/pages/travel-vaccination.js" /* webpackChunkName: "component---src-pages-travel-vaccination-js" */),
  "component---src-pages-travel-vaccine-js": () => import("./../../../src/pages/travel-vaccine.js" /* webpackChunkName: "component---src-pages-travel-vaccine-js" */)
}

