
import React from 'react';
import SEO from '../../components/SEO/SEO';
import OflShop from '../../components/Shop/Shops/Ofl/OflShop';

const OflShopPage = ({ location: { href }}) => (
  <>
    <SEO
      title='OFL Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <OflShop location={href} />
  </>
);

export default OflShopPage;
