import React from 'react';
import './BackgroundWCenterContent.scss';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';

const BackgroundWCenterContent = ({ content, imageSrc, imageAlt, backgroundStyle }) => (
  <div className='center-container'>
    <BG
      imageSrc={imageSrc}
      imageAlt={imageAlt || 'background image'}
      backgroundStyle={backgroundStyle}
    >
      {content}
    </BG>
  </div>
);

export default BackgroundWCenterContent;
const BG = ({ imageSrc, imageAlt, backgroundStyle, children }) => (
  <StaticQuery
    query={graphql`
			{
				background: allImageSharp(filter: { original: { src: { regex: "images/" } } }) {
					edges {
						node {
							fluid(maxWidth: 800) {
								originalName
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`}
    render={data => {
      const myImg = data.background.edges.find(img => img.node.fluid.originalName === imageSrc);
      return (
        <BackgroundImage
          aria-label={imageAlt}
          role='img'
          className='background-image'
          fluid={myImg.node.fluid}
          style={{ ...backgroundStyle }}
        >
          <div className='center-child-container'>{children}</div>
        </BackgroundImage>
      );
    }}
  ></StaticQuery>
);
