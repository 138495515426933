import React from 'react';
import './HeroColumns.scss';
const HeroColumns = ({ numCols, title, tagline, column1, column2 }) => (
  <>
    <div className='hero-columns-container'>
      <div className='row padded-row'>
        <div className='col-12 col-md-5 col-lg-4 hero-left'>
          <h1 className='hero-title'>
            {title}
            <i className='fas fa-circle html-period'></i>
          </h1>
          <h4 className='text-white'>{tagline}</h4>
        </div>
        <div className='col-12 col-md-7 col-lg-8'>
          <div className='row hero-right'>
            {numCols === 1 && <div className='col-lg-12'>{column1}</div>}
            {numCols === 2 && <TwoCols column1 column2 />}
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HeroColumns;

const TwoCols = ({ column1, column2 }) => (
  <>
    <div className='col-lg-4'>{column1}</div>
    <div className='col-lg-4'>{column2}</div>
  </>
);
