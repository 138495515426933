import React from 'react';
import bg from '../../assets/images/bullet-background.png';
import './AffiliateBullets.scss';

const AffiliateBullets = () => (
  <>
    <div className='affiliate-bullets-container' style={{ backgroundImage: `url(${bg})` }}>
      <div className='affiliates-content-container'>
        <div className='affiliates-content'>
          <h3>We’ve made sharing travel health information easily accessible and fast.</h3>
          <h2>Add free travel health support to your site:</h2>
          <div className='bullet-container'>
            <div className='row'>
              <div className='col-sm-12 col-md-6'>
                <ul className='shwd-list'>
                  <li>Promote health and wellness to your travellers</li>
                  <li>Offer a strong focus on sickness prevention</li>
                </ul>
              </div>
              <div className='col-sm-12 col-md-6'>
                <ul className='shwd-list'>
                  <li>Travel health information tailored to destinations</li>
                  <li>Licensed clinics for vaccinations and advice</li>
                </ul>
              </div>
            </div>
          </div>
          <p>
						Offer your own branded travel health information to provide value and accurate medical
						advice for your followers.
          </p>
          <p>You can share important steps in preventing disease and saving lives.</p>
        </div>
      </div>
    </div>
  </>
);

export default AffiliateBullets;
