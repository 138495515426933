import React from 'react';
import { get } from 'lodash';
import LinkButton from '../../LinkButton/LinkButton';
import '../Shops/Amex/AmexShop.scss';

const ProductLink = ({
  title,
  body,
  buttons,
}) => (
  <div className="tile">
    <div className="amex-title-box">
      <h4 className="product-sub-title">
        {title}
      </h4>
    </div>
    <div className="tile-content">
      <div className='body-container'>
        <p className="body-text">
          {body}
        </p>
      </div>
      <div>
        {buttons.map((item, indx) => (
          <div key={indx} className="row center mob-col">
            <LinkButton
              newTab={get(item, 'newTab', false)}
              text={item.buttonText}
              color={`${item.buttonColor} no-margin`}
              linkSrc={item.linkSrc}
              style={{ marginTop: 5 }}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default ProductLink;
