import React from 'react';
import { Link } from 'gatsby';
import docLogoRect from '../../assets/icons/dochq-logo-rect-white.svg';
import icoLogo from '../../assets/icons/ico-logo.svg';
import applePodcasts from '../../assets/icons/apple-podcasts.svg';
import spotifyPodcasts from '../../assets/icons/spotify-podcasts.svg';
import './Footer.scss';
import useConfig from '../../helpers/useConfig';

const Footer = () => {
  const { config: { portalUrl } } = useConfig();

  return (
    <div className='footer-container'>
      <div className='row '>
        <div className='col-lg-3 col-md-12 logo-container'>
          <img className='svglogo' src={docLogoRect} alt='DocHQ Logo' />
          <div className='dochq-socials'>
            <a
              alt='DocHQ Facebook'
              href='https://www.facebook.com/DocHQ.co.uk'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Facebook'
            >
              <i className='fab fa-facebook-f fa-2x'></i>
            </a>
            <a
              alt='DocHQ Twitter'
              href='https://twitter.com/@DocHQ_Health'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Twitter'
            >
              <i className='fab fa-twitter fa-2x'></i>
            </a>
            <a
              alt='DocHQ Instagram'
              href='https://www.instagram.com/dochq'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Instagram'
            >
              <i className='fab fa-instagram fa-2x'></i>
            </a>
            <a
              alt='DocHQ LinkedIn'
              href='https://www.linkedin.com/company/14136494'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ LinkedIn'
            >
              <i className='fab fa-linkedin-in fa-2x'></i>
            </a>
          </div>
          <div className='row' style={{ paddingTop: '20px' }}>
            <a
              alt='DocHQ Spotify'
              href='https://open.spotify.com/show/0qdBL5c0G1tAu2WgtO6PrP?si=bHRWLCgPQ2ioT79Wgy-dcQ'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Spotify'
            >
              <img src={spotifyPodcasts} alt='Spotify Logo' style={{height:'35px'}} />
            </a>
          </div>
          <div className='row' style={{ paddingTop: '5px' }}>
            <a
              alt='DocHQ Apple Podcast'
              href='https://podcasts.apple.com/us/podcast/health-tech-for-businesses/id1526658913'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Apple Podcast'
            >
              <img src={applePodcasts} alt='Apple Logo' style={{height:'35px'}}/>
            </a>
          </div>
        </div>
        <div className='col-lg-2 col-md-3 col-sm-12'>
          <div className='title-item'>
            <Link to='/'>Home</Link>
          </div>
          <div className='link-item'>
            <a
              href='/travel-medicine-restrictions/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Travel Medicine
            </a>
          </div>
        </div>

        <div className='col-lg-2 col-md-3 col-sm-12'>
          <div className='link-item'>
            <a
              href='https://blog.dochq.co.uk'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='DocHQ Blog'
            >
              Blog
            </a>
          </div>
          <div className='link-item'>
            <a
              href='/about-us/'
              target="_blank"
              rel='noopener noreferrer'
            >
              About Us
            </a>
          </div>
          <div className='link-item'>
            <a
              href='/contact-us/'
              target="_blank"
              rel='noopener noreferrer'
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className='col-lg-2 col-md-3 col-sm-12'>
          <div className='link-item'>
            <a
              href={`${portalUrl}/terms_and_conditions.pdf`}
              target="_blank"
              rel='noopener noreferrer'
            >
              Terms and Conditions
            </a>
          </div>
          <div className='link-item'>
            {/* https://github.com/gatsbyjs/gatsby/issues/13825#issuecomment-488947348 */}
            <a
              href='/privacy-policy/'
              target="_blank"
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div className='col-lg-1 col-md-12 col-sm-12'>
          <img className='icologo' src={icoLogo} alt='ICO Logo' />
        </div>
      </div>
      <div className='row copyright'>
        <p>
          <strong>dochq.co.uk</strong> is owned and operated by DocHQ Ltd, UK
        </p>
      </div>
    </div>
  );
};

export default Footer;
