import React from 'react';
import SEO from '../../components/SEO/SEO';
import CtnShop from '../../components/Shop/Shops/Ctn/CtnShop';

const CtnShopPage = ({ location: { href } }) => (
  <>
    <SEO
      title='CTN Shop'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
    />
    <CtnShop location={href} />
  </>
);

export default CtnShopPage;
