import React, { useEffect } from 'react';
import {
  Box,
  Grid,
} from '@material-ui/core';
import SEO from '../../../SEO/SEO';
import ProductLink from '../../Products/ProductLink';
import americanExpressBanner from '../../../../assets/images/amex-banner.jpg';
import '../Shop.scss';
import './AmexShop.scss';

const AmexUkShop = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.Trustpilot) {
      const trustpilotbox = document.getElementById('trustpilot_box');
      window.Trustpilot.loadFromElement(trustpilotbox);
      const trustpilotblock = document.getElementById('trustpilot_block');
      window.Trustpilot.loadFromElement(trustpilotblock);
    }
  }, []);

  return (
    <div className="shop-page amex-shop">
      <SEO
        title='Covid-19 Products'
        description='Covid-19 Products'
      />
      <div className='banner-container'>
        <img src={americanExpressBanner} className='banner-image' />
      </div>
      <Grid
        container
        className="delivery-container"
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <Box p={{ xs: 2, md: 8 }}>
            <p className="amex-text">
							All American Express colleagues and contractors are strongly encouraged to take a COVID-19 test at
							home before entering Amex offices to help identify asymptomatic individuals, to stop the spread of
							the virus and protect each other.<br /><br />
							Amex offers free lateral flow testing kits to all colleagues and contractors in partnership with DocHQ.
            </p>
          </Box>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Box p={{ xs: 2, md: 8 }}>
            <p className="amex-text">
							You are encouraged to pick test kits up from the offices – however if you’re unable to, then you can order them through this portal. You must
							only order tests for your own use. You need to use your Amex<br/>(or vendor) email address to make the order.<br /><br />
							All kits are FDA approved and in compliance with the UK Government guidelines. It's easy to use with
							guaranteed results within 20 minutes.
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        className="products-container"
      >
        <Grid item xs={12} md={4} style={{padding: '2px 2px'}}>
          <ProductLink
            title="Order your Free Lateral Flow Testing Kits"
            body="Order today using your voucher codes in few steps. Kits will be delivered directly to your home address."
            buttons={[
              {
                buttonColor: 'amex-blue',
                buttonText: 'Order Now',
                linkSrc: '/checkout?source=amex-uk',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{padding: '2px 2px'}}>
          <ProductLink
            title="How to Take Your Test"
            body="Read the guide to understand the steps and avoid invalid results."
            buttons={[
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'English',
                linkSrc: '/partners/amex-uk/how-to-take-a-test?language=en',
              },
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'Spanish',
                linkSrc: '/partners/amex-uk/how-to-take-a-test?language=es',
              },
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'German',
                linkSrc: '/partners/amex-uk/how-to-take-a-test?language=de',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{padding: '2px 2px'}}>
          <ProductLink
            title="Watch the Video"
            body="Watch the video to understand the steps and avoid invalid results."
            buttons={[
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'English',
                linkSrc: '/partners/amex-uk/how-to-take-a-test-video?language=en',
              },
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'Spanish',
                linkSrc: '/partners/amex-uk/how-to-take-a-test-video?language=es',
              },
              {
                newTab: true,
                buttonColor: 'amex-blue',
                buttonText: 'German',
                linkSrc: '/partners/amex-uk/how-to-take-a-test-video?language=de',
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <div id="trustpilot_block" className="trustpilot-widget trustpilot-box" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="60a81450941eea000194948f" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
            <a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener noreferrer"></a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AmexUkShop;
