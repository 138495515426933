import React, { useState } from 'react';
import { get } from 'lodash';
import Button from '../../Button/Button';
import { Divider } from '@material-ui/core';
import ApprovedKits from '../ApprovedKits';
import '../Shops/Shop.scss';

const CertificateProduct = ({
  product1,
  product2,
  basket,
  jsonAPI,
  myhealthUrl,
  addToBasket,
  isCtnShop = false,
  discount = null,
  isStaging = false,
}) => {
  const [approvedKitsModal, setApprovedKitsModal] = useState(false);
  const [numberOfKits2, setNumberOfKits2] = useState(1);
  const numberOfKits1 = 1;
  const productPrice1 = product1.price;
  const productPrice2 = product2.price;
  const id1 = product1.id;
  const id2 = product2.id;
  const sku1 = product1.sku;
  const sku2 = product2.sku;
  const productName1 = 'Video Consultation';
  const productName2 = 'Certificate';
  const title = get(product1, 'title', '');
  const service = get(product1, 'service', '');
  const subTitle = get(product1, 'sub_title', '');
  const description = get(product1, 'description', '');
  const points = get(product1, 'points', []);
  const footer = get(product1, 'footer', '');
  const productDiscountExempt1 = get(product1, 'discount_exempt', false);
  const productDiscountExempt2 = get(product2, 'discount_exempt', false);
  const cardColour = get(product1, 'card_colour', 'doc-green');
 	const price1 = (!!discount && !productDiscountExempt1) ? productPrice1 - (productPrice1 * (discount.value / 100)) : productPrice1;
  const totalPrice1 = price1 * numberOfKits1;
  const priceWithoutDiscount1 = (productPrice1 * numberOfKits1);
  const price2 = (!!discount && !productDiscountExempt2) ? productPrice2 - (productPrice2 * (discount.value / 100)) : productPrice2;
  const totalPrice2 = price2 * (numberOfKits2 || 1);
  const priceWithoutDiscount2 = productPrice2 * numberOfKits2;
  const numberOfKits1InBasket = get(basket.find(({ id }) => id === id1), 'numberOfKits', 0);
  const products2CanBeAdded = 4 - get(basket.find(({ id }) => id === id2), 'numberOfKits', 0);

  return (
    <>
      <div className="tile">
        <div className={`title-box title-box-double background--${cardColour}`}>
          <h5 className="product-title">
            {service} {isStaging && (
              <>
                <a href={`${myhealthUrl}/super_admin/product/${id1}`} target="_blank" rel="noopener noreferrer">id1:{id1}</a>, <a href={`${myhealthUrl}/super_admin/product/${id2}`} target="_blank" rel="noopener noreferrer">id2:{id2}</a>
              </>
            )}
          </h5>
          <Divider className="title-divider"/>
          {title && (
            <h4 className="product-sub-title">
              {title}
            </h4>
          )}
          {!!subTitle && (
            <p className="additional-title-text">
              {subTitle}
            </p>
          )}
        </div>
        <div className="tile-content">
          {description && (
            <div>
              <p className="description">
                {description}
              </p>
            </div>
          )}
          <ul className="shwd-list">
            <li>
              <span
                className="link"
                onClick={() => setApprovedKitsModal(true)}
              >
								Please <span className="underline">check here the list</span> of UK and EU approved kits. *
              </span>
            </li>
            {points.map(({ text, colour }, indx) => (
              <li key={indx} className={colour}>
                {text}
              </li>
            ))}
          </ul>
          <div className="buy-action-box" style={{ minHeight: 'unset', paddingBottom: 20 }}>
            <div>
              <h4 className="no-margin" style={{ textAlign: 'left', fontSize: 20 }}>
                {productName1}
              </h4>
              <h3 style={{ textAlign: 'left', margin: '10px 0' }}>
                {discount ? (
                  <>
                    <span className="line-thought">
											£{priceWithoutDiscount1.toFixed(2)}
                    </span>
                    <span>
											&nbsp;&nbsp;£{totalPrice1.toFixed(2)}
                    </span>
                  </>
                ) : (
                  <>
										£{totalPrice1.toFixed(2)}
                  </>
                )}
              </h3>
            </div>
          </div>
          <div className="buy-action-box" style={{ minHeight: 'unset' }}>
            <div>
              <h4 className="no-margin" style={{ textAlign: 'left', fontSize: 20  }}>
                {productName2}
              </h4>
              <h3 style={{ textAlign: 'left', margin: '10px 0' }}>
                {discount ? (
                  <>
                    <span className="line-thought">
											£{priceWithoutDiscount2.toFixed(2)}
                    </span>
                    <span>
											&nbsp;&nbsp;£{totalPrice2.toFixed(2)}
                    </span>
                  </>
                ) : (
                  <>
										£{totalPrice2.toFixed(2)} <span className="green-available-text" style={{ fontSize: 16 }}>(max 4 people per consultation)</span>
                  </>
                )}
              </h3>
            </div>
            {!!products2CanBeAdded && (
              <div className="row space-between mob-col">
                <div className="row align-center">
                  <Button
                    text={<i className="fa fa-minus" aria-hidden="true"></i>}
                    color='green add-btn'
                    onClick={() => numberOfKits2 > 1 ? setNumberOfKits2(numberOfKits2 - 1) : null}
                  />
                  <h4 style={{ margin: '0 10px' }}>
                    {numberOfKits2}
                  </h4>
                  <Button
                    text={<i className="fa fa-plus" aria-hidden="true"></i>}
                    color='green add-btn'
                    disabled={!products2CanBeAdded}
                    onClick={() => (products2CanBeAdded > numberOfKits2 && numberOfKits2 < 15) ? setNumberOfKits2(numberOfKits2 + 1) : null}
                  />
                </div>
                <Button
                  text='Add to basket'
                  color='pink action-btn'
                  onClick={() => {
                    addToBasket([
                      ...(numberOfKits1InBasket ? [] : [{ sku: sku1, price: (isCtnShop ? productPrice1 : price1) * numberOfKits1, numberOfKits: numberOfKits1, title: productName1, id: id1 }]),
                      ...(numberOfKits2 > 0 ? [{ sku: sku2, price: (isCtnShop ? productPrice2 : price2) * numberOfKits2, numberOfKits: numberOfKits2, title: productName2, id: id2 }] : []),
                    ]),
                    setNumberOfKits2(1);
                  }}
                />
              </div>
            )}
          </div>
          {(numberOfKits2 >= 15) && (
            <div className='row center bulk-notification' style={{margin: '10px 0px'}}>
              If you are a business and you want to make a bulk order, please contact us at: <strong className="red-text">orders@dochq.co.uk</strong>
            </div>
          )}
          {footer && (
            <div>
              <p className="star-text">
                {footer}
              </p>
            </div>
          )}
        </div>
      </div>
      <ApprovedKits
        open={approvedKitsModal}
        jsonApi={jsonAPI}
        handleClose={() => setApprovedKitsModal(false)}
      />
    </>
  );
};

export default CertificateProduct;
