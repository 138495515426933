import { get } from 'lodash';
import { useState } from 'react';
import calculateProductPrice from './calculateProductPrice';

const getProductInfo = (product, numberOfKits, discount, isAnyActiveDropbox) => {
  const id = product.id;
  const title = get(product, 'title', '');
  const service = get(product, 'service', '');
  const subTitle = get(product, 'sub_title', '');
  const description = get(product, 'description', '');
  const points = get(product, 'points', []);
  const footer = get(product, 'footer', '');
  const cardColour = get(product, 'card_colour', 'doc-green');
  const outOfStock = get(product, 'out_of_stock', false);
  const quantityRestriction = get(product, 'quantityRestriction', false);
  const comingSoon =  get(product, 'comingSoon', false);
  const requiresReturn = get(product, 'tags', []).find((tagName) => tagName === 'postal_return') ? 1 : 0;
  const deliveryDefaultValue = requiresReturn === 0 ? '' : isAnyActiveDropbox ? 'drop' : 'royal';
  const [delivery, setDelivery] = useState(deliveryDefaultValue);
  const {
    price,
    isDiscountAvailable,
    priceWithoutDiscount,
    totalPrice,
  } = calculateProductPrice(product, numberOfKits, discount, delivery, requiresReturn);

  return ({
    id,
    price,
    service,
    description,
    subTitle,
    title,
    points,
    footer,
    requiresReturn,
    quantityRestriction,
    cardColour,
    comingSoon,
    outOfStock,
    delivery,
    setDelivery,
    isDiscountAvailable,
    deliveryDefaultValue,
    priceWithoutDiscount,
    totalPrice,
  });
};

export default getProductInfo;
