import React from 'react';
import './GoogleMapLocation.scss';
const GoogleMapLocation = () => (
  <>
    <div className='google-map-location-container'>
      <iframe
        allowFullScreen=''
        frameBorder='0'
        height='448'
        width='100%'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2446.7209126337834!2d-2.192175167029135!3d52.175767504674404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870f07485a55011%3A0xa1b64f38f1164a93!2zVGVtZSBIb3VzZSwgV2hpdHRpbmd0b24gSGFsbCwgV2hpdHRpbmd0b24gUmQsIFdoaXR0aW5ndG9uLCBXb3JjZXN0ZXIgV1I1IDJSWSwg0JLQtdC70LjQutC-0LHRgNC40YLQsNC90LjRjw!5e0!3m2!1sru!2sua!4v1679058410861!5m2!1sru!2sua'
        title='DocHQ - Interactive map'
      />
    </div>
  </>
);

export default GoogleMapLocation;
